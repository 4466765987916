import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Login from './routes/Login';
import Forgot from './routes/Forgot';
import Reset from './routes/Reset';
import Register from './routes/Register/';
import Verify from './routes/Verify';
import UserLicense from './routes/UserLicense';

const Auth = () => {
  return (
    <div className='auth-pages'>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/forgot' component={Forgot} />
        <Route path='/user/license' component={UserLicense} />
        <Route path='/auth/reset/:code' component={Reset} />
        <Route path='/register/:code?' component={Register} />
        <Route path='/auth/verify/:code' component={Verify} />
        <Redirect from='*' to='/login' />
      </Switch>
    </div>
  );
};

export default Auth;
