import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import ButtonLine from 'shared/components/ButtonLine';
import { countries, degrees, specialty, states } from '../configs';
import Select from 'shared/components/Select';
import ButtonLoading from 'shared/components/ButtonLoading';

const FormStep = ({ formSubmit, user, fetch, error, regCode, lastForm, isEventUser }) => {
  const intial = {
    fname: '',
    lname: '',
    email: '',
    institution: '',
    country: '',
    city: '',
    address: '',
    zipcode: '',
    phone: '',
    degree: '',
    specialty: '',
    certificate: '',
    password: '',
    rpassword: '',
    state: '',
    ...user,
    ...lastForm,
  };
  const [info, setInfo] = useState(intial);

  const isCountryUs = info.country === '226';
  const passwordMatch = info.password === info.rpassword;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...info };
    tempAuth[name] = value;
    setInfo(tempAuth);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const body = {
      regCode,
      email: info.email,
      password: isEventUser ? undefined : info.password,
      firstname: info.fname,
      lastname: info.lname,
      usertype: 1,
      countryid: info.country,
      learnerprofile: {
        state: info.state,
        institution: info.institution,
        city: info.city,
        address: info.address,
        zipcode: info.zipcode,
        phone: info.phone,
        degree: info.degree,
        profession: info.specialty,
        PrefferedNameOnCertificate: info.certificate,
      },
    };
    formSubmit(body, info);
  };

  return (
    <form onSubmit={handleFormSubmit} className='mt-5'>
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='fname'
          onChange={handleChange}
          className='mr-3'
          placeholder='First Name*'
          value={info.fname}
          required
        />
        <InputGroup
          type='text'
          name='lname'
          className='ml-3'
          onChange={handleChange}
          placeholder='Last Name*'
          value={info.lname}
          required
        />
      </div>
      <InputGroup
        type='email'
        name='email'
        onChange={handleChange}
        className='mb-4'
        placeholder='Email*'
        value={info.email}
        disabled={user.email}
        required
      />
      <InputGroup
        type='text'
        name='institution'
        className='mb-4'
        onChange={handleChange}
        placeholder='Hospital/Clinic*'
        value={info.institution}
        required
      />
      <InputGroup
        type='text'
        name='address'
        className='mb-4'
        onChange={handleChange}
        placeholder='Hospital/Clinic Address*'
        value={info.address}
        required
      />
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='city'
          className='mr-3'
          onChange={handleChange}
          placeholder='Hospital/Clinic City*'
          value={info.city}
          required
        />
        {isCountryUs ? (
          <Select
            label='State/Province*'
            name='state'
            className='ml-3'
            value={info.state}
            items={states}
            onChange={handleChange}
            required
          />
        ) : (
          <InputGroup
            type='text'
            name='state'
            className='ml-3'
            onChange={handleChange}
            placeholder='State/Province*'
            value={info.state}
            required
          />
        )}
      </div>
      <Select
        label='Hospital/Clinic Country*'
        name='country'
        className='mb-4'
        value={info.country}
        items={countries}
        onChange={handleChange}
        required
      />
      <div className='d-flex mb-4'>
        <InputGroup
          type='number'
          name='zipcode'
          className='mr-3'
          onChange={handleChange}
          placeholder='Hospital/Clinic Zipcode*'
          value={info.zipcode}
          required
        />
        <InputGroup
          type='text'
          name='phone'
          className='ml-3'
          onChange={handleChange}
          placeholder='Preferred Phone*'
          value={info.phone}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <Select
          label='Degree*'
          name='degree'
          className='mr-3'
          value={info.degree}
          items={degrees}
          onChange={handleChange}
          required
        />
        <Select
          label='Specialty*'
          name='specialty'
          className='ml-3'
          value={info.specialty}
          items={specialty}
          onChange={handleChange}
          required
        />
      </div>
      <InputGroup
        type='text'
        name='certificate'
        className='mb-4'
        onChange={handleChange}
        placeholder='Preferred name on certificate*'
        value={info.certificate}
        required
      />
      {!isEventUser && (
        <div className='d-flex mb-4'>
          <InputGroup
            type='password'
            name='password'
            onChange={handleChange}
            className='mr-3'
            placeholder='Password'
            value={info.password}
            required
          />
          <InputGroup
            type='password'
            name='rpassword'
            onChange={handleChange}
            className='ml-3'
            placeholder='Confirm password'
            value={info.rpassword}
            required
          />
        </div>
      )}
      <div className='text-center'>
        {!passwordMatch && info.rpassword && <div className='error-text'>Passwords mismatch</div>}
        {error && <div className='error-text'>{error}</div>}
        <ButtonLine
          type='submit'
          disabled={(info.password && info.rpassword && !passwordMatch) || fetch}
        >
          {fetch ? <ButtonLoading /> : 'Next'}
        </ButtonLine>
      </div>
    </form>
  );
};

export default FormStep;
