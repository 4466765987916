import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import AuthBlock from '../components/AuthBlock';
import SuccessInfo from '../components/SuccessInfo';
import Loading from 'shared/components/Loading';
import { getError } from 'utils/appHelpers';

const Verify = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [verifyData, setVerifyData] = useState({});
  const [validCode, setValidCode] = useState(false);

  const signinUrl =
    verifyData && verifyData.eventId ? `/events/${verifyData.eventId}/login` : '/login';

  const successInfo = {
    title: 'Thank you!!',
    subTitle: 'Your email has been successfully verified',
    signinUrl,
  };

  const authOptions = {
    links: {
      signin: true,
      signinUrl,
    },
  };

  const checkVerifyCode = useCallback(async () => {
    const code = match.params.code;
    try {
      const { data } = await Api.get(`/auth/verify/${code}`);
      setVerifyData(data.data);
      setValidCode(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/login');
    }
  }, [enqueueSnackbar, history, match.params.code]);

  useEffect(() => {
    checkVerifyCode();
  }, [checkVerifyCode]);

  if (!validCode) return <Loading />;

  return <AuthBlock {...authOptions} successBlock={<SuccessInfo {...successInfo} />} />;
};

export default Verify;
