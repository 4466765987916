import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import ButtonLoading from 'shared/components/ButtonLoading';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import cancelIcon from 'assets/cancel-red.svg';
import EventHeader from '../EventLanding/components/EventHeader';
import { useSnackbar } from 'notistack';
import Select from 'shared/components/Select';
import { countries } from 'app/Auth/routes/Register/configs';
import blueLogo from 'assets/ACE-logo-blue.svg';

const EventRegister = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [token, setToken] = useState();
  const [isConfirm, setIsConfirm] = useState(false);
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    countryid: '',
    city: '',
    state: '',
    password: '',
    rpassword: '',
    userType: 6,
    eventId: Number(id),
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      form.learnerprofile = {
        city: form.city,
        state: form.state,
      };
      delete form.city;
      const res = await Api.post('/auth/register', form);
      setToken(res.data.data);
      setIsConfirm(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempFOrm = { ...form };
    tempFOrm[name] = value;
    setForm(tempFOrm);
  };

  const onClickNo = async () => {
    try {
      setFetch(true);
      await Api.post(`/auth/cancel`, token);
      enqueueSnackbar('Successfully Canceled', { variant: 'success' });
      setIsConfirm(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onClickYes = async () => {
    try {
      setFetch(true);
      await Api.post(`/auth/approve`, token);
      enqueueSnackbar('Successfully Approved', { variant: 'success' });
      history.push(`/events/${id}/login`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const passwordMatch = form.password === form.rpassword;

  return (
    <div className='event-view d-flex flex-column min-vh-100'>
      <EventHeader eventId={id} />
      <div className='event-login flex-1 d-flex align-items-start justify-content-center py-5'>
        <div className='event-login-block col-12 col-sm-8 col-lg-4 col-md-6 py-4 px-5 mt-3'>
          <div className='mt-3 mb-5 text-center'>
            <img src={blueLogo} alt='logo' height='75' />
          </div>
          {!isConfirm && (
            <form onSubmit={handleSubmit}>
              <InputGroup
                type='text'
                name='firstname'
                onChange={handleChange}
                className='mb-4'
                placeholder='First Name*'
                value={form.firstname}
                required
              />
              <InputGroup
                type='text'
                name='lastname'
                className='mb-4'
                onChange={handleChange}
                placeholder='Last Name*'
                value={form.lastname}
                required
              />
              <InputGroup
                type='email'
                name='email'
                onChange={handleChange}
                className='mb-4'
                placeholder='Email*'
                value={form.email}
                required
              />
              <InputGroup
                type='text'
                name='city'
                className='mb-4'
                onChange={handleChange}
                placeholder='City*'
                value={form.city}
                required
              />
              <InputGroup
                type='text'
                name='state'
                className='mb-4'
                onChange={handleChange}
                placeholder='State/Province*'
                value={form.state}
                required
              />
              <Select
                label='Country*'
                name='countryid'
                className='mb-4 pl-1'
                value={form.countryid}
                items={countries}
                onChange={handleChange}
                required
              />
              <InputGroup
                type='password'
                name='password'
                onChange={handleChange}
                className='mb-4'
                placeholder='Password'
                value={form.password}
                required
              />
              <InputGroup
                type='password'
                name='rpassword'
                onChange={handleChange}
                className='mb-4'
                placeholder='Confirm password'
                value={form.rpassword}
                required
              />
              <div className='text-right'>
                {!passwordMatch && form.rpassword && (
                  <div className='error-text'>Passwords mismatch</div>
                )}
                {error && <div className='error-text'>{error}</div>}
                <ButtonLine type='submit' disabled={!passwordMatch || fetch}>
                  {fetch ? <ButtonLoading /> : 'Sign Up'}
                </ButtonLine>
              </div>
            </form>
          )}
          {isConfirm && (
            <div
              className={`justify-content-center registration-success is-event text-center d-flex`}
            >
              <div className='align-items-center d-flex flex-column'>
                <div className={`auth-success-block`}>
                  <h1 className='title'>Successfully Registered!</h1>
                  <p>
                    A registration email will be sent to <b>{form && form.email}</b> <br /> Please
                    confirm that this email address is correct:
                  </p>
                </div>
                <div className='mt-2 suc-btns d-flex justify-content-between align-items-center w-100'>
                  <button
                    disabled={fetch}
                    className='btn d-flex align-items-center mt-1'
                    onClick={onClickNo}
                  >
                    <img src={cancelIcon} alt='icon' />
                    No, It is Not
                  </button>
                  <ButtonLine disabled={fetch} onClick={onClickYes} className='p-0'>
                    Yes, It is Correct
                  </ButtonLine>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventRegister;
