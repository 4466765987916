import React, { useRef, useEffect, useState } from 'react';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import ArrowTool from './ArrowTool';
import { HOST } from 'configs';

const original = `${
  HOST.API.CONTENT
}/dicom/2018_CHDI2D_107_BTOJPEGLossless_MG_R_CC_TomoHD_24X29_20180331_/img_1_lut.jpg`;

const TestViewer = () => {
  const [imageUrl, setImageUrl] = useState(original);

  const el1 = useRef(null);

  const onWindowResize = () => {
    cornerstone.resize(el1.current);
    // console.log(cornerstoneTools.getElementToolStateManager(el1.current));
  };

  const loadImage = async (element, url) => {
    const image = await cornerstone.loadAndCacheImage(url || imageUrl);
    const viewportOptions = { image };
    await cornerstone.displayImage(element, image, viewportOptions);
  };

  const initElement = async element => {
    await cornerstone.enable(element);
    await cornerstone.resize(element);
    window.addEventListener('resize', onWindowResize);
  };

  const initData = async (element, url) => {
    await loadImage(element, url);
    await cornerstone.fitToWindow(element);
    await cornerstone.resize(element);

    // cornerstoneTools.addToolForElement(element, cornerstoneTools.ArrowAnnotateTool, {
    //   configuration: { getTextCallback: () => {} },
    // });
    // cornerstoneTools.setToolActive('ArrowAnnotate', { mouseButtonMask: 1 });
    // console.log(cornerstoneTools);
    // console.dir(cornerstoneTools.ArrowAnnotateTool);

    const data = [
      {
        x: 2633.665306122448,
        y: 1905.89387755102,
      },
      {
        x: 1864.620408163264,
        y: 2474.3183673469384,
      },
    ];

    cornerstoneTools.addToolForElement(el1.current, ArrowTool, { data });
    cornerstoneTools.setToolActive('ArrowTool', { mouseButtonMask: 1 });
  };

  const onImageChange = e => {
    e.preventDefault();
    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
    // initData(el1.current);
  };

  useEffect(() => {
    initElement(el1.current);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    initData(el1.current);
    //eslint-disable-next-line
  }, []);

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <div className='control-area bg-light p-2 d-flex justify-content-between'>
        <form className='form-inline' onSubmit={onImageChange}>
          <div className='form-group mb-2'>
            <label className='sr-only'>Image</label>
            <input
              type='text'
              className='form-control-plaintext border small'
              onChange={({ target }) => setImageUrl(target.value)}
              value={imageUrl}
            />
          </div>
          <button type='submit' className='btn btn-primary mb-2 btn-sm ml-2'>
            change image
          </button>
        </form>
      </div>
      <div className='viewer-area d-flex'>
        <div className='viewport-element p-0' ref={el1}>
          <canvas className='cornerstone-canvas' />
        </div>
      </div>
    </div>
  );
};

export default TestViewer;
