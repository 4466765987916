/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { onOpenExternalUrl } from 'utils/appHelpers';

const SubMenu = ({ menu, path }) => (
  <ul className='sub-menu users mt-2'>
    {menu.map((item, i) => (
      <li key={i}>
        {item.path ? (
          <NavLink to={`${path}/${item.path}`} activeClassName='active'>
            {item.name}
          </NavLink>
        ) : (
          <a href={item.link} target='_blank' onClick={onOpenExternalUrl.bind(null, item.link)}>
            {item.name}
          </a>
        )}
      </li>
    ))}
  </ul>
);

export default SubMenu;
