import React from 'react';
import { connect } from 'react-redux';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { getAndUpdateProfile } from 'app/Auth/actions';

const UserImage = ({ url, userId, getAndUpdateProfile }) => {
  const { enqueueSnackbar } = useSnackbar();

  const uploadImage = async ({ target }) => {
    const file = target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      await Api.post(`/api/image/uploaduserimage/${userId}`, formData);
      await getAndUpdateProfile();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <div className='avatar-container'>
      <img className='user-avatar' src={url} alt='avatar' />
      <label htmlFor='file' className='d-flex align-items-end justify-content-center'>
        <span className='text-center'>
          Change <br />
          Photo
        </span>
      </label>
      <input id='file' type='file' className='hide' onChange={uploadImage} />
    </div>
  );
};

export default connect(
  null,
  { getAndUpdateProfile },
)(UserImage);
