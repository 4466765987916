import React, { useState } from 'react';
import { bindDuration } from 'utils/appHelpers';

const LessonsList = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = index => setActiveIndex(activeIndex === index ? null : index);

  if (!data || !data.length) return null;

  return (
    <div className='accordion parts-accordion'>
      {data.map((item, index) => (
        <ul className='accordion-item' key={item.id}>
          <li
            className={`item-header ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleAccordion(index)}
            role='presentation'
          >
            <div className='d-flex'>
              <div className='col-3 p-0'>
                <img src={item.image} alt={item.name} />
              </div>
              <div className='col-9 pr-0'>
                <h6 className='color-blue'>{item.name}</h6>
                <div className='d-flex text-secondary small'>
                  <div>duration: {bindDuration(item.duration)}</div>
                  <div className='ml-3'>chapters: {item.episodes.length || 0}</div>
                  <div className='ml-3'>type: {item.type}</div>
                </div>
              </div>
            </div>
            <p className='mb-0 mt-3 text-muted info-dsc'>{item.description}</p>
          </li>
          {activeIndex === index && (
            <li className='item-content px-4'>
              <ul>
                {item.episodes.map(epi => (
                  <li key={epi.id} className='bg-white px-3 py-2 mb-2'>
                    {epi.name}
                    <span className='weight-300 ml-2 small'>{bindDuration(epi.duration)}</span>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      ))}
    </div>
  );
};

export default LessonsList;
