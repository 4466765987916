import React from 'react';

const TagsViewBlock = ({ tags }) => {
  return null;
  if (!tags || !tags.length) return null;
  return (
    <div className='tags-view-block'>
      <h1>Tags</h1>
      <div className='d-flex flex-wrap tag-wrap'>
        {tags &&
          tags.map(item => {
            const tag = item.tag || item;
            return (
              <div className='tag-item' key={tag.id}>
                {tag.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TagsViewBlock;
