// Load vendors
import axios from 'axios';

// Load configs
import { HOST } from 'configs/host';
import { getEventId } from './appHelpers';
import { hasAccess } from './permissionHelper';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
});

export const setAuthToken = (authToken, api) => {
  api.defaults.headers.common[HOST.API.AUTH_HEADER] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};

Api.interceptors.request.use(
  request => {
    if (hasAccess('event_user') && getEventId()) {
      request.headers.common.eventId = getEventId();
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);
