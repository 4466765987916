/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import dashLogo from 'assets/white-logo.svg';

const DashBoxInfo = () => {
  return (
    <div className='d-flex flex-column h-100 justify-content-center'>
      <div>
        <img src={dashLogo} alt='logo' />
      </div>
      <p>
        Welcome to <br /> Breast & Skeletal <br /> Health’s Advanced <br /> Continuing Education
        Portal for Physicians!
      </p>
    </div>
  );
};

export default DashBoxInfo;
// Welcome to Breast & Skeletal Health’s Advanced Continuing Education Portal for Physicians!
