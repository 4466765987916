import React from 'react';
import { useSelector } from 'react-redux';
import AuthorsList from './components/AuthorsList';
import LessonsList from './components/LessonsList';
import BackButton from 'shared/BackButton';
import { IconKey, IconLock, IconRight } from 'shared/components/Icons';
// import { Link } from 'react-router-dom';
import TagsViewBlock from 'shared/components/TagsViewBlock';
import { useState } from 'react';
import SubscriptionModal from 'shared/components/SubscriptionModal';
import { Api } from 'utils/connectors';
import { courseSubsStatuses, getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { formatSubscription } from 'utils/formatHelpers';
import { useEffect } from 'react';
import ProgressBar from 'shared/components/ProgressBar';
import ButtonLoading from 'shared/components/ButtonLoading';
import { getCourses } from '../Courses/actions';
import Loading from 'shared/components/Loading';

const subsTypes = {
  1: 'No Key Required',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required
    </>
  ),
};

const CourseDetails = ({ history, match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const courses = useSelector(store => store.courses);
  const course = courses.find(item => item.id === Number(id));
  const [modalData, setModalData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [fetch, setFetch] = useState(null);

  const fetchData = async () => {
    try {
      const subs = course.subscriptionPlans && course.subscriptionPlans[0];
      const { data } = await Api.get(`subscription/getsubscription/${subs.subscriptionId}`);
      setSubscription(formatSubscription(data.data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUnSubscribeSubscription = async () => {
    try {
      setFetch(true);
      await Api.post('/subscription/unsubscribe', { subscriptionId: subscription.id });
      await getCourses();
      await fetchData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    if (course) fetchData();
    //eslint-disable-next-line
  }, []);

  const isEventType = course.courseType === 1;

  const isPending =
    subscription &&
    subscription.userSubs &&
    courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'pending');
  const isDeclined =
    subscription &&
    (!subscription.userSubs ||
      (subscription.userSubs &&
        courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'declined')));
  const isAccepted =
    subscription &&
    subscription.userSubs &&
    courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'active');

  if (!subscription) return <Loading className='mt-5' />;

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
        <div className='d-flex'>
          <BackButton history={history} />
          <div className='course-name border-0 pr-3'>{course.name}</div>
        </div>
      </div>
      <div className='py-3 d-flex'>
        <div className='col-8'>
          <div className='px-3'>
            <h5 className='py-3 color-blue d-flex align-items-center'>
              <IconLock className='mr-2' />
              {course.name}*
            </h5>
            {course.description && (
              <div className='text-muted mb-5'>
                <span className='d-block mb-2 text-lg'>About course</span>
                <span className='d-block white-space-pre'>{course.description}</span>
              </div>
            )}
            {course.authors && course.authors.length > 0 && (
              <AuthorsList data={course.authors} history={history} />
            )}
            {/* <div className='my-4'>
              <p className='text-secondary '>Subscription plans</p>
              <div>
                <table className='table table-custom'>
                  <thead>
                    <tr>
                      <th scope='col'>Subscriptions plans title</th>
                      <th scope='col'>Type</th>
                      <th scope='col'>Content</th>
                      <th scope='col'>Duration</th>
                      <th scope='col'>Validity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {course.subscriptionPlans &&
                      course.subscriptionPlans.map(item => {
                        return (
                          <tr key={item.subscriptionId}>
                            <td>{item.title}</td>
                            <td className='text-nowrap'>{subsTypes[item.subscriptionType]}</td>
                            <td className='text-nowrap'>{item.courses} Courses</td>
                            <td className='text-nowrap'>{item.courseDuration} Hours</td>
                            <td className='text-nowrap'>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div>{item.planDuration} Days</div>
                                <Link
                                  to={`/subscription/${item.subscriptionId}`}
                                  className='bg-white btn btn-sm ml-3 weight-600'
                                >
                                  More
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div> */}
            <p className='text-secondary'>Lessons</p>
            {course.lessons && course.lessons.length > 0 && <LessonsList data={course.lessons} />}
          </div>
        </div>
        <div className='col-4 py-3'>
          <div className='plan-info-box'>
            <img src={course.image} alt='course' />
            <h3 className='mt-3 mb-2 font-weight-light font-size-15'>
              <b>{course.name}*</b>
            </h3>
            <div className='d-flex justify-content-between'>
              <span>License Key</span>
              <span>{subsTypes[subscription.type] || '-'}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Parts</span>
              <span>{course.lessons ? course.lessons.length : '0'} parts</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Faculty</span>
              <span>{course.authors.length} Faculty</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span> Validity Period</span>
              <span>{subscription.expirable ? `${subscription.days} days` : 'No Expiration'}</span>
            </div>
            {isDeclined && !isEventType && (
              <div className='d-flex justify-content-center'>
                <button
                  className='btn subscribe-btn-block mt-3'
                  onClick={() => setModalData(subscription)}
                >
                  Enroll
                </button>
              </div>
            )}
            {isAccepted && (
              <>
                <div className='d-flex flex-column pt-3'>
                  <div className='d-flex justify-content-between pb-2'>
                    <span className='mr-4'>
                      <b className='mr-2'>Start to:</b>
                      {subscription.userSubs.startDate.substr(0, 10)}
                    </span>
                    {!!subscription.expirable && (
                      <span>
                        <b className='mr-2'>End to:</b>
                        {subscription.userSubs.endDate.substr(0, 10)}
                      </span>
                    )}
                  </div>

                  <div className='d-flex align-items-center justify-content-between'>
                    <span className='mr-5 text-nowrap'>
                      Progress {subscription.userSubs.progress || 0}%
                    </span>
                    <ProgressBar progress={subscription.userSubs.progress || 0} />
                  </div>
                </div>
                {!isEventType && (
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn mt-4 text-danger'
                      onClick={onUnSubscribeSubscription}
                      disabled={fetch || subscription.isSystem}
                    >
                      {fetch ? <ButtonLoading /> : 'Deactivate'}
                    </button>
                  </div>
                )}
              </>
            )}
            {isPending && <div className='text-center text-secondary small mt-4'>Request Sent</div>}
            <p className='mt-3 mb-0 enroll-text'>
              * To get access to this course you first need to enroll
            </p>
          </div>
          <TagsViewBlock tags={course.courseTags} />
        </div>
      </div>
      {modalData && (
        <SubscriptionModal
          data={modalData}
          onModalClose={() => setModalData(null)}
          onSuccess={fetchData}
        />
      )}
    </div>
  );
};

export default CourseDetails;
