import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export let userPermissions = {};

// const types = {
//   0: 'Admin',
//   1: 'Learner',
//   2: 'Doctor',
//   3: 'User',
//   4: 'DemoLearner',
//   5: 'KOL',
//   6: 'EventVisitor',
// };

const vipPermissions = ['vip_user'];
const eventPermissions = ['event_user'];

export const permissions = {
  0: vipPermissions,
  1: vipPermissions,
  2: vipPermissions,
  3: vipPermissions,
  4: vipPermissions,
  5: vipPermissions,
  6: eventPermissions,
};

export const setUserAppPermissions = (type, role) => {
  userPermissions = {};
  permissions[type].forEach(item => {
    userPermissions[item] = true;
  });
  if (role && role === 6) {
    userPermissions['kol_user'] = true;
  }
};

export const hasAccess = name => {
  const isEventsView = window.location.pathname.includes('events/');
  if (name === 'event_user') return isEventsView;
  if (name === 'vip_user') return !isEventsView;
  return !!userPermissions[name];
};

export const PRoute = props => {
  if (props.access && !hasAccess(props.access)) return <Redirect to='/' />;
  return <Route {...props} />;
};

export const clearPermissions = () => {
  userPermissions = {};
};
