/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import topLogo from 'assets/ACE-logo.svg';
import topLogoRSNA from 'assets/RSNA_logo.svg';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowDown } from 'shared/components/Icons';
import { logout } from 'app/Auth/actions';
import { hasAccess } from 'utils/permissionHelper';
import { getEventId, getEventUrl, onOpenExternalUrl } from 'utils/appHelpers';
import { navigation } from './configs';

const HeaderNav = ({ openAdmin }) => {
  const eventId = getEventId();
  const dispatch = useDispatch();
  const [accountMenu, setAccountMenu] = useState();
  const [dropMenu, setDropMenu] = useState();
  const accountMenuEl = useRef();
  const dropMenuEl = useRef();
  const account = useSelector(state => state.account);
  const events = useSelector(state => state.events);
  const event = events && eventId ? events[eventId] : null;

  const logoutProfile = e => {
    e.preventDefault();
    dispatch(logout());
  };

  useOutsideClick(accountMenuEl, () => setAccountMenu(false));
  useOutsideClick(dropMenuEl, () => setDropMenu(false));

  const isEvent = hasAccess('event_user');
  const path = isEvent ? getEventUrl() : '';
  const homeUrl = isEvent ? getEventUrl(true) : '/home';

  return (
    <div className='dashboard-header d-flex flex-column justify-content-center'>
      <div className='dashboard-nav w-100'>
        <div className='d-flex justify-content-between'>
          <Link to={homeUrl}>
            <img src={isEvent ? topLogoRSNA : topLogo} height='45' alt='topLogo' />
          </Link>
          <ul className='m-0 p-0 d-flex justify-content-end align-items-center'>
            {isEvent && event && <NavLink to={homeUrl}>RSNA 2020</NavLink>}
            {navigation &&
              navigation.map(nav => {
                if (nav.permission && !hasAccess(nav.permission)) return null;
                if (nav.menu)
                  return (
                    <li key={nav.name}>
                      <div
                        className={`drop-link ${dropMenu ? 'open' : ''}`}
                        onClick={e => setDropMenu(!dropMenu)}
                        ref={dropMenuEl}
                      >
                        {nav.name}{' '}
                        <IconArrowDown width='10' height='10' className='ml-1' color='#fff' />
                        {dropMenu && (
                          <ul className='drop-link-menu'>
                            {nav.menu.map(i => (
                              <li key={i.path}>
                                {i.path ? (
                                  <NavLink activeClassName='active' to={`${path}/${i.path}`}>
                                    {i.name}
                                  </NavLink>
                                ) : (
                                  <a
                                    href={i.link}
                                    target='_blank'
                                    onClick={onOpenExternalUrl.bind(null, i.link)}
                                  >
                                    {i.name}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                return (
                  <li key={nav.name}>
                    <NavLink activeClassName='active' to={`${path}/${nav.path}`}>
                      {nav.name}
                    </NavLink>
                  </li>
                );
              })}
            <li>
              <div
                className={`drop-link drop-account d-flex align-items-center ${
                  accountMenu ? 'open' : ''
                }`}
                onClick={e => setAccountMenu(!accountMenu)}
                ref={accountMenuEl}
              >
                <div className='avatar'>
                  <img src={account.imageUrl} alt={account.name} />
                </div>
                <span>
                  {account.firstName} <br /> {account.lastName}
                </span>
                <IconArrowDown width='10' height='10' color='#fff' />
                {accountMenu && (
                  <ul className='drop-link-menu'>
                    <li>
                      <NavLink activeClassName='active' to={`${path}/profile`}>
                        Profile
                      </NavLink>
                    </li>
                    {hasAccess('kol_user') && (
                      <li>
                        <a
                          href='#'
                          onClick={e => {
                            e.preventDefault();
                            openAdmin();
                          }}
                        >
                          KOL Admin
                        </a>
                      </li>
                    )}
                    <li>
                      <a href='#' onClick={logoutProfile}>
                        Log out
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
