import React from 'react';
import { IconPlayFill, IconLock, IconVideos } from 'shared/components/Icons';
import { bindDuration, getEventUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

const LessonsListSection = ({ lessons, activeLessonn, history, courseId }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';

  const lessonClickHandle = (lesson, e) => {
    if (lesson.status !== 1) {
      e.preventDefault();
      return;
    }

    const isPLayButton = e.target.ariaLabel === 'lessonPlay';

    if (!isPLayButton) {
      history.push(`${path}/courses/${courseId}/${lesson.type}/${lesson.id}`);
      return;
    }

    const lessonPath = `${path}/courses/${courseId}/${lesson.type}/${lesson.id}`;

    if (lesson.type === 'cases') {
      history.push(`${lessonPath}/play`);
    } else if (lesson.type === 'video') {
      history.push(`${lessonPath}/auto`);
    } else {
      history.push(`${lessonPath}`);
    }

    window.scrollTo(0, 0);
  };

  return (
    <div className='courses-list'>
      {lessons &&
        lessons.map(lesson => {
          const Icon = lesson.status === 1 ? IconPlayFill : IconLock;
          const active = lesson.id === activeLessonn.id;
          const isFeedback = lesson.type === 'feedback';
          return (
            <div
              key={lesson.id}
              onClick={lessonClickHandle.bind(null, lesson)}
              role='button'
              tabIndex='-1'
              className={`list-item d-flex pointer ${active ? 'active' : ''} ${
                lesson.status === 1 ? '' : 'not-allowed'
              } justify-content-between`}
            >
              <div className='d-flex align-items-center'>
                <div aria-label='lessonPlay'>
                  <Icon ariaLabel='lessonPlay' />
                </div>
                {lesson.image && <img className='mx-3' src={lesson.image} alt={lesson.name} />}
                <div className='d-flex flex-column'>
                  <p className='name'>{lesson.name}</p>
                  <p className='description'>{lesson.description}</p>
                </div>
              </div>
              {!isFeedback && (
                <div className='time-part d-flex flex-column justify-content-around align-items-end'>
                  {lesson.episodes && (
                    <span>
                      {lesson.episodes.length} <IconVideos />
                    </span>
                  )}
                  <p>{bindDuration(lesson.duration)}</p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LessonsListSection;
