import React from 'react';
import { IconCourses } from 'shared/components/Icons';

const Requirements = () => {
  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconCourses color='#131f6b' />
          </span>
          System Requirements
        </div>
      </div>
      <div className='p-3'>
        <h3>Browser</h3>
        <p className='ml-2'>
          ● Chrome (min version <b>79.0.3945</b>)
        </p>
        <p className='ml-2'>
          ● Mozilla Firefox (min version <b>78.0</b>)
        </p>
        <p className='ml-2'>
          ● Safari Mac (min version <b>13</b>)
        </p>
        <p className='ml-2'>
          ● Microsoft Edge (min version <b>79.0.309</b>)
        </p>
        <hr />
        <h3>Hardware</h3>
        <p className='ml-2'>● Standard laptop, desktop computer or iPad</p>
        <p className='ml-2'>● Resolution of at least 1366×768</p>
        <hr />
        <h3>Internet Connection</h3>
        <p className='ml-2'>
          ● No firewall blocking ACE domain (hologicace.com)
        </p>
        <p className='ml-2'>● Bandwidth 7 MBits/s connection</p>
        <hr />
        <h3>Software</h3>
        <p className='ml-2'>● Google Chrome</p>
        <p className='ml-2'>● Mozilla Firefox</p>
        <p className='ml-2'>● Safari (Mac)</p>
        <p className='ml-2'>● Microsoft Edge</p>
      </div>
    </div>
  );
};

export default Requirements;
