import { createReducer } from 'utils/reduxHelpers';
import { Api } from 'utils/connectors';
import { formatCourseAuthors } from '../../Authors/actions';
import { getDate } from 'utils/appHelpers';
import { ON_LOGOUT } from 'app/Auth/actions';

const ON_COURSES_GET = '@@courses/ON_COURSES_GET';
const ON_COURSE_UPDATE = '@@courses/ON_COURSE_UPDATE';

const onCoursesGet = payload => ({ type: ON_COURSES_GET, payload });
export const onCourseUpdate = payload => ({ type: ON_COURSE_UPDATE, payload });

const coursesHandlers = {
  [ON_COURSES_GET]: (state, action) => action.payload,
  [ON_COURSE_UPDATE]: (state, action) => {
    const tempCourses = [...state];
    const courseIndex = tempCourses.findIndex(item => item.id === action.payload.id);
    tempCourses[courseIndex] = action.payload;
    return tempCourses;
  },
  [ON_LOGOUT]: () => null,
};

const formatFranchisesData = data => {
  if (!data || !data.length) return [];
  return data.map(item => item.franchise.id);
};

const formatEpisodesData = (data, course) => {
  if (!data) return [];
  const result = [];
  data.forEach(episode => {
    const { userEpisode } = episode;
    result.push({
      id: episode.id,
      name: episode.title,
      time: episode.startFrom,
      duration: episode.duration,
      status: course.isOpened ? 1 : userEpisode ? userEpisode.userEpisodeStatus : -1,
      caseId: episode.caseId,
      orderNum: episode.orderNum,
      canSkipAfter: episode.canSkipAfter,
    });
  });
  return result;
};

const formatLessonsData = (data, course) => {
  if (!data) return [];
  const types = { 1: 'video', 2: 'pdf', 3: 'cases', 4: 'image', 5: 'feedback' };
  const result = [];
  data.forEach(({ lesson }) => {
    if (!lesson) return;
    const { userLesson, episodes } = lesson;
    result.push({
      id: lesson.id,
      type: types[lesson.contentType],
      name: lesson.title,
      description: lesson.info,
      image: lesson.imageUrl,
      duration: lesson.duration,
      url: lesson.contentUrl,
      caseIds: lesson.cases ? lesson.cases : [],
      startDate: userLesson ? getDate(userLesson.startDate) : '',
      endDate: userLesson ? getDate(userLesson.endDate) : '',
      progress: userLesson ? userLesson.progress : 0,
      correctProgress: userLesson ? userLesson.questionaryProgress : null,
      completed: course.isOpened ? lesson.duration : userLesson ? userLesson.completed : 0,
      status: course.isOpened ? 1 : userLesson ? userLesson.userLessonStatus : -1,
      userLessonId: userLesson ? userLesson.id : null,
      episodes: formatEpisodesData(episodes, course),
      lessonTags: lesson.lessonTags,
    });
  });
  return result;
};

export const formatCourseData = data => {
  if (!data) return [];
  const result = [];
  data.forEach(data => {
    const course = data.course || data;
    const { userCourse, courseLessons, courseFranchises, courseTags } = course;
    result.push({
      id: course.id,
      name: course.title,
      description: course.info,
      image: course.imageUrl,
      progress: userCourse ? userCourse.progress : null,
      status: userCourse ? userCourse.userCourseStatus : -1,
      expire: userCourse ? userCourse.endDate : null,
      subscriptionId: userCourse ? userCourse.subscriptionId : null,
      userSubscriptionStatus: userCourse ? userCourse.userSubscriptionStatus : null,
      authors: formatCourseAuthors(course.courseAuthors),
      lessons: formatLessonsData(courseLessons, course),
      franchises: formatFranchisesData(courseFranchises),
      courseTags: courseTags,
      courseTagsIds: courseTags ? courseTags.map(item => item.tag.id) : [],
      certificateUrl: userCourse ? userCourse.certificateUrl : null,
      completedCases: userCourse ? userCourse.completedCases : null,
      totalCases: userCourse ? userCourse.totalCases : null,
      watchedVideoDuration: userCourse ? userCourse.watchedVideoDuration : null,
      totalVideoDuration: userCourse ? userCourse.totalVideoDuration : null,
      isOpened: course.isOpened,
      expirable: course.expirable,
      isCertificateAvailable: course.isCertificateAvailable,
      isEvaluationAvailable: course.isEvaluationAvailable,
      subscriptionPlans: course.subscriptionPlans,
      courseType: course.courseType,
    });
  });
  return result;
};

export const getCourses = () => async dispatch => {
  try {
    const { data } = await Api.get('/courses/getusercourses');
    await dispatch(onCoursesGet(formatCourseData(data.data)));
  } catch (err) {
    throw new Error(err);
  }
};

const coursesReducerInitialState = null;

export const coursesReducer = createReducer(coursesReducerInitialState, coursesHandlers);
