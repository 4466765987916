import React, { useState, createRef, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { Api } from 'utils/connectors';
import { onCourseUpdate, getCourses } from 'app/Main/routes/Courses/actions';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const TypePDF = ({ lesson, course, getCourses }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const pdfContainerRef = createRef();
  let scrollingOffset = 0;
  let PDFNode = null;
  const width = window.innerWidth - 230 - 310;

  const updateLessonsTimeAndEpisode = page => {
    const tempCourse = { ...course };
    const lessonIndex = tempCourse.lessons.findIndex(item => item.id === lesson.id);
    const tempLesson = tempCourse.lessons[lessonIndex];
    tempLesson.completed = page;
    dispatch(onCourseUpdate(tempCourse));
  };

  const sendLessonTime = page => {
    const body = { lessonId: lesson.id, completed: page };
    Api.post('/courses/updateuserlesson', body);
    updateLessonsTimeAndEpisode(page);
    getCourses();
  };

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setPages(new Array(numPages).fill(1));
  };

  const selectPage = index => {
    if (!PDFNode) {
      PDFNode = Array.from(pdfContainerRef.current.children).find(
        elem => elem.className === 'react-pdf__Document' && elem,
      );
    }
    setTimeout(() => {
      PDFNode.scrollTop = 0;
      setPage(index + 1);
    }, 0);
  };

  const handlePdfScrolling = () => {
    if (pages.length < 2) return;
    if (!PDFNode) {
      PDFNode = Array.from(pdfContainerRef.current.children).find(
        elem => elem.className === 'react-pdf__Document' && elem,
      );
    }
    const { scrollHeight, scrollTop, clientHeight } = PDFNode;
    const scrolledTillEnd = scrollTop + clientHeight === scrollHeight;

    setTimeout(() => {
      const nextCondition = scrollingOffset < scrollTop && scrolledTillEnd && page !== pages.length;
      const prevCondition = scrollingOffset > scrollTop && scrollTop === 0 && page !== 1;
      if (nextCondition) {
        PDFNode.scrollTop = 1;
        setPage(page + 1);
      } else if (prevCondition) {
        PDFNode.scrollTop = scrollHeight - clientHeight - 1;
        setPage(page - 1);
      }
      scrollingOffset = scrollTop;
    }, 0);
  };

  useEffect(() => {
    if (page > lesson.completed) {
      sendLessonTime(page);
    }
    //eslint-disable-next-line
  }, [page]);

  return (
    <>
      <div ref={pdfContainerRef} className='col p-0' onScroll={handlePdfScrolling} id='myElement'>
        <Document
          ref={pdfContainerRef}
          file={lesson.url}
          onLoadSuccess={onDocumentLoadSuccess}
          id='myElement2'
        >
          <Page width={width} pageNumber={page} id='myElement3' />
        </Document>
      </div>
      <div className='course-parts'>
        <p className='course-title'>{lesson.name} - Chapters</p>
        <div className='course-parts-lists'>
          {pages.map((item, index) => (
            <div
              onClick={() => selectPage(index)}
              role='button'
              tabIndex='-1'
              key={index}
              className={`part-item d-flex align-items-center px-3 ${
                index + 1 === page ? 'active' : ''
              }`}
            >
              <div>
                <p className='name'>Page N{index + 1}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default connect(
  null,
  { getCourses },
)(TypePDF);
