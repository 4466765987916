import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import InfoStep from './components/InfoStep';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { IconLeft } from 'shared/components/Icons';
import FormStep from './components/FormStep';
import { Link } from 'react-router-dom';
import ButtonLine from 'shared/components/ButtonLine';
import cancelIcon from 'assets/cancel-red.svg';
import SuccessInfo from 'app/Auth/components/SuccessInfo';
import { useSelector } from 'react-redux';
import blueLogo from 'assets/ACE-logo-blue.svg';

const alreadyExistUser = {
  title: 'Success!',
  subTitle: 'Registration successfully completed!',
  className: 'col-12 pt-5',
};

const Register = ({ history, match }) => {
  const isAuth = useSelector(state => state.isAuthenticated);
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [hasUser, setHasUser] = useState(false);
  const [error, setError] = useState();
  const [fetch, setFetch] = useState(false);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const [token, setToken] = useState();
  const [form, setForm] = useState({});
  const regCode = match.params.code;
  const isEventUser = isAuth && account && account.userType === 6;

  const handleFormSubmit = async (data, form) => {
    setFetch(true);
    try {
      setForm({ ...form, email: '', password: '', rpassword: '' });
      const res = await Api.post('/auth/register', data);
      setToken(res.data.data);
      handleStepChange(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const handleStepChange = bool => {
    if (bool) {
      setStep(step + 1);
      return;
    }
    step === 1 ? history.push('/login') : setStep(step - 1);
  };

  const getUserdata = async code => {
    try {
      const { data } = await Api.get(`/auth/getinviteuser/${code}`);
      setUser({
        fname: data.data.firstName,
        lname: data.data.lastName,
        email: data.data.email,
        degree: data.data.degree,
      });
      setHasUser(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/login');
    }
  };

  const setEventUserData = () => {
    setUser({
      fname: account.firstName,
      lname: account.lastName,
      email: account.email,
      institution: account.learnerProfile.institution,
    });
  };

  const onClickNo = async () => {
    try {
      setFetch(true);
      await Api.post(`/auth/cancel`, token);
      enqueueSnackbar('Successfully Canceled', { variant: 'success' });
      setStep(2);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onClickYes = async () => {
    try {
      setFetch(true);
      await Api.post(`/auth/approve`, token);
      enqueueSnackbar('Successfully Approved', { variant: 'success' });
      history.push('/login');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    if (regCode) getUserdata(regCode);
    if (isEventUser) setEventUserData();
    //eslint-disable-next-line
  }, [regCode]);

  return (
    <div
      className={`align-items-center auth-block overflow-auto large d-flex py-5 position-relative ${
        step === 3 ? 'justify-content-center is-success' : ''
      }`}
    >
      {step < 3 && (
        <div className='position-absolute l-0'>
          <button className='btn btn-line d-flex' onClick={handleStepChange.bind(null, false)}>
            <IconLeft className='ml-2' />
            <span>Back</span>
          </button>
        </div>
      )}
      <div className='text-center logo-block'>
        <Link to='/login'>
          <img src={blueLogo} alt='logo' height='75' className='opc-3' />
        </Link>
      </div>
      {step === 1 && <InfoStep onNextClick={handleStepChange.bind(null, true)} />}
      {step === 2 && (
        <FormStep
          formSubmit={handleFormSubmit}
          user={user}
          error={error}
          fetch={fetch}
          regCode={regCode}
          lastForm={form}
          isEventUser={isEventUser}
        />
      )}
      {step === 3 && (
        <div
          className={`justify-content-center registration-success text-center ${
            !hasUser ? 'd-flex' : ''
          }`}
        >
          {hasUser ? (
            <SuccessInfo {...alreadyExistUser} />
          ) : (
            <div className='align-items-center d-flex flex-column'>
              <div className={`auth-success-block`}>
                <h1 className='title'>You're Almost Done!</h1>
                <p>
                  A confirmation email has been sent to <b>{token && token.email}</b> <br /> Please
                  check your email and click the confirmation link.
                </p>
              </div>
              <div className='mt-2 suc-btns d-flex justify-content-between align-items-center w-100'>
                <button
                  disabled={fetch}
                  className='btn d-flex align-items-center mt-1'
                  onClick={onClickNo}
                >
                  <img src={cancelIcon} alt='icon' />
                  The address is incorrect
                </button>
                <ButtonLine disabled={fetch} onClick={onClickYes} className='p-0'>
                  OK
                </ButtonLine>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Register;
