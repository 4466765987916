export const countries = [
  { name: 'United States', iso: 'US', iso3: 'USA', numCode: 840, phoneCode: 1, id: 226 },
  { name: 'Australia', iso: 'AU', iso3: 'AUS', numCode: 36, phoneCode: 61, id: 13 },
  { name: 'New Zealand', iso: 'NZ', iso3: 'NZL', numCode: 554, phoneCode: 64, id: 153 },
  { name: 'Afghanistan', iso: 'AF', iso3: 'AFG', numCode: 4, phoneCode: 93, id: 1 },
  { name: 'Albania', iso: 'AL', iso3: 'ALB', numCode: 8, phoneCode: 355, id: 2 },
  { name: 'Algeria', iso: 'DZ', iso3: 'DZA', numCode: 12, phoneCode: 213, id: 3 },
  { name: 'American Samoa', iso: 'AS', iso3: 'ASM', numCode: 16, phoneCode: 1684, id: 4 },
  { name: 'Andorra', iso: 'AD', iso3: 'AND', numCode: 20, phoneCode: 376, id: 5 },
  { name: 'Angola', iso: 'AO', iso3: 'AGO', numCode: 24, phoneCode: 244, id: 6 },
  { name: 'Anguilla', iso: 'AI', iso3: 'AIA', numCode: 660, phoneCode: 1264, id: 7 },
  { name: 'Antarctica', iso: 'AQ', iso3: null, numCode: null, phoneCode: 0, id: 8 },
  { name: 'Antigua and Barbuda', iso: 'AG', iso3: 'ATG', numCode: 28, phoneCode: 1268, id: 9 },
  { name: 'Argentina', iso: 'AR', iso3: 'ARG', numCode: 32, phoneCode: 54, id: 10 },
  { name: 'Armenia', iso: 'AM', iso3: 'ARM', numCode: 51, phoneCode: 374, id: 11 },
  { name: 'Aruba', iso: 'AW', iso3: 'ABW', numCode: 533, phoneCode: 297, id: 12 },
  { name: 'Australia', iso: 'AU', iso3: 'AUS', numCode: 36, phoneCode: 61, id: 13 },
  { name: 'Austria', iso: 'AT', iso3: 'AUT', numCode: 40, phoneCode: 43, id: 14 },
  { name: 'Azerbaijan', iso: 'AZ', iso3: 'AZE', numCode: 31, phoneCode: 994, id: 15 },
  { name: 'Bahamas', iso: 'BS', iso3: 'BHS', numCode: 44, phoneCode: 1242, id: 16 },
  { name: 'Bahrain', iso: 'BH', iso3: 'BHR', numCode: 48, phoneCode: 973, id: 17 },
  { name: 'Bangladesh', iso: 'BD', iso3: 'BGD', numCode: 50, phoneCode: 880, id: 18 },
  { name: 'Barbados', iso: 'BB', iso3: 'BRB', numCode: 52, phoneCode: 1246, id: 19 },
  { name: 'Belarus', iso: 'BY', iso3: 'BLR', numCode: 112, phoneCode: 375, id: 20 },
  { name: 'Belgium', iso: 'BE', iso3: 'BEL', numCode: 56, phoneCode: 32, id: 21 },
  { name: 'Belize', iso: 'BZ', iso3: 'BLZ', numCode: 84, phoneCode: 501, id: 22 },
  { name: 'Benin', iso: 'BJ', iso3: 'BEN', numCode: 204, phoneCode: 229, id: 23 },
  { name: 'Bermuda', iso: 'BM', iso3: 'BMU', numCode: 60, phoneCode: 1441, id: 24 },
  { name: 'Bhutan', iso: 'BT', iso3: 'BTN', numCode: 64, phoneCode: 975, id: 25 },
  { name: 'Bolivia', iso: 'BO', iso3: 'BOL', numCode: 68, phoneCode: 591, id: 26 },
  { name: 'Bosnia and Herzegovina', iso: 'BA', iso3: 'BIH', numCode: 70, phoneCode: 387, id: 27 },
  { name: 'Botswana', iso: 'BW', iso3: 'BWA', numCode: 72, phoneCode: 267, id: 28 },
  { name: 'Bouvet Island', iso: 'BV', iso3: null, numCode: null, phoneCode: 0, id: 29 },
  { name: 'Brazil', iso: 'BR', iso3: 'BRA', numCode: 76, phoneCode: 55, id: 30 },
  {
    name: 'British Indian Ocean Territory',
    iso: 'IO',
    iso3: null,
    numCode: null,
    phoneCode: 246,
    id: 31,
  },
  { name: 'Brunei Darussalam', iso: 'BN', iso3: 'BRN', numCode: 96, phoneCode: 673, id: 32 },
  { name: 'Bulgaria', iso: 'BG', iso3: 'BGR', numCode: 100, phoneCode: 359, id: 33 },
  { name: 'Burkina Faso', iso: 'BF', iso3: 'BFA', numCode: 854, phoneCode: 226, id: 34 },
  { name: 'Burundi', iso: 'BI', iso3: 'BDI', numCode: 108, phoneCode: 257, id: 35 },
  { name: 'Cambodia', iso: 'KH', iso3: 'KHM', numCode: 116, phoneCode: 855, id: 36 },
  { name: 'Cameroon', iso: 'CM', iso3: 'CMR', numCode: 120, phoneCode: 237, id: 37 },
  { name: 'Canada', iso: 'CA', iso3: 'CAN', numCode: 124, phoneCode: 1, id: 38 },
  { name: 'Cape Verde', iso: 'CV', iso3: 'CPV', numCode: 132, phoneCode: 238, id: 39 },
  { name: 'Cayman Islands', iso: 'KY', iso3: 'CYM', numCode: 136, phoneCode: 1345, id: 40 },
  {
    name: 'Central African Republic',
    iso: 'CF',
    iso3: 'CAF',
    numCode: 140,
    phoneCode: 236,
    id: 41,
  },
  { name: 'Chad', iso: 'TD', iso3: 'TCD', numCode: 148, phoneCode: 235, id: 42 },
  { name: 'Chile', iso: 'CL', iso3: 'CHL', numCode: 152, phoneCode: 56, id: 43 },
  { name: 'China', iso: 'CN', iso3: 'CHN', numCode: 156, phoneCode: 86, id: 44 },
  { name: 'Christmas Island', iso: 'CX', iso3: null, numCode: null, phoneCode: 61, id: 45 },
  {
    name: 'Cocos (Keeling) Islands',
    iso: 'CC',
    iso3: null,
    numCode: null,
    phoneCode: 672,
    id: 46,
  },
  { name: 'Colombia', iso: 'CO', iso3: 'COL', numCode: 170, phoneCode: 57, id: 47 },
  { name: 'Comoros', iso: 'KM', iso3: 'COM', numCode: 174, phoneCode: 269, id: 48 },
  { name: 'Congo', iso: 'CG', iso3: 'COG', numCode: 178, phoneCode: 242, id: 49 },
  {
    name: 'Congo, the Democratic Republic of the',
    iso: 'CD',
    iso3: 'COD',
    numCode: 180,
    phoneCode: 242,
    id: 50,
  },
  { name: 'Cook Islands', iso: 'CK', iso3: 'COK', numCode: 184, phoneCode: 682, id: 51 },
  { name: 'Costa Rica', iso: 'CR', iso3: 'CRI', numCode: 188, phoneCode: 506, id: 52 },
  { name: "Cote D'Ivoire", iso: 'CI', iso3: 'CIV', numCode: 384, phoneCode: 225, id: 53 },
  { name: 'Croatia', iso: 'HR', iso3: 'HRV', numCode: 191, phoneCode: 385, id: 54 },
  { name: 'Cuba', iso: 'CU', iso3: 'CUB', numCode: 192, phoneCode: 53, id: 55 },
  { name: 'Cyprus', iso: 'CY', iso3: 'CYP', numCode: 196, phoneCode: 357, id: 56 },
  { name: 'Czech Republic', iso: 'CZ', iso3: 'CZE', numCode: 203, phoneCode: 420, id: 57 },
  { name: 'Denmark', iso: 'DK', iso3: 'DNK', numCode: 208, phoneCode: 45, id: 58 },
  { name: 'Djibouti', iso: 'DJ', iso3: 'DJI', numCode: 262, phoneCode: 253, id: 59 },
  { name: 'Dominica', iso: 'DM', iso3: 'DMA', numCode: 212, phoneCode: 1767, id: 60 },
  { name: 'Dominican Republic', iso: 'DO', iso3: 'DOM', numCode: 214, phoneCode: 1809, id: 61 },
  { name: 'Ecuador', iso: 'EC', iso3: 'ECU', numCode: 218, phoneCode: 593, id: 62 },
  { name: 'Egypt', iso: 'EG', iso3: 'EGY', numCode: 818, phoneCode: 20, id: 63 },
  { name: 'El Salvador', iso: 'SV', iso3: 'SLV', numCode: 222, phoneCode: 503, id: 64 },
  { name: 'Equatorial Guinea', iso: 'GQ', iso3: 'GNQ', numCode: 226, phoneCode: 240, id: 65 },
  { name: 'Eritrea', iso: 'ER', iso3: 'ERI', numCode: 232, phoneCode: 291, id: 66 },
  { name: 'Estonia', iso: 'EE', iso3: 'EST', numCode: 233, phoneCode: 372, id: 67 },
  { name: 'Ethiopia', iso: 'ET', iso3: 'ETH', numCode: 231, phoneCode: 251, id: 68 },
  {
    name: 'Falkland Islands (Malvinas)',
    iso: 'FK',
    iso3: 'FLK',
    numCode: 238,
    phoneCode: 500,
    id: 69,
  },
  { name: 'Faroe Islands', iso: 'FO', iso3: 'FRO', numCode: 234, phoneCode: 298, id: 70 },
  { name: 'Fiji', iso: 'FJ', iso3: 'FJI', numCode: 242, phoneCode: 679, id: 71 },
  { name: 'Finland', iso: 'FI', iso3: 'FIN', numCode: 246, phoneCode: 358, id: 72 },
  { name: 'France', iso: 'FR', iso3: 'FRA', numCode: 250, phoneCode: 33, id: 73 },
  { name: 'French Guiana', iso: 'GF', iso3: 'GUF', numCode: 254, phoneCode: 594, id: 74 },
  { name: 'French Polynesia', iso: 'PF', iso3: 'PYF', numCode: 258, phoneCode: 689, id: 75 },
  {
    name: 'French Southern Territories',
    iso: 'TF',
    iso3: null,
    numCode: null,
    phoneCode: 0,
    id: 76,
  },
  { name: 'Gabon', iso: 'GA', iso3: 'GAB', numCode: 266, phoneCode: 241, id: 77 },
  { name: 'Gambia', iso: 'GM', iso3: 'GMB', numCode: 270, phoneCode: 220, id: 78 },
  { name: 'Georgia', iso: 'GE', iso3: 'GEO', numCode: 268, phoneCode: 995, id: 79 },
  { name: 'Germany', iso: 'DE', iso3: 'DEU', numCode: 276, phoneCode: 49, id: 80 },
  { name: 'Ghana', iso: 'GH', iso3: 'GHA', numCode: 288, phoneCode: 233, id: 81 },
  { name: 'Gibraltar', iso: 'GI', iso3: 'GIB', numCode: 292, phoneCode: 350, id: 82 },
  { name: 'Greece', iso: 'GR', iso3: 'GRC', numCode: 300, phoneCode: 30, id: 83 },
  { name: 'Greenland', iso: 'GL', iso3: 'GRL', numCode: 304, phoneCode: 299, id: 84 },
  { name: 'Grenada', iso: 'GD', iso3: 'GRD', numCode: 308, phoneCode: 1473, id: 85 },
  { name: 'Guadeloupe', iso: 'GP', iso3: 'GLP', numCode: 312, phoneCode: 590, id: 86 },
  { name: 'Guam', iso: 'GU', iso3: 'GUM', numCode: 316, phoneCode: 1671, id: 87 },
  { name: 'Guatemala', iso: 'GT', iso3: 'GTM', numCode: 320, phoneCode: 502, id: 88 },
  { name: 'Guinea', iso: 'GN', iso3: 'GIN', numCode: 324, phoneCode: 224, id: 89 },
  { name: 'Guinea-Bissau', iso: 'GW', iso3: 'GNB', numCode: 624, phoneCode: 245, id: 90 },
  { name: 'Guyana', iso: 'GY', iso3: 'GUY', numCode: 328, phoneCode: 592, id: 91 },
  { name: 'Haiti', iso: 'HT', iso3: 'HTI', numCode: 332, phoneCode: 509, id: 92 },
  {
    name: 'Heard Island and Mcdonald Islands',
    iso: 'HM',
    iso3: null,
    numCode: null,
    phoneCode: 0,
    id: 93,
  },
  {
    name: 'Holy See (Vatican City State)',
    iso: 'VA',
    iso3: 'VAT',
    numCode: 336,
    phoneCode: 39,
    id: 94,
  },
  { name: 'Honduras', iso: 'HN', iso3: 'HND', numCode: 340, phoneCode: 504, id: 95 },
  { name: 'Hong Kong', iso: 'HK', iso3: 'HKG', numCode: 344, phoneCode: 852, id: 96 },
  { name: 'Hungary', iso: 'HU', iso3: 'HUN', numCode: 348, phoneCode: 36, id: 97 },
  { name: 'Iceland', iso: 'IS', iso3: 'ISL', numCode: 352, phoneCode: 354, id: 98 },
  { name: 'India', iso: 'IN', iso3: 'IND', numCode: 356, phoneCode: 91, id: 99 },
  { name: 'Indonesia', iso: 'ID', iso3: 'IDN', numCode: 360, phoneCode: 62, id: 100 },
  {
    name: 'Iran, Islamic Republic of',
    iso: 'IR',
    iso3: 'IRN',
    numCode: 364,
    phoneCode: 98,
    id: 101,
  },
  { name: 'Iraq', iso: 'IQ', iso3: 'IRQ', numCode: 368, phoneCode: 964, id: 102 },
  { name: 'Ireland', iso: 'IE', iso3: 'IRL', numCode: 372, phoneCode: 353, id: 103 },
  { name: 'Israel', iso: 'IL', iso3: 'ISR', numCode: 376, phoneCode: 972, id: 104 },
  { name: 'Italy', iso: 'IT', iso3: 'ITA', numCode: 380, phoneCode: 39, id: 105 },
  { name: 'Jamaica', iso: 'JM', iso3: 'JAM', numCode: 388, phoneCode: 1876, id: 106 },
  { name: 'Japan', iso: 'JP', iso3: 'JPN', numCode: 392, phoneCode: 81, id: 107 },
  { name: 'Jordan', iso: 'JO', iso3: 'JOR', numCode: 400, phoneCode: 962, id: 108 },
  { name: 'Kazakhstan', iso: 'KZ', iso3: 'KAZ', numCode: 398, phoneCode: 7, id: 109 },
  { name: 'Kenya', iso: 'KE', iso3: 'KEN', numCode: 404, phoneCode: 254, id: 110 },
  { name: 'Kiribati', iso: 'KI', iso3: 'KIR', numCode: 296, phoneCode: 686, id: 111 },
  {
    name: "Korea, Democratic People's Republic of",
    iso: 'KP',
    iso3: 'PRK',
    numCode: 408,
    phoneCode: 850,
    id: 112,
  },
  { name: 'Korea, Republic of', iso: 'KR', iso3: 'KOR', numCode: 410, phoneCode: 82, id: 113 },
  { name: 'Kuwait', iso: 'KW', iso3: 'KWT', numCode: 414, phoneCode: 965, id: 114 },
  { name: 'Kyrgyzstan', iso: 'KG', iso3: 'KGZ', numCode: 417, phoneCode: 996, id: 115 },
  {
    name: "Lao People's Democratic Republic",
    iso: 'LA',
    iso3: 'LAO',
    numCode: 418,
    phoneCode: 856,
    id: 116,
  },
  { name: 'Latvia', iso: 'LV', iso3: 'LVA', numCode: 428, phoneCode: 371, id: 117 },
  { name: 'Lebanon', iso: 'LB', iso3: 'LBN', numCode: 422, phoneCode: 961, id: 118 },
  { name: 'Lesotho', iso: 'LS', iso3: 'LSO', numCode: 426, phoneCode: 266, id: 119 },
  { name: 'Liberia', iso: 'LR', iso3: 'LBR', numCode: 430, phoneCode: 231, id: 120 },
  {
    name: 'Libyan Arab Jamahiriya',
    iso: 'LY',
    iso3: 'LBY',
    numCode: 434,
    phoneCode: 218,
    id: 121,
  },
  { name: 'Liechtenstein', iso: 'LI', iso3: 'LIE', numCode: 438, phoneCode: 423, id: 122 },
  { name: 'Lithuania', iso: 'LT', iso3: 'LTU', numCode: 440, phoneCode: 370, id: 123 },
  { name: 'Luxembourg', iso: 'LU', iso3: 'LUX', numCode: 442, phoneCode: 352, id: 124 },
  { name: 'Macao', iso: 'MO', iso3: 'MAC', numCode: 446, phoneCode: 853, id: 125 },
  {
    name: 'Macedonia, the Former Yugoslav Republic of',
    iso: 'MK',
    iso3: 'MKD',
    numCode: 807,
    phoneCode: 389,
    id: 126,
  },
  { name: 'Madagascar', iso: 'MG', iso3: 'MDG', numCode: 450, phoneCode: 261, id: 127 },
  { name: 'Malawi', iso: 'MW', iso3: 'MWI', numCode: 454, phoneCode: 265, id: 128 },
  { name: 'Malaysia', iso: 'MY', iso3: 'MYS', numCode: 458, phoneCode: 60, id: 129 },
  { name: 'Maldives', iso: 'MV', iso3: 'MDV', numCode: 462, phoneCode: 960, id: 130 },
  { name: 'Mali', iso: 'ML', iso3: 'MLI', numCode: 466, phoneCode: 223, id: 131 },
  { name: 'Malta', iso: 'MT', iso3: 'MLT', numCode: 470, phoneCode: 356, id: 132 },
  { name: 'Marshall Islands', iso: 'MH', iso3: 'MHL', numCode: 584, phoneCode: 692, id: 133 },
  { name: 'Martinique', iso: 'MQ', iso3: 'MTQ', numCode: 474, phoneCode: 596, id: 134 },
  { name: 'Mauritania', iso: 'MR', iso3: 'MRT', numCode: 478, phoneCode: 222, id: 135 },
  { name: 'Mauritius', iso: 'MU', iso3: 'MUS', numCode: 480, phoneCode: 230, id: 136 },
  { name: 'Mayotte', iso: 'YT', iso3: null, numCode: null, phoneCode: 269, id: 137 },
  { name: 'Mexico', iso: 'MX', iso3: 'MEX', numCode: 484, phoneCode: 52, id: 138 },
  {
    name: 'Micronesia, Federated States of',
    iso: 'FM',
    iso3: 'FSM',
    numCode: 583,
    phoneCode: 691,
    id: 139,
  },
  { name: 'Moldova, Republic of', iso: 'MD', iso3: 'MDA', numCode: 498, phoneCode: 373, id: 140 },
  { name: 'Monaco', iso: 'MC', iso3: 'MCO', numCode: 492, phoneCode: 377, id: 141 },
  { name: 'Mongolia', iso: 'MN', iso3: 'MNG', numCode: 496, phoneCode: 976, id: 142 },
  { name: 'Montserrat', iso: 'MS', iso3: 'MSR', numCode: 500, phoneCode: 1664, id: 143 },
  { name: 'Morocco', iso: 'MA', iso3: 'MAR', numCode: 504, phoneCode: 212, id: 144 },
  { name: 'Mozambique', iso: 'MZ', iso3: 'MOZ', numCode: 508, phoneCode: 258, id: 145 },
  { name: 'Myanmar', iso: 'MM', iso3: 'MMR', numCode: 104, phoneCode: 95, id: 146 },
  { name: 'Namibia', iso: 'NA', iso3: 'NAM', numCode: 516, phoneCode: 264, id: 147 },
  { name: 'Nauru', iso: 'NR', iso3: 'NRU', numCode: 520, phoneCode: 674, id: 148 },
  { name: 'Nepal', iso: 'NP', iso3: 'NPL', numCode: 524, phoneCode: 977, id: 149 },
  { name: 'Netherlands', iso: 'NL', iso3: 'NLD', numCode: 528, phoneCode: 31, id: 150 },
  { name: 'Netherlands Antilles', iso: 'AN', iso3: 'ANT', numCode: 530, phoneCode: 599, id: 151 },
  { name: 'New Caledonia', iso: 'NC', iso3: 'NCL', numCode: 540, phoneCode: 687, id: 152 },
  { name: 'Nicaragua', iso: 'NI', iso3: 'NIC', numCode: 558, phoneCode: 505, id: 154 },
  { name: 'Niger', iso: 'NE', iso3: 'NER', numCode: 562, phoneCode: 227, id: 155 },
  { name: 'Nigeria', iso: 'NG', iso3: 'NGA', numCode: 566, phoneCode: 234, id: 156 },
  { name: 'Niue', iso: 'NU', iso3: 'NIU', numCode: 570, phoneCode: 683, id: 157 },
  { name: 'Norfolk Island', iso: 'NF', iso3: 'NFK', numCode: 574, phoneCode: 672, id: 158 },
  {
    name: 'Northern Mariana Islands',
    iso: 'MP',
    iso3: 'MNP',
    numCode: 580,
    phoneCode: 1670,
    id: 159,
  },
  { name: 'Norway', iso: 'NO', iso3: 'NOR', numCode: 578, phoneCode: 47, id: 160 },
  { name: 'Oman', iso: 'OM', iso3: 'OMN', numCode: 512, phoneCode: 968, id: 161 },
  { name: 'Pakistan', iso: 'PK', iso3: 'PAK', numCode: 586, phoneCode: 92, id: 162 },
  { name: 'Palau', iso: 'PW', iso3: 'PLW', numCode: 585, phoneCode: 680, id: 163 },
  {
    name: 'Palestinian Territory, Occupied',
    iso: 'PS',
    iso3: null,
    numCode: null,
    phoneCode: 970,
    id: 164,
  },
  { name: 'Panama', iso: 'PA', iso3: 'PAN', numCode: 591, phoneCode: 507, id: 165 },
  { name: 'Papua New Guinea', iso: 'PG', iso3: 'PNG', numCode: 598, phoneCode: 675, id: 166 },
  { name: 'Paraguay', iso: 'PY', iso3: 'PRY', numCode: 600, phoneCode: 595, id: 167 },
  { name: 'Peru', iso: 'PE', iso3: 'PER', numCode: 604, phoneCode: 51, id: 168 },
  { name: 'Philippines', iso: 'PH', iso3: 'PHL', numCode: 608, phoneCode: 63, id: 169 },
  { name: 'Pitcairn', iso: 'PN', iso3: 'PCN', numCode: 612, phoneCode: 0, id: 170 },
  { name: 'Poland', iso: 'PL', iso3: 'POL', numCode: 616, phoneCode: 48, id: 171 },
  { name: 'Portugal', iso: 'PT', iso3: 'PRT', numCode: 620, phoneCode: 351, id: 172 },
  { name: 'Puerto Rico', iso: 'PR', iso3: 'PRI', numCode: 630, phoneCode: 1787, id: 173 },
  { name: 'Qatar', iso: 'QA', iso3: 'QAT', numCode: 634, phoneCode: 974, id: 174 },
  { name: 'Reunion', iso: 'RE', iso3: 'REU', numCode: 638, phoneCode: 262, id: 175 },
  { name: 'Romania', iso: 'RO', iso3: 'ROM', numCode: 642, phoneCode: 40, id: 176 },
  { name: 'Russian Federation', iso: 'RU', iso3: 'RUS', numCode: 643, phoneCode: 70, id: 177 },
  { name: 'Rwanda', iso: 'RW', iso3: 'RWA', numCode: 646, phoneCode: 250, id: 178 },
  { name: 'Saint Helena', iso: 'SH', iso3: 'SHN', numCode: 654, phoneCode: 290, id: 179 },
  {
    name: 'Saint Kitts and Nevis',
    iso: 'KN',
    iso3: 'KNA',
    numCode: 659,
    phoneCode: 1869,
    id: 180,
  },
  { name: 'Saint Lucia', iso: 'LC', iso3: 'LCA', numCode: 662, phoneCode: 1758, id: 181 },
  {
    name: 'Saint Pierre and Miquelon',
    iso: 'PM',
    iso3: 'SPM',
    numCode: 666,
    phoneCode: 508,
    id: 182,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: 'VC',
    iso3: 'VCT',
    numCode: 670,
    phoneCode: 1784,
    id: 183,
  },
  { name: 'Samoa', iso: 'WS', iso3: 'WSM', numCode: 882, phoneCode: 684, id: 184 },
  { name: 'San Marino', iso: 'SM', iso3: 'SMR', numCode: 674, phoneCode: 378, id: 185 },
  {
    name: 'Sao Tome and Principe',
    iso: 'ST',
    iso3: 'STP',
    numCode: 678,
    phoneCode: 239,
    id: 186,
  },
  { name: 'Saudi Arabia', iso: 'SA', iso3: 'SAU', numCode: 682, phoneCode: 966, id: 187 },
  { name: 'Senegal', iso: 'SN', iso3: 'SEN', numCode: 686, phoneCode: 221, id: 188 },
  {
    name: 'Serbia and Montenegro',
    iso: 'CS',
    iso3: null,
    numCode: null,
    phoneCode: 381,
    id: 189,
  },
  { name: 'Seychelles', iso: 'SC', iso3: 'SYC', numCode: 690, phoneCode: 248, id: 190 },
  { name: 'Sierra Leone', iso: 'SL', iso3: 'SLE', numCode: 694, phoneCode: 232, id: 191 },
  { name: 'Singapore', iso: 'SG', iso3: 'SGP', numCode: 702, phoneCode: 65, id: 192 },
  { name: 'Slovakia', iso: 'SK', iso3: 'SVK', numCode: 703, phoneCode: 421, id: 193 },
  { name: 'Slovenia', iso: 'SI', iso3: 'SVN', numCode: 705, phoneCode: 386, id: 194 },
  { name: 'Solomon Islands', iso: 'SB', iso3: 'SLB', numCode: 90, phoneCode: 677, id: 195 },
  { name: 'Somalia', iso: 'SO', iso3: 'SOM', numCode: 706, phoneCode: 252, id: 196 },
  { name: 'South Africa', iso: 'ZA', iso3: 'ZAF', numCode: 710, phoneCode: 27, id: 197 },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso: 'GS',
    iso3: null,
    numCode: null,
    phoneCode: 0,
    id: 198,
  },
  { name: 'Spain', iso: 'ES', iso3: 'ESP', numCode: 724, phoneCode: 34, id: 199 },
  { name: 'Sri Lanka', iso: 'LK', iso3: 'LKA', numCode: 144, phoneCode: 94, id: 200 },
  { name: 'Sudan', iso: 'SD', iso3: 'SDN', numCode: 736, phoneCode: 249, id: 201 },
  { name: 'Suriname', iso: 'SR', iso3: 'SUR', numCode: 740, phoneCode: 597, id: 202 },
  {
    name: 'Svalbard and Jan Mayen',
    iso: 'SJ',
    iso3: 'SJM',
    numCode: 744,
    phoneCode: 47,
    id: 203,
  },
  { name: 'Swaziland', iso: 'SZ', iso3: 'SWZ', numCode: 748, phoneCode: 268, id: 204 },
  { name: 'Sweden', iso: 'SE', iso3: 'SWE', numCode: 752, phoneCode: 46, id: 205 },
  { name: 'Switzerland', iso: 'CH', iso3: 'CHE', numCode: 756, phoneCode: 41, id: 206 },
  { name: 'Syrian Arab Republic', iso: 'SY', iso3: 'SYR', numCode: 760, phoneCode: 963, id: 207 },
  {
    name: 'Taiwan, Province of China',
    iso: 'TW',
    iso3: 'TWN',
    numCode: 158,
    phoneCode: 886,
    id: 208,
  },
  { name: 'Tajikistan', iso: 'TJ', iso3: 'TJK', numCode: 762, phoneCode: 992, id: 209 },
  {
    name: 'Tanzania, United Republic of',
    iso: 'TZ',
    iso3: 'TZA',
    numCode: 834,
    phoneCode: 255,
    id: 210,
  },
  { name: 'Thailand', iso: 'TH', iso3: 'THA', numCode: 764, phoneCode: 66, id: 211 },
  { name: 'Timor-Leste', iso: 'TL', iso3: null, numCode: null, phoneCode: 670, id: 212 },
  { name: 'Togo', iso: 'TG', iso3: 'TGO', numCode: 768, phoneCode: 228, id: 213 },
  { name: 'Tokelau', iso: 'TK', iso3: 'TKL', numCode: 772, phoneCode: 690, id: 214 },
  { name: 'Tonga', iso: 'TO', iso3: 'TON', numCode: 776, phoneCode: 676, id: 215 },
  { name: 'Trinidad and Tobago', iso: 'TT', iso3: 'TTO', numCode: 780, phoneCode: 1868, id: 216 },
  { name: 'Tunisia', iso: 'TN', iso3: 'TUN', numCode: 788, phoneCode: 216, id: 217 },
  { name: 'Turkey', iso: 'TR', iso3: 'TUR', numCode: 792, phoneCode: 90, id: 218 },
  { name: 'Turkmenistan', iso: 'TM', iso3: 'TKM', numCode: 795, phoneCode: 7370, id: 219 },
  {
    name: 'Turks and Caicos Islands',
    iso: 'TC',
    iso3: 'TCA',
    numCode: 796,
    phoneCode: 1649,
    id: 220,
  },
  { name: 'Tuvalu', iso: 'TV', iso3: 'TUV', numCode: 798, phoneCode: 688, id: 221 },
  { name: 'Uganda', iso: 'UG', iso3: 'UGA', numCode: 800, phoneCode: 256, id: 222 },
  { name: 'Ukraine', iso: 'UA', iso3: 'UKR', numCode: 804, phoneCode: 380, id: 223 },
  { name: 'United Arab Emirates', iso: 'AE', iso3: 'ARE', numCode: 784, phoneCode: 971, id: 224 },
  { name: 'United Kingdom', iso: 'GB', iso3: 'GBR', numCode: 826, phoneCode: 44, id: 225 },
  {
    name: 'United States Minor Outlying Islands',
    iso: 'UM',
    iso3: null,
    numCode: null,
    phoneCode: 1,
    id: 227,
  },
  { name: 'Uruguay', iso: 'UY', iso3: 'URY', numCode: 858, phoneCode: 598, id: 228 },
  { name: 'Uzbekistan', iso: 'UZ', iso3: 'UZB', numCode: 860, phoneCode: 998, id: 229 },
  { name: 'Vanuatu', iso: 'VU', iso3: 'VUT', numCode: 548, phoneCode: 678, id: 230 },
  { name: 'Venezuela', iso: 'VE', iso3: 'VEN', numCode: 862, phoneCode: 58, id: 231 },
  { name: 'Viet Nam', iso: 'VN', iso3: 'VNM', numCode: 704, phoneCode: 84, id: 232 },
  {
    name: 'Virgin Islands, British',
    iso: 'VG',
    iso3: 'VGB',
    numCode: 92,
    phoneCode: 1284,
    id: 233,
  },
  {
    name: 'Virgin Islands, U.s.',
    iso: 'VI',
    iso3: 'VIR',
    numCode: 850,
    phoneCode: 1340,
    id: 234,
  },
  { name: 'Wallis and Futuna', iso: 'WF', iso3: 'WLF', numCode: 876, phoneCode: 681, id: 235 },
  { name: 'Western Sahara', iso: 'EH', iso3: 'ESH', numCode: 732, phoneCode: 212, id: 236 },
  { name: 'Yemen', iso: 'YE', iso3: 'YEM', numCode: 887, phoneCode: 967, id: 237 },
  { name: 'Zambia', iso: 'ZM', iso3: 'ZMB', numCode: 894, phoneCode: 260, id: 238 },
  { name: 'Zimbabwe', iso: 'ZW', iso3: 'ZWE', numCode: 716, phoneCode: 263, id: 239 },
];

export const degrees = [
  { name: 'MD', code: 'MD' },
  { name: 'DO', code: 'DO' },
  { name: 'PhD', code: 'PhD' },
  { name: 'MBBS', code: 'MBBS' },
  { name: 'MS', code: 'MS' },
  { name: 'PA', code: 'PA' },
  { name: 'RN', code: 'RN' },
  { name: 'RT', code: 'RT' },
  { name: 'Other', code: 'Other' },
];

export const specialty = [
  { name: 'Surgery', code: 'Surgery' },
  { name: 'Radiology', code: 'Radiology' },
  { name: 'Fellowship in Radiology', code: 'Fellowship in Radiology' },
  { name: 'Oncology', code: 'Oncology' },
  { name: 'Radiation Oncology', code: 'Radiation Oncology' },
  { name: 'Medical Physics', code: 'Medical Physics' },
  { name: 'Internal Medicine', code: 'Internal Medicine' },
  { name: 'Dosimetry', code: 'Dosimetry' },
  { name: 'Other', code: 'Other' },
];

export const states = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District Of Columbia', code: 'DC' },
  { name: 'Federated States Of Micronesia', code: 'FM' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Guam', code: 'GU' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Palau', code: 'PW' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virgin Islands', code: 'VI' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];
