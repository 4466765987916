/* eslint-disable react/style-prop-object */
import React, { memo } from 'react';

const SvgIcon = memo(({ width, height, viewBox, children, className, ariaLabel }) => (
  <svg
    aria-label={ariaLabel}
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
  >
    {children}
  </svg>
));

export const IconLogo = ({ className, color = '#131f6b' }) => (
  <SvgIcon
    width='140.000000pt'
    height='24.000000pt'
    viewBox='0 0 140 24'
    preserveAspectRatio='xMidYMid meet'
    className={className}
  >
    <g>
      <g>
        <polygon
          fill={color}
          points='18.7,0.4 15,0.4 15,9.3 4.5,9.3 4.5,0.4 0,0.4 0,23.6 4.5,23.6 4.5,13.3 15,13.3 15,23.6 19.4,23.6
			19.4,0.4 		'
        />
      </g>
      <path
        fill={color}
        d='M42.3,5.9c-1-1.8-2.4-3.3-4.2-4.3c-1.8-1-3.8-1.5-6.1-1.5c-3.4,0-6.3,1.1-8.4,3.3c-2.2,2.2-3.3,5.2-3.3,9
		c0,2,0.5,3.9,1.4,5.8c0.9,1.8,2.3,3.3,4.1,4.4c1.8,1,3.9,1.6,6.2,1.6c2.1,0,4.1-0.5,5.9-1.4c1.8-1,3.3-2.4,4.3-4.3
		c1-1.8,1.5-3.9,1.5-6.3C43.8,9.8,43.3,7.7,42.3,5.9 M39.2,12c0,2.6-0.7,4.6-2,6c-1.4,1.4-3,2.1-5.2,2.1c-2.1,0-3.7-0.7-5.1-2.1
		c-1.3-1.4-2-3.3-2-5.7c0-3.1,0.7-5.2,2.1-6.5c1.4-1.3,3.1-1.9,5.1-1.9c1.4,0,2.6,0.3,3.8,1c1.1,0.7,1.9,1.6,2.5,2.8
		C38.9,8.9,39.2,10.4,39.2,12'
      />
      <g>
        <polygon fill={color} points='49.2,19.6 49.2,0.4 44.7,0.4 44.7,23.6 60.5,23.6 60.5,19.6 		' />
      </g>
      <path
        fill={color}
        d='M80.2,5.9c-1-1.8-2.4-3.3-4.2-4.3c-1.8-1-3.8-1.5-6.1-1.5c-3.4,0-6.3,1.1-8.4,3.3c-2.2,2.2-3.3,5.2-3.3,9
		c0,2,0.5,3.9,1.4,5.8c0.9,1.8,2.3,3.3,4.1,4.4c1.8,1,3.9,1.6,6.2,1.6c2.1,0,4.1-0.5,5.9-1.4c1.8-1,3.3-2.4,4.3-4.3
		c1-1.8,1.5-3.9,1.5-6.3C81.6,9.8,81.1,7.7,80.2,5.9 M77.1,12c0,2.6-0.7,4.6-2,6c-1.4,1.4-3,2.1-5.2,2.1c-2.1,0-3.7-0.7-5.1-2.1
		c-1.4-1.4-2-3.3-2-5.7c0-3.1,0.7-5.2,2.1-6.5c1.4-1.3,3.1-1.9,5.1-1.9c1.4,0,2.6,0.3,3.7,1c1.1,0.7,1.9,1.6,2.5,2.8
		C76.8,8.9,77.1,10.4,77.1,12'
      />
      <path
        fill={color}
        d='M94.2,11.1v4h6.7v3c-0.6,0.4-1.4,0.9-2.4,1.3C97.4,19.8,96.2,20,95,20c-1.4,0-2.7-0.3-4-0.9
		c-1.2-0.6-2.1-1.4-2.8-2.6c-0.6-1.2-1-2.7-1-4.5c0-1.5,0.3-2.9,0.8-4.1c0.3-0.7,0.7-1.4,1.3-2c0.5-0.6,1.3-1,2.2-1.4
		c0.9-0.4,2-0.6,3.3-0.6c1.1,0,2,0.2,2.9,0.5c0.8,0.3,1.5,0.8,1.9,1.3c0.5,0.6,0.8,1.4,1.2,2.4l0.2,0.7l4.1-1.1L105,7
		c-0.4-1.6-1.1-2.9-1.9-3.8c-0.8-1-2-1.8-3.4-2.3c-1.4-0.5-3-0.8-4.8-0.8c-2.4,0-4.5,0.5-6.4,1.4c-1.9,1-3.3,2.4-4.3,4.4
		c-1,1.9-1.5,4-1.5,6.3c0,2.3,0.5,4.3,1.5,6.2c1,1.9,2.5,3.3,4.5,4.3c1.9,1,4.1,1.4,6.5,1.4c1.7,0,3.5-0.3,5.1-0.9
		c1.6-0.6,3.3-1.5,4.8-2.7l0.3-0.2v-9.2H94.2'
      />
      <g>
        <polygon fill={color} points='110.9,0.4 107.2,0.4 107.2,23.6 111.6,23.6 111.6,0.4 		' />
      </g>
      <path
        fill={color}
        d='M130.4,14.4l-0.2,0.7c-0.4,1.7-1.1,2.9-2.1,3.8c-1.1,0.8-2.3,1.3-3.9,1.3c-1.3,0-2.5-0.3-3.6-0.9
		c-1.1-0.6-1.8-1.5-2.3-2.8c-0.5-1.3-0.8-2.8-0.8-4.6c0-1.3,0.2-2.7,0.7-3.9c0.4-1.2,1.2-2.2,2.2-2.9c1.1-0.7,2.4-1.1,4-1.1
		c1.4,0,2.5,0.3,3.4,1c0.9,0.6,1.6,1.7,2.1,3.1l0.2,0.6l4.3-1l-0.2-0.7c-0.7-2.2-1.8-3.9-3.5-5.1C129,0.6,126.9,0,124.5,0
		c-2.1,0-4.1,0.5-5.9,1.4c-1.8,0.9-3.2,2.3-4.2,4.1c-1,1.8-1.5,3.9-1.5,6.3c0,2.2,0.4,4.2,1.3,6.1c0.9,1.9,2.1,3.4,3.8,4.5
		c1.7,1,3.8,1.5,6.4,1.5c2.5,0,4.7-0.7,6.5-2c1.8-1.3,3-3.3,3.7-5.8l0.2-0.7L130.4,14.4'
      />
      <path
        fill={color}
        d='M137.5,5.7c-1.5,0-2.5-1.1-2.5-2.5c0-1.5,1.1-2.5,2.5-2.5c1.3,0,2.5,1,2.5,2.5C140,4.7,138.9,5.7,137.5,5.7
		 M137.5,1c-1.1,0-1.9,0.9-1.9,2.1c0,1.2,0.7,2.1,1.9,2.1c1.1,0,1.9-0.9,1.9-2.1C139.5,1.9,138.6,1,137.5,1 M137,4.6h-0.5V1.8h1.1
		c0.7,0,1,0.2,1,0.8c0,0.5-0.3,0.7-0.7,0.8l0.8,1.2h-0.5l-0.7-1.2H137V4.6z M137.5,3c0.3,0,0.6,0,0.6-0.4c0-0.3-0.3-0.4-0.6-0.4H137
		V3H137.5z'
      />
    </g>
  </SvgIcon>
);

export const IconMail = ({ className, view, color = '#131f6b' }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 19 14' className={className}>
    <g>
      <g>
        <path
          className={view}
          fill={color}
          d='M16.7,0H2.3C1,0,0,1,0,2.2v9.5C0,13,1,14,2.3,14h14.4c1.3,0,2.3-1,2.3-2.2V2.3C19,1,18,0,16.7,0z M16.7,13
			H2.3c-0.7,0-1.3-0.5-1.3-1.2V2.3C1.1,1.6,1.6,1,2.3,1h14.4c0.7,0,1.3,0.5,1.3,1.2v9.5h0C17.9,12.4,17.4,13,16.7,13z M12,6.9l4.6-4
			c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.8,0L9.5,7.7L8.3,6.6c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1L3.1,2.1c-0.2-0.2-0.6-0.2-0.8,0
			c-0.2,0.2-0.2,0.5,0,0.7l4.7,4.1l-4.7,4.2c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4-0.1l4.8-4.3l1.3,1.1
			c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1l1.3-1.1l4.7,4.3c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.2
			c0.2-0.2,0.2-0.5,0-0.7L12,6.9z'
        />
      </g>
    </g>
  </SvgIcon>
);

export const IconMenu = ({ className, color = '#131f6b' }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 21 21' className={className}>
    <g>
      <g>
        <path
          fill={color}
          d='M10.5,21C4.7,21,0,16.3,0,10.5S4.7,0,10.5,0S21,4.7,21,10.5S16.3,21,10.5,21z M10.5,1C5.3,1,1,5.3,1,10.5
			S5.3,20,10.5,20s9.5-4.3,9.5-9.5S15.7,1,10.5,1z'
        />
      </g>
      <path
        d='M9.3,8.2c0.7,0,1.3-0.1,2-0.3c-0.2-0.3-0.4-0.6-0.7-0.8C10,8.3,9.4,9.6,8.8,10.8c-0.5,1-1.2,2.2-1,3.3
		c0.1,1.1,1.2,1.7,2.2,1.5c1.1-0.2,2.1-1.1,2.7-2c0.4-0.6-0.6-1.2-1-0.6c-0.4,0.6-1.2,1.4-2,1.5c-1,0.1-0.8-1-0.6-1.6
		c0.3-0.9,0.8-1.7,1.2-2.6c0.4-0.9,0.9-1.8,1.3-2.7c0.2-0.4-0.1-1-0.7-0.8C10.4,7,9.8,7.1,9.3,7.1C8.5,7.1,8.5,8.2,9.3,8.2L9.3,8.2z
		'
      />
      <circle cx='12' cy='5' r='1' />
    </g>
  </SvgIcon>
);

export const IconAbout = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='21' height='21' viewBox='0 0 21 21' className={className}>
    <g>
      <g>
        <path
          fill={color}
          d='M10.5,21C4.7,21,0,16.3,0,10.5S4.7,0,10.5,0S21,4.7,21,10.5S16.3,21,10.5,21z M10.5,1C5.3,1,1,5.3,1,10.5
			S5.3,20,10.5,20s9.5-4.3,9.5-9.5S15.7,1,10.5,1z'
        />
      </g>
      <path
        fill={color}
        d='M9.3,8.2c0.7,0,1.3-0.1,2-0.3c-0.2-0.3-0.4-0.6-0.7-0.8C10,8.3,9.4,9.6,8.8,10.8c-0.5,1-1.2,2.2-1,3.3
		c0.1,1.1,1.2,1.7,2.2,1.5c1.1-0.2,2.1-1.1,2.7-2c0.4-0.6-0.6-1.2-1-0.6c-0.4,0.6-1.2,1.4-2,1.5c-1,0.1-0.8-1-0.6-1.6
		c0.3-0.9,0.8-1.7,1.2-2.6c0.4-0.9,0.9-1.8,1.3-2.7c0.2-0.4-0.1-1-0.7-0.8C10.4,7,9.8,7.1,9.3,7.1C8.5,7.1,8.5,8.2,9.3,8.2L9.3,8.2z
		'
      />
      <circle fill={color} cx='12' cy='5' r='1' />
    </g>
  </SvgIcon>
);

export const IconAuthors = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='28' height='22' viewBox='0 0 28.4 20' className={className}>
    <g>
      <g>
        <path
          fill={color}
          d='M19,11c-3,0-5.5-2.5-5.5-5.5S16,0,19,0s5.5,2.5,5.5,5.5S22.1,11,19,11z M19,1c-2.5,0-4.5,2-4.5,4.5
			s2,4.5,4.5,4.5s4.5-2,4.5-4.5S21.5,1,19,1z'
        />
      </g>
      <g>
        <path
          fill={color}
          d='M27.9,20c-0.3,0-0.5-0.2-0.5-0.5c0-4.6-3.7-8.3-8.3-8.3s-8.3,3.7-8.3,8.3c0,0.3-0.2,0.5-0.5,0.5
			s-0.5-0.2-0.5-0.5c0-5.1,4.2-9.3,9.3-9.3s9.3,4.2,9.3,9.3C28.4,19.8,28.2,20,27.9,20z'
        />
      </g>
      <g>
        <path
          fill={color}
          d='M7.6,13c-2.5,0-4.5-2-4.5-4.5S5.2,4,7.6,4s4.5,2,4.5,4.5S10.1,13,7.6,13z M7.6,5C5.7,5,4.1,6.6,4.1,8.5
			S5.7,12,7.6,12s3.5-1.6,3.5-3.5S9.6,5,7.6,5z'
        />
      </g>
      <g>
        <path
          fill={color}
          d='M0.5,20C0.2,20,0,19.8,0,19.5c0-4,3.3-7.3,7.3-7.3c1.9,0,3.6,0.7,5,2c0.2,0.2-0.1,0.2-0.3,0.4
			s-0.2,0.5-0.4,0.3c-1.2-1.1-2.7-1.7-4.3-1.7C3.8,13.2,1,16,1,19.5C1,19.8,0.8,20,0.5,20z'
        />
      </g>
    </g>
  </SvgIcon>
);

export const IconAuthorsNew = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' className={className}>
    <g transform='translate(-2 -2)'>
      <path
        fill={color}
        d='M25.842,18.514l-3.309-.733v-.761a2.818,2.818,0,0,0,1.61-2.54v-.4h.4a.4.4,0,0,0,.4-.4,3.6,3.6,0,0,0-.829-2.307.329.329,0,0,0,.024-.109V9.223l.805-.242v1.474h.805V8.442a.406.406,0,0,0-.286-.386L23.74,7.54V3.208A1.211,1.211,0,0,0,22.532,2H3.208A1.211,1.211,0,0,0,2,3.208V20.117a1.211,1.211,0,0,0,1.208,1.208H16.493v4.429a.4.4,0,0,0,.4.4h8.857a.4.4,0,0,0,.4-.4V18.909a.405.405,0,0,0-.314-.395Zm-1.353.527-2.536,1.151.459-1.614ZM23.338,14.48a2.013,2.013,0,1,1-4.026,0v-.588l.648-.435.2.4a.4.4,0,0,0,.358.221.391.391,0,0,0,.149-.028l1.816-.729.854.572ZM20.7,20.193,18.16,19.042l2.077-.463Zm3.164-6.921-1.107-.737a.4.4,0,0,0-.37-.04l-1.671.668-.238-.475a.412.412,0,0,0-.254-.209.406.406,0,0,0-.326.056l-1.107.737h-.254a2.8,2.8,0,0,1,.8-1.59c0-.008.012-.012.02-.02H23.3c.008.008.016.012.02.016a2.821,2.821,0,0,1,.8,1.594Zm-4.549-2.416V9.464l1.9.572a.431.431,0,0,0,.234,0l1.9-.572v1.393ZM23.95,8.442l-2.625.789L18.7,8.442l2.625-.789ZM2.805,3.208a.4.4,0,0,1,.4-.4H22.532a.4.4,0,0,1,.4.4V5.221H2.805Zm14,15.307a.405.405,0,0,0-.314.395v1.61H3.208a.4.4,0,0,1-.4-.4V6.026h20.13V7.3l-1.494-.451a.459.459,0,0,0-.234,0L17.182,8.055a.4.4,0,0,0,0,.773l1.325.395V11.26a.329.329,0,0,0,.024.109,3.617,3.617,0,0,0-.829,2.307.4.4,0,0,0,.4.4h.4v.4a2.818,2.818,0,0,0,1.61,2.54v.761Zm2.5,6.836H17.3V19.533l2.013.918Zm1.61,0h-.805V20.813l.805.366Zm0-7.3v-.781a2.532,2.532,0,0,0,.805,0v.781l-.4,1.409Zm1.61,7.3h-.805V21.18l.805-.366Zm2.818,0H23.338v-4.9l2.013-.918Z'
        transform='translate(0)'
      />
    </g>
  </SvgIcon>
);

export const IconContacts = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='23' height='21' viewBox='0 0 21 21' className={className}>
    <g>
      <path
        fill={color}
        d='M16.8,21c-2.4,0-5.9-1.3-10.7-6.1c-6-6-6.5-10-5.9-12.4c0.2-0.6,0.5-1,1-1.3L3,0.1C3.4-0.2,4,0,4.3,0.4
		l2.9,4.4c0.3,0.4,0.2,1-0.2,1.3L5.4,7.4C5.1,7.6,5,8.1,5.2,8.5l0.1,0.2c0.5,0.8,1,1.9,3.1,3.9c2,2,3.1,2.6,3.9,3.1l0,0l0.2,0.1
		c0.4,0.2,0.9,0.1,1.1-0.2l1.3-1.6c0.3-0.4,0.9-0.5,1.3-0.2l4.4,2.9c0.4,0.3,0.5,0.8,0.3,1.3l-1.1,1.8c-0.3,0.5-0.8,0.9-1.3,1
		C18,20.9,17.4,21,16.8,21z M3.5,1L1.8,2.1C1.5,2.3,1.3,2.5,1.2,2.8C0.6,4.9,1.1,8.6,6.8,14.2c5.7,5.7,9.3,6.2,11.4,5.6
		c0.3-0.1,0.6-0.3,0.7-0.6l1.1-1.8l-4.4-2.9l-1.2,1.6c-0.6,0.7-1.6,0.9-2.4,0.5l-0.2-0.1l0,0c-0.9-0.5-2-1.1-4.1-3.2S5,10.1,4.5,9.2
		L4.3,8.9C3.9,8.1,4.1,7.1,4.8,6.6l1.6-1.3L3.5,1z'
      />
    </g>
  </SvgIcon>
);

export const IconCourses = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='21' height='21' viewBox='0 0 21 20' className={className}>
    <g>
      <path
        fill={color}
        d='M20.5,20H4.3C1.9,20,0,18.1,0,15.7V4.3C0,2,1.9,0,4.3,0h16.2C20.8,0,21,0.3,21,0.5v11c0,0.1,0,0.6-0.1,0.7
		l-0.2,0.3c-1.1,1.8-1.2,4-0.2,5.9l0.4,0.9c0.1,0.2,0.1,0.3,0,0.5C20.8,19.9,20.7,20,20.5,20z M1,15.3v0.4C1,17.5,2.5,19,4.3,19
		h15.4l-0.1-0.2c-1.1-2.2-1-4.7,0.3-6.8l0,0H4.3C2.5,12,1,13.5,1,15.3z M4.3,1C2.5,1,1,2.5,1,4.3v8.2C1.8,11.6,3,11,4.3,11H20V1H4.3
		z'
      />
    </g>
  </SvgIcon>
);

export const IconCoursesNew = ({ className, color = '#181818' }) => (
  <SvgIcon width='24' height='26' viewBox='0 0 26 26' className={className}>
    <g transform='translate(2.432 3)'>
      <rect fill='none' width='24' height='26' transform='translate(-2.432 -3)' />
      <g transform='translate(-5.932 -54.566)'>
        <path
          fill={color}
          d='M27.968,65.444l-.123-.041V61.164l.421-.127C29.074,60.8,29.5,60.4,29.5,59.88s-.426-.915-1.233-1.157l-9.63-2.889a7.747,7.747,0,0,0-2.137-.268,7.75,7.75,0,0,0-2.137.268L4.734,58.723c-.807.242-1.234.642-1.234,1.157s.426.915,1.233,1.157l3.73,1.119v5.637c0,.951.894,1.753,2.586,2.316a17.9,17.9,0,0,0,5.451.757,17.9,17.9,0,0,0,5.451-.757c1.691-.564,2.586-1.365,2.586-2.316V62.157l2.125-.637V65.4l-.123.041a2.246,2.246,0,0,0-1.532,2.129v3.308a2.247,2.247,0,1,0,4.493,0V67.574A2.245,2.245,0,0,0,27.968,65.444Zm-4.616,2.349c0,.223-.374.726-1.776,1.193a16.726,16.726,0,0,1-5.077.7,16.726,16.726,0,0,1-5.077-.7c-1.4-.467-1.776-.97-1.776-1.193V62.512l4.716,1.414a7.723,7.723,0,0,0,2.137.269,7.729,7.729,0,0,0,2.137-.269l4.716-1.414Zm-5.056-5a6.663,6.663,0,0,1-1.8.218,6.663,6.663,0,0,1-1.8-.218L5,59.88,14.7,56.968a6.642,6.642,0,0,1,1.8-.218,6.642,6.642,0,0,1,1.8.218L28,59.88Zm10.019,8.09a1.062,1.062,0,1,1-2.124,0V67.574a1.062,1.062,0,1,1,2.124,0Z'
        />
      </g>
    </g>
  </SvgIcon>
);

export const IconTranscript = ({ className, color = '#b6c1f2' }) => (
  <SvgIcon width='20.768' height='17.442' viewBox='0 0 20.768 17.442' className={className}>
    <g transform='translate(0 -41)'>
      <path
        fill={color}
        d='M317.241,252.54l-2.447-1.318A1.217,1.217,0,0,0,313,252.294v3.012a1.216,1.216,0,0,0,1.909,1l2.447-1.694a1.217,1.217,0,0,0-.116-2.072Zm-3.024,2.765v-3.012l2.447,1.318Z'
        transform='translate(-300.304 -201.555)'
      />
      <path
        fill={color}
        d='M69.5,241H65.608a.608.608,0,1,0,0,1.217H69.5a.608.608,0,0,0,0-1.217Z'
        transform='translate(-62.363 -191.888)'
      />
      <path
        fill={color}
        d='M69.5,305H65.608a.608.608,0,0,0,0,1.217H69.5a.608.608,0,0,0,0-1.217Z'
        transform='translate(-62.363 -253.292)'
      />
      <path
        fill={color}
        d='M69.5,369H65.608a.608.608,0,0,0,0,1.217H69.5a.608.608,0,0,0,0-1.217Z'
        transform='translate(-62.363 -314.696)'
      />
      <path
        fill={color}
        d='M18.942,41H1.825A1.827,1.827,0,0,0,0,42.825V56.616a1.827,1.827,0,0,0,1.825,1.825H18.942a1.827,1.827,0,0,0,1.825-1.825V42.825A1.827,1.827,0,0,0,18.942,41ZM1.825,42.217H18.942a.609.609,0,0,1,.608.608v3.367H1.217V42.825A.609.609,0,0,1,1.825,42.217ZM18.942,57.225H1.825a.609.609,0,0,1-.608-.608V47.409H19.551v9.208A.609.609,0,0,1,18.942,57.225Z'
      />
      <circle fill={color} cx='0.5' cy='0.5' r='0.5' transform='translate(3 44)' />
      <circle fill={color} cx='0.5' cy='0.5' r='0.5' transform='translate(5 44)' />
      <circle fill={color} cx='0.5' cy='0.5' r='0.5' transform='translate(8 44)' />
    </g>
  </SvgIcon>
);

export const IconPlay = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='7' height='10' viewBox='0 0 7 10' className={className}>
    <g>
      <path
        fill={color}
        d='M0.2,9.9C0.1,9.8,0,9.7,0,9.5v-9C0,0.3,0.1,0.1,0.3,0C0.4,0,0.7,0,0.8,0.1l6,4.5C6.9,4.7,7,4.8,7,5
		S6.9,5.3,6.8,5.4l-6,4.5C0.7,10,0.4,10.1,0.2,9.9z M1,1.5v7L5.7,5L1,1.5z'
      />
    </g>
  </SvgIcon>
);

export const IconHome = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='20' height='20' viewBox='0 0 20 20' className={className}>
    <path
      fill={color}
      d='M10,1c0.2,0,0.4,0.1,0.5,0.2l8.3,8C18.9,9.3,19,9.5,19,9.7v8.6c0,0.4-0.3,0.7-0.7,0.7H1.7C1.3,19,1,18.7,1,18.3
	V9.7c0-0.2,0.1-0.4,0.2-0.5l8.3-8C9.7,1,9.8,1,10,1 M10,0C9.6,0,9.1,0.2,8.8,0.5l-8.3,8C0.2,8.8,0,9.2,0,9.7v8.9
	C0,19.4,0.6,20,1.4,20h17.2c0.8,0,1.4-0.6,1.4-1.4V9.7c0-0.5-0.2-0.9-0.5-1.2l-8.3-8C10.9,0.1,10.4,0,10,0'
    />
  </SvgIcon>
);

export const IconStatistics = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='22' height='20' viewBox='0 0 21 20' className={className}>
    <g>
      <path
        fill={color}
        d='M20.5,20H2.6C1.2,20,0,18.8,0,17.4V0.5C0,0.2,0.2,0,0.5,0S1,0.2,1,0.5v16.9C1,18.3,1.7,19,2.6,19h17.9
		c0.3,0,0.5,0.2,0.5,0.5S20.8,20,20.5,20z'
      />
    </g>
    <g>
      <path
        fill={color}
        d='M5.5,16C5.2,16,5,15.8,5,15.5v-5C5,10.2,5.2,10,5.5,10S6,10.2,6,10.5v5C6,15.8,5.8,16,5.5,16z'
      />
    </g>
    <g>
      <path
        fill={color}
        d='M11.5,16c-0.3,0-0.5-0.2-0.5-0.5v-8C11,7.2,11.2,7,11.5,7S12,7.2,12,7.5v8C12,15.8,11.8,16,11.5,16z'
      />
    </g>
    <g>
      <path
        fill={color}
        d='M18.5,16c-0.3,0-0.5-0.2-0.5-0.5v-15C18,0.2,18.2,0,18.5,0S19,0.2,19,0.5v15C19,15.8,18.8,16,18.5,16z'
      />
    </g>
  </SvgIcon>
);

export const IconPassword = ({ className }) => (
  <SvgIcon width='16' height='18' viewBox='0 0 16 18' className={className}>
    <path
      d='M12.9,7C14,7,15,8,15,9v6.2c0,1-0.8,1.8-1.8,1.8H2.8c-1,0-1.8-0.8-1.8-1.8V9c0-1,0.9-2,1.9-2H12.9 M12.9,6h-10
	C1.4,6,0,7.4,0,9v6.2C0,16.7,1.3,18,2.8,18h10.3c1.6,0,2.8-1.3,2.8-2.8V9C16,7.4,14.5,6,12.9,6L12.9,6z'
    />
    <path d='M9,11v2H7v-2H9 M9,10H7c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-2C10,10.4,9.6,10,9,10L9,10z' />
    <g>
      <path
        d='M13.3,6.7h-1V3.3C12.3,2,11.1,1,9.8,1H6.2C4.9,1,3.7,2,3.7,3.3v3.5h-1V3.3C2.7,1.5,4.3,0,6.2,0h3.5
		c1.9,0,3.5,1.5,3.5,3.3V6.7z'
      />
    </g>
  </SvgIcon>
);

export const IconProfession = ({ className }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 21 17' className={className}>
    <path
      d='M19.2,3h-4.4l0-1.3c0-0.9-0.8-1.7-1.8-1.7H7.9c-1,0-1.8,0.8-1.8,1.7l0,1.3H1.7C0.8,3,0,3.8,0,4.7l0,10.6
	C0,16.2,0.8,17,1.7,17h17.5c1,0,1.8-0.8,1.8-1.7l0-10.6C21,3.8,20.2,3,19.2,3z M7,1.7c0-0.5,0.4-0.8,0.9-0.8h5.2
	c0.5,0,0.9,0.4,0.9,0.8L14,3H7L7,1.7z M19.2,16.1H1.7c-0.5,0-0.9-0.4-0.9-0.8V8.6c0.3,0.1,0.6,0.2,0.9,0.2h7v1.3
	c0,0.2,0.2,0.4,0.4,0.4h2.6c0.2,0,0.4-0.2,0.4-0.4V8.8h7c0.3,0,0.6-0.1,0.9-0.2v6.7C20.1,15.8,19.7,16.1,19.2,16.1z M9.6,9.7V8h1.8
	v1.7H9.6z M19.2,8h-7V7.6c0-0.2-0.2-0.4-0.4-0.4H9.2c-0.2,0-0.4,0.2-0.4,0.4V8h-7C1.3,8,0.9,7.6,0.9,7.1l0-2.4
	c0-0.5,0.4-0.8,0.9-0.8h17.5c0.5,0,0.9,0.4,0.9,0.8l0,2.4C20.1,7.6,19.7,8,19.2,8z'
    />
  </SvgIcon>
);

export const IconUsername = ({ className }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 16 15' className={className}>
    <g>
      <g>
        <path
          d='M8,8.4c2.4,0,4.3-1.9,4.3-4.2S10.3,0,8,0C5.6,0,3.7,1.9,3.7,4.2S5.6,8.4,8,8.4z M8,1.1c1.7,0,3.2,1.4,3.2,3.1
			S9.8,7.3,8,7.3c-1.7,0-3.2-1.4-3.2-3.1S6.2,1.1,8,1.1z M10.6,9.2H5.4c-3,0-5.4,2.4-5.4,5.3C0,14.8,0.3,15,0.6,15h14.9
			c0.3,0,0.6-0.2,0.6-0.6C16,11.5,13.6,9.2,10.6,9.2z M1.2,13.9c0.3-2,2-3.6,4.2-3.6h5.3c2.2,0,3.9,1.6,4.2,3.6H1.2z'
        />
      </g>
    </g>
  </SvgIcon>
);

export const IconCirclePlay = ({ className }) => (
  <SvgIcon width='35' height='35' viewBox='0 0 35 35' className={className}>
    <g>
      <g>
        <path
          fill='#131F6B'
          d='M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35C27.2,35,35,27.2,35,17.5S27.2,0,17.5,0z'
        />
      </g>
    </g>
    <path
      fill='#ffffff'
      d='M25.7,16.6l-11.8-7c-0.6-0.4-1.4,0.1-1.4,0.9v14.1c0,0.8,0.8,1.2,1.4,0.9l11.8-7C26.3,18,26.3,17,25.7,16.6z'
    />
  </SvgIcon>
);

export const IconLock = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='20' height='26' viewBox='0 0 13 16' className={className}>
    <path
      fill={color}
      d='M11.7,6.5h-0.2V4.8c0-2.6-2.1-4.7-4.9-4.8H6.4C3.6,0.1,1.5,2.2,1.5,4.8v1.7H1.3C0.6,6.5,0,7,0,7.7v7
	C0,15.4,0.6,16,1.3,16h10.4c0.7,0,1.3-0.6,1.3-1.3v-7C13,7,12.4,6.5,11.7,6.5z M7,11.7v1.6c0,0.3-0.2,0.5-0.5,0.5S6,13.5,6,13.3
	v-1.6c-0.4-0.2-0.7-0.6-0.7-1.1c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2C7.7,11.1,7.4,11.5,7,11.7z M9.3,6.5H3.7V4.8
	c0-1.5,1.3-2.7,2.8-2.7s2.8,1.2,2.8,2.7V6.5z'
    />
  </SvgIcon>
);

export const IconCourseLock = ({ className, color = '#b5bef6' }) => (
  <SvgIcon width='8' height='12' viewBox='0 0 8 12' className={className}>
    <g>
      <g>
        <path
          fill={color}
          d='M7.5,12h-7C0.2,12,0,11.8,0,11.5v-6C0,5.2,0.2,5,0.5,5h7C7.8,5,8,5.3,8,5.5v6C8,11.8,7.8,12,7.5,12z M1,11h6
			V6H1V11z'
        />
      </g>
      <g>
        <path
          fill={color}
          d='M6.5,5.6C6.2,5.6,6,5.4,6,5.1V3c0-0.8-0.5-1.5-1.2-1.8C4.2,0.9,3.5,0.9,2.9,1.3S2,2.3,2,3v2.2
			c0,0.3-0.2,0.5-0.5,0.5S1,5.4,1,5.1V3c0-1,0.5-1.9,1.3-2.5c0.9-0.6,1.9-0.7,2.9-0.3C6.3,0.6,7,1.7,7,3v2.2C7,5.4,6.8,5.6,6.5,5.6z
			'
        />
      </g>
    </g>
  </SvgIcon>
);

export const IconPlayFill = ({ className, color, ariaLabel }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 12 12' className={className} ariaLabel={ariaLabel}>
    <path
      aria-label={ariaLabel}
      fill={color}
      d='M11.6,5.4L1.3,0.1C0.7-0.2,0,0.2,0,0.7v10.6c0,0.6,0.7,0.9,1.3,0.6l10.3-5.4C12.1,6.3,12.1,5.6,11.6,5.4z'
    />
  </SvgIcon>
);

export const IconRight = ({ className, color }) => (
  <SvgIcon width='10' height='11' viewBox='0 0 8 7' className={className}>
    <path
      fill={color}
      d='M7.5,4h-7C0.2,4,0,3.8,0,3.5l0,0C0,3.2,0.2,3,0.5,3h7C7.8,3,8,3.2,8,3.5l0,0C8,3.8,7.8,4,7.5,4z'
    />
    <path
      fill={color}
      d='M7.1,3.9l-3-3C4,0.7,4,0.3,4.2,0.2l0,0C4.3,0,4.7,0,4.9,0.2l3,3C8,3.3,7.5,3.4,7.4,3.6l0.1-0.1
	C7.3,3.6,7.3,4,7.1,3.9z'
    />
    <path
      fill={color}
      d='M7.1,3.1l-3,3C4,6.3,4,6.7,4.2,6.8l0,0C4.3,7,4.7,7,4.9,6.8l3-3C8,3.7,7.7,3.5,7.6,3.3l-0.1,0
	C7.2,3.2,7.3,3,7.1,3.1z'
    />
  </SvgIcon>
);

export const IconLeft = props => (
  <div className='rotate-180'>
    <IconRight {...props} />
  </div>
);

export const IconVideos = ({ className, color = '#ffffff' }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 12 12' className={className}>
    <g>
      <g>
        <path
          fill={color}
          d='M11.4,8.3L10.7,8L7.4,9.5C7,9.7,6.5,9.8,6,9.8S5,9.7,4.6,9.5L1.3,8L0.6,8.3C0.2,8.5,0,8.8,0,9.1
			s0.2,0.6,0.6,0.7l4.3,2C5.2,11.9,5.6,12,6,12s0.8-0.1,1.1-0.2l4.3-2C11.8,9.6,12,9.4,12,9.1C12,8.8,11.8,8.5,11.4,8.3z M11.4,5.3
			L10.7,5L7.4,6.5C7,6.7,6.5,6.8,6,6.8S5,6.7,4.6,6.5L1.3,5L0.6,5.3C0.2,5.4,0,5.7,0,6s0.2,0.6,0.6,0.7l4.3,2C5.2,8.9,5.6,8.9,6,8.9
			s0.8-0.1,1.1-0.2l4.3-2C11.8,6.6,12,6.3,12,6S11.8,5.4,11.4,5.3z M0.6,3.7l4.3,2C5.2,5.8,5.6,5.9,6,5.9s0.8-0.1,1.1-0.2l4.3-2
			C11.8,3.5,12,3.2,12,2.9s-0.2-0.6-0.6-0.7l-4.3-2C6.8,0.1,6.4,0,6,0S5.2,0.1,4.9,0.2l-4.3,2C0.2,2.4,0,2.7,0,2.9
			C0,3.2,0.2,3.5,0.6,3.7z'
        />
      </g>
    </g>
    ;
  </SvgIcon>
);

export const IconBio = ({ className }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 16 16' className={className}>
    <g>
      <g>
        <g>
          <path
            d='M15.5,5.6c-1.4-4-6.1-6.2-10.4-4.8C5,0.8,3.9,1.2,2.8,1.9L3,0.7C3.1,0.4,2.9,0.1,2.6,0S2,0.1,2,0.4L1.4,3.2
				l0,0c0,0,0,0.2,0.1,0.4c0.1,0.2,0.3,0.2,0.4,0.2L4.7,4C5,4,5.2,3.8,5.3,3.5c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-1.4,0-1.4,0
				c1-0.7,2.2-1,2.2-1c3.7-1.2,7.7,0.7,9,4.2s-0.7,7.3-4.4,8.5s-7.7-0.7-9-4.2C1,10.1,0.7,9.9,0.4,10s-0.5,0.4-0.4,0.7
				c1.5,4.1,6.2,6.2,10.4,4.8C14.7,14.2,17,9.7,15.5,5.6z M7.6,10.3L7.6,10.3c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4-0.1l0,0l3.6-3.4
				C12,6.6,12,6.2,11.8,6c-0.2-0.2-0.6-0.2-0.8,0L7.7,9.2L4.8,8C4.5,7.9,4.1,8,4,8.3S4,8.9,4.3,9L7.6,10.3z'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const IconEducation = ({ className, color = '#ffffff' }) => (
  <SvgIcon width='22' height='18' viewBox='0 0 18 15' className={className}>
    <g>
      <g>
        <path
          fill={color}
          d='M15.5,12.6c-1-0.4-2.2-0.8-3.4-0.8c-1.4,0-2.2,0.6-2.7,1.1c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0
			c0.6-0.6,1.3-0.9,2.3-0.9c1.1,0,2.2,0.4,3.2,0.7c0.9,0.3,1.7,0.6,2.4,0.6c0.2,0,0.3-0.1,0.3-0.3V1.6c0-0.2-0.1-0.3-0.3-0.3
			c-0.6,0-1.4-0.3-2.2-0.6c-1-0.4-2.2-0.8-3.4-0.8c-1.4,0-2.2,0.6-2.7,1.1C9.2,1.3,9.1,1.5,9,1.7C8.9,1.5,8.8,1.3,8.6,1.1
			C8.1,0.6,7.3,0,5.9,0C4.7,0,3.5,0.4,2.5,0.8C1.7,1,0.9,1.3,0.3,1.3C0.1,1.3,0,1.4,0,1.6v11.8c0,0.2,0.1,0.3,0.3,0.3
			c0.7,0,1.5-0.3,2.4-0.6c1-0.3,2.1-0.7,3.2-0.7c1,0,1.7,0.3,2.3,0.9c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.3,0-0.4
			c-0.5-0.5-1.3-1.1-2.7-1.1c-1.2,0-2.4,0.4-3.4,0.8c-0.7,0.2-0.9,0.3-1.5,0.3V2c0.6-0.1,0.9-0.4,1.7-0.7c1-0.3,2.1-0.7,3.2-0.7
			c2.1,0,2.7,1.6,2.8,1.8V14H8.5l0,0c-0.5-0.5-1.3-1-2.6-1c-1.2,0-2.4,0.4-3.4,0.8c-0.8,0.3-1.6,0.6-2.2,0.6c-0.2,0-0.3,0.1-0.3,0.3
			S0.1,15,0.3,15c0.7,0,1.5-0.3,2.4-0.6c1-0.3,2.1-0.7,3.2-0.7c1,0,1.7,0.3,2.3,0.9c0.1,0.1,0.1,0.1,0.2,0.1h1.3
			c0.1,0,0.2,0,0.2-0.1c0.6-0.6,1.3-0.9,2.3-0.9c1.1,0,2.2,0.4,3.2,0.7c0.9,0.3,1.6,0.6,2.3,0.6c0.2,0,0.3-0.1,0.3-0.3
			s-0.1-0.3-0.3-0.3c-0.6,0-1.3-0.3-2.1-0.6c-1-0.4-2.2-0.8-3.4-0.8c-1.3,0-2.1,0.5-2.6,1l0,0H9.3V2.4c0.2-0.4,0.9-1.8,2.8-1.8
			c1.1,0,2.2,0.4,3.2,0.7C16,1.6,16.3,1.9,17,2v10.9C16.4,12.8,16.2,12.8,15.5,12.6z'
        />
      </g>
    </g>
  </SvgIcon>
);

export const IconExperiance = ({ className }) => (
  <SvgIcon width='30' height='22' viewBox='0 0 23 17' className={className}>
    <path
      d='M22.7,5.5l-11-5.4c-0.1-0.1-0.3-0.1-0.4,0l-11,5.4C0.1,5.6,0,5.7,0,5.9c0,0.2,0.1,0.3,0.3,0.4l3.6,1.8v3.6
	c0,2.2,3.3,4,7.6,4s7.6-1.8,7.6-4V8.2l1.3-0.6V11c-0.6,0.2-1,0.7-1,1.4c0,0.6,0.3,1.1,0.9,1.3l-0.9,2.5c-0.1,0.3-0.1,0.5,0,0.6
	s0.2,0.2,0.4,0.2H22c0.2,0,0.3-0.1,0.4-0.2s0.1-0.3,0-0.6l-0.9-2.5c0.5-0.2,0.9-0.7,0.9-1.3s-0.4-1.2-1-1.4V7l1.4-0.7
	c0.1,0,0.2-0.2,0.2-0.4C23,5.7,22.9,5.6,22.7,5.5z M16.3,13.8c-1.3,0.6-3,1-4.8,1s-3.5-0.3-4.8-1c-1.2-0.6-1.8-1.3-1.8-2.1V8.6
	l6.6,3.3l6.6-3.3v3.1l0,0C18.1,12.5,17.5,13.3,16.3,13.8z M20.3,16.1l0.5-1.3l0.5,1.3H20.3z M21.4,12.4c0,0.3-0.2,0.5-0.5,0.5
	s-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5l0,0l0,0C21.2,11.9,21.4,12.1,21.4,12.4z M20.7,6.3l-9.2-0.8c-0.3,0-0.5,0.2-0.5,0.4
	s0.2,0.5,0.4,0.5l7.7,0.7l-7.6,3.8l-9.9-5L11.5,1l9.9,4.9L20.7,6.3z'
    />
  </SvgIcon>
);

export const IconInfo = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='28' height='28' viewBox='0 0 26 26' className={className}>
    <path
      fill={color}
      d='M13,2c6.1,0,11,4.9,11,11s-4.9,11-11,11S2,19.1,2,13S6.9,2,13,2 M13,0C5.8,0,0,5.8,0,13s5.8,13,13,13
	s13-5.8,13-13S20.2,0,13,0L13,0z'
    />
    <g>
      <path
        fill={color}
        d='M11.4,7.5c0-0.4,0.1-0.7,0.4-0.9s0.6-0.4,1-0.4c0.4,0,0.8,0.1,1,0.4s0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
		s-0.6,0.4-1,0.4s-0.8-0.1-1-0.4S11.4,7.9,11.4,7.5z M14.1,19.4h-2.5V9.9h2.5V19.4z'
      />
    </g>
  </SvgIcon>
);

export const IconSubscription = ({ className, color = '#B5BEF6' }) => (
  <SvgIcon width='20' height='22' viewBox='0 0 22 17' className={className}>
    <path
      fill={color}
      d='M0,4.6c0.4,0,0.9,0,1.3,0c0-0.8,0-1.5,0-2.3c0.5,0,0.9,0,1.4,0c0-0.8,0-1.5,0-2.3c5.5,0,11,0,16.6,0
	c0,0.7,0,1.4,0,2.2c0.5,0,0.9,0,1.4,0c0,0.8,0,1.5,0,2.3c0.5,0,0.9,0,1.4,0.1c0,4.1,0,8.2,0,12.4c-7.3,0-14.6,0-22,0
	C0,12.9,0,8.8,0,4.6z M20.8,5.9c-6.5,0-13,0-19.5,0c0,3.3,0,6.6,0,9.9c6.5,0,13,0,19.5,0C20.8,12.5,20.8,9.2,20.8,5.9z M2.6,4.5
	c5.6,0,11.2,0,16.8,0c0-0.4,0-0.7,0-1c-5.6,0-11.2,0-16.8,0C2.6,3.9,2.6,4.2,2.6,4.5z M18,1.2c-4.7,0-9.4,0-14,0c0,0.4,0,0.7,0,1
	c4.7,0,9.3,0,14,0C18,1.9,18,1.6,18,1.2z'
    />
  </SvgIcon>
);

export const IconSearch = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 14 14' className={className}>
    <path
      fill={color}
      d='M13.8,13.1L9.7,8.9C10.5,8,11,6.8,11,5.5C11,2.5,8.5,0,5.5,0C2.5,0,0,2.5,0,5.5c0,3,2.4,5.5,5.5,5.5
	c1.3,0,2.5-0.5,3.5-1.2l4.1,4.1c0.2,0.2,0.6,0.2,0.8,0C14,13.6,14,13.3,13.8,13.1z M5.5,9.9c-2.4,0-4.4-2-4.4-4.4
	c0-2.4,2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4C9.9,7.9,7.9,9.9,5.5,9.9z'
    />
  </SvgIcon>
);

export const IconKey = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='13' height='13' viewBox='0 0 12 12' className={className}>
    <path
      fill={color}
      d='M8.2,0C6.1,0,4.4,1.7,4.4,3.8c0,0.4,0.1,0.9,0.2,1.3L0,9.8V12h2.2v-1.3h1.3V9.3h1.3l2-2
	c0.4,0.1,0.8,0.2,1.3,0.2c2.1,0,3.8-1.7,3.8-3.8C12.1,1.7,10.4,0,8.2,0z M9.4,4C8.7,4,8,3.4,8,2.7s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3
	S10.2,4,9.4,4z'
    />
  </SvgIcon>
);

export const IconArrowRight = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='13' height='13' viewBox='0 0 7 12' className={className}>
    <g>
      <path
        fill={color}
        d='M0.5,12c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0.1-0.7L5.7,6L0.2,0.9C0,0.7-0.1,0.4,0.1,0.2S0.6,0,0.8,0.1
		l6,5.5C6.9,5.7,7,5.9,7,6S6.9,6.3,6.8,6.4l-6,5.5C0.7,12,0.6,12,0.5,12z'
      />
    </g>
  </SvgIcon>
);

export const IconArrowLeft = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='13' height='13' viewBox='0 0 7 12' className={className}>
    <g>
      <path
        fill={color}
        d='M6.2,11.9l-6-5.5C0.1,6.3,0,6.2,0,6c0-0.2,0.1-0.3,0.2-0.4l6-5.5C6.4,0,6.7,0,6.9,0.2S7,0.7,6.8,0.9L1.3,6
		l5.5,5.1c0.3,0.2,0.3,0.5,0.1,0.7C6.8,11.9,6.6,12,6.5,12C6.4,12,6.3,12,6.2,11.9z'
      />
    </g>
  </SvgIcon>
);

export const IconArrowUp = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='13' height='13' viewBox='0 0 12 7' className={className}>
    <g>
      <path
        fill={color}
        d='M12,6.5c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.5,0.2-0.7-0.1L6,1.3L0.9,6.8C0.7,7,0.4,7.1,0.2,6.9S0,6.4,0.1,6.2
		l5.5-6C5.7,0.1,5.8,0,6,0c0.2,0,0.3,0.1,0.4,0.2l5.5,6C12,6.3,12,6.4,12,6.5z'
      />
    </g>
  </SvgIcon>
);

export const IconArrowDown = ({ className, color = '#131F6B', width = '13', height = '13' }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 12 7' className={className}>
    <g>
      <path
        fill={color}
        d='M11.9,0.8l-5.5,6C6.3,6.9,6.2,7,6,7C5.8,7,5.7,6.9,5.6,6.8l-5.5-6C0,0.6,0,0.3,0.2,0.1S0.7,0,0.9,0.2L6,5.7
		l5.1-5.5c0.2-0.3,0.5-0.3,0.7-0.1C11.9,0.2,12,0.4,12,0.5S12,0.7,11.9,0.8z'
      />
    </g>
  </SvgIcon>
);

export const IconFooterLogo = ({ className, color = '#fff' }) => (
  <SvgIcon width='100%' height='100%' viewBox='0 0 2021 275' className={className}>
    <g transform='matrix(4.16667,0,0,4.16667,0,0)'>
      <path
        d='M41.567,5.568L33.224,5.568L33.224,25.326L9.916,25.326L9.916,5.568L0,5.568L0,56.833L9.916,56.833L9.916,34.127L33.224,34.127L33.224,56.833L43.138,56.833L43.138,5.568L41.567,5.568Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M87.182,31.275C87.182,36.987 85.67,41.431 82.689,44.484C79.678,47.568 75.928,49.066 71.229,49.066C66.616,49.066 62.898,47.582 59.863,44.528C56.867,41.512 55.348,37.275 55.348,31.936C55.348,25.204 56.915,20.394 60.006,17.637C63.195,14.794 66.899,13.411 71.333,13.411C74.403,13.411 77.207,14.146 79.665,15.595C82.127,17.044 83.944,19.042 85.224,21.701C86.523,24.394 87.182,27.615 87.182,31.275ZM94.097,17.66C91.975,13.615 88.852,10.417 84.815,8.153C80.812,5.909 76.262,4.771 71.297,4.771C63.699,4.771 57.396,7.206 52.565,12.006C47.726,16.812 45.272,23.492 45.272,31.861C45.272,36.29 46.315,40.561 48.372,44.555C50.455,48.602 53.556,51.83 57.589,54.15C61.599,56.459 66.2,57.629 71.261,57.629C75.928,57.629 80.345,56.571 84.387,54.484C88.489,52.358 91.699,49.205 93.928,45.113C96.136,41.059 97.255,36.413 97.255,31.305C97.255,26.254 96.193,21.664 94.097,17.66Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M109.289,48.034L109.289,5.568L107.717,5.568L99.373,5.568L99.373,56.833L134.383,56.833L134.383,48.034L109.289,48.034Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M171.27,31.275C171.27,36.986 169.758,41.431 166.776,44.484C163.767,47.568 160.02,49.066 155.317,49.066C150.701,49.066 146.984,47.583 143.953,44.528C140.913,41.471 139.436,37.352 139.436,31.936C139.436,25.204 141.003,20.394 144.094,17.637C147.281,14.794 150.986,13.411 155.421,13.411C158.49,13.411 161.293,14.146 163.753,15.595C166.211,17.043 168.03,19.041 169.313,21.701C170.61,24.397 171.27,27.619 171.27,31.275ZM178.182,17.659C176.066,13.616 172.943,10.417 168.902,8.153C164.9,5.909 160.352,4.771 155.385,4.771C147.785,4.771 141.481,7.206 136.65,12.006C131.813,16.811 129.36,23.49 129.36,31.861C129.36,36.292 130.403,40.563 132.46,44.557C134.544,48.602 137.646,51.831 141.68,54.151C145.685,56.459 150.284,57.629 155.35,57.629C160.02,57.629 164.435,56.57 168.475,54.484C172.576,52.361 175.784,49.207 178.016,45.111C180.224,41.061 181.343,36.415 181.343,31.305C181.343,26.249 180.279,21.659 178.182,17.659Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M209.382,29.15L209.382,37.951L224.362,37.951L224.362,44.554C223.074,45.519 221.346,46.458 219.039,47.338C216.445,48.327 213.752,48.829 211.037,48.829C207.918,48.829 204.935,48.17 202.17,46.869C199.463,45.602 197.457,43.721 196.039,41.115C194.607,38.482 193.881,35.113 193.881,31.102C193.881,27.855 194.492,24.811 195.699,22.049C196.385,20.489 197.365,19.036 198.613,17.735C199.825,16.471 201.47,15.426 203.5,14.628C205.556,13.82 208.057,13.411 210.932,13.411C213.294,13.411 215.467,13.795 217.389,14.552C219.23,15.278 220.654,16.247 221.617,17.43C222.619,18.665 223.485,20.436 224.188,22.691L224.637,24.125L233.716,21.74L233.298,20.208C232.359,16.774 230.955,13.925 229.123,11.739C227.263,9.523 224.687,7.78 221.467,6.561C218.321,5.373 214.771,4.771 210.91,4.771C205.603,4.771 200.834,5.823 196.738,7.898C192.557,10.016 189.311,13.289 187.092,17.631C184.912,21.894 183.809,26.536 183.809,31.429C183.809,36.407 184.926,40.978 187.131,45.018C189.366,49.119 192.716,52.291 197.084,54.441C201.373,56.557 206.2,57.629 211.431,57.629C215.303,57.629 219.131,56.968 222.811,55.661C226.473,54.357 230.066,52.385 233.493,49.795L234.117,49.323L234.117,29.15L209.382,29.15Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M246.492,5.568L238.148,5.568L238.148,56.833L248.063,56.833L248.063,5.568L246.492,5.568Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M289.731,36.509L289.378,38.05C288.536,41.705 286.934,44.484 284.613,46.312C282.258,48.166 279.434,49.066 275.98,49.066C273.156,49.066 270.5,48.375 268.085,47.013C265.708,45.668 264.001,43.685 262.865,40.945C261.687,38.109 261.091,34.704 261.091,30.823C261.091,27.86 261.599,24.94 262.601,22.141C263.561,19.45 265.18,17.373 267.554,15.791C269.914,14.212 272.933,13.411 276.529,13.411C279.596,13.411 282.153,14.117 284.128,15.507C286.092,16.891 287.631,19.195 288.703,22.356L289.155,23.689L298.806,21.561L298.307,19.933C296.84,15.151 294.193,11.387 290.439,8.739C286.705,6.105 282.067,4.771 276.659,4.771C271.929,4.771 267.524,5.809 263.569,7.851C259.556,9.929 256.42,13.001 254.251,16.985C252.103,20.922 251.014,25.582 251.014,30.837C251.014,35.623 251.968,40.177 253.848,44.37C255.77,48.643 258.618,51.96 262.321,54.231C266.004,56.486 270.793,57.629 276.558,57.629C282.193,57.629 287.045,56.144 290.979,53.218C294.927,50.284 297.693,45.977 299.199,40.414L299.623,38.847L289.731,36.509Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M305.937,10.073C306.701,10.073 307.376,9.997 307.376,9.099C307.376,8.349 306.642,8.258 306.026,8.258L304.797,8.258L304.797,10.073L305.937,10.073ZM304.797,13.599L303.776,13.599L303.776,7.388L306.132,7.388C307.631,7.388 308.396,7.913 308.396,9.173C308.396,10.298 307.706,10.763 306.776,10.868L308.517,13.599L307.361,13.599L305.757,10.942L304.797,10.942L304.797,13.599ZM305.906,5.753C303.477,5.753 301.587,7.658 301.587,10.388C301.587,12.938 303.236,15.022 305.906,15.022C308.307,15.022 310.196,13.132 310.196,10.388C310.196,7.658 308.307,5.753 305.906,5.753ZM305.906,15.997C302.682,15.997 300.387,13.583 300.387,10.388C300.387,7.013 302.937,4.778 305.906,4.778C308.847,4.778 311.396,7.013 311.396,10.388C311.396,13.763 308.847,15.997 305.906,15.997Z'
        fill={color}
        fillRule='nonzero'
      />
      <path d='M325.295,4.779L325.295,56.893' fill='none' fillRule='nonzero' />
      <path d='M325.295,4.779L325.295,56.893' strokeWidth='1' fill='none' stroke='rgb(38,42,130)' />
      <path
        d='M370.525,26.62L370.525,5.358L364.149,5.358L358.985,18.204L353.821,5.358L347.477,5.358L347.477,26.62L352.004,26.62L352.004,11.733L357.997,26.62L359.973,26.62L365.966,11.733L365.966,26.62L370.525,26.62Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M381.682,27.003C384.072,27.003 386.495,26.269 388.025,24.867L386.24,22.253C385.252,23.209 383.435,23.783 382.128,23.783C379.514,23.783 377.952,22.157 377.697,20.213L389.141,20.213L389.141,19.32C389.141,14.283 386.017,10.841 381.427,10.841C376.741,10.841 373.457,14.443 373.457,18.906C373.457,23.847 376.996,27.003 381.682,27.003ZM385.22,17.471L377.633,17.471C377.824,15.941 378.908,14.06 381.427,14.06C384.104,14.06 385.124,16.005 385.22,17.471Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M406.441,26.62L406.441,5.358L402.361,5.358L402.361,13.2C401.149,11.638 399.428,10.841 397.611,10.841C393.69,10.841 390.789,13.901 390.789,18.938C390.789,24.07 393.722,27.003 397.611,27.003C399.46,27.003 401.149,26.174 402.361,24.644L402.361,26.62L406.441,26.62ZM398.854,23.4C396.559,23.4 394.965,21.583 394.965,18.938C394.965,16.26 396.559,14.443 398.854,14.443C400.225,14.443 401.691,15.176 402.361,16.196L402.361,21.647C401.691,22.667 400.225,23.4 398.854,23.4Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M411.988,9.566C413.327,9.566 414.41,8.482 414.41,7.143C414.41,5.804 413.327,4.752 411.988,4.752C410.681,4.752 409.565,5.804 409.565,7.143C409.565,8.482 410.681,9.566 411.988,9.566ZM414.028,26.62L414.028,11.223L409.979,11.223L409.979,26.62L414.028,26.62Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M424.669,27.003C427.824,27.003 429.737,25.632 430.757,24.197L428.111,21.743C427.378,22.763 426.262,23.4 424.86,23.4C422.405,23.4 420.684,21.583 420.684,18.906C420.684,16.228 422.405,14.443 424.86,14.443C426.262,14.443 427.378,15.017 428.111,16.1L430.757,13.614C429.737,12.211 427.824,10.841 424.669,10.841C419.919,10.841 416.508,14.188 416.508,18.906C416.508,23.655 419.919,27.003 424.669,27.003Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M446.383,26.62L446.383,16.674C446.383,12.243 443.163,10.841 439.657,10.841C437.234,10.841 434.811,11.606 432.931,13.263L434.461,15.973C435.768,14.762 437.298,14.156 438.955,14.156C440.996,14.156 442.334,15.176 442.334,16.738L442.334,18.81C441.314,17.599 439.497,16.929 437.457,16.929C435.003,16.929 432.102,18.3 432.102,21.902C432.102,25.345 435.003,27.003 437.457,27.003C439.465,27.003 441.282,26.269 442.334,25.026L442.334,26.62L446.383,26.62ZM439.083,24.261C437.489,24.261 436.182,23.432 436.182,21.998C436.182,20.5 437.489,19.671 439.083,19.671C440.39,19.671 441.665,20.117 442.334,21.01L442.334,22.922C441.665,23.815 440.39,24.261 439.083,24.261Z'
        fill={color}
        fillRule='nonzero'
      />
      <rect x='449.88' y='5.358' width='4.048' height='21.262' fill={color} fillRule='nonzero' />
      <path
        d='M362.524,56.82L362.524,52.835L352.004,52.835L352.004,48.022L362.3,48.022L362.3,44.037L352.004,44.037L352.004,39.542L362.524,39.542L362.524,35.558L347.477,35.558L347.477,56.82L362.524,56.82Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M380.447,56.82L380.447,35.558L376.367,35.558L376.367,43.399C375.156,41.837 373.434,41.04 371.617,41.04C367.696,41.04 364.796,44.101 364.796,49.137C364.796,54.27 367.728,57.202 371.617,57.202C373.466,57.202 375.156,56.374 376.367,54.843L376.367,56.82L380.447,56.82ZM372.861,53.6C370.565,53.6 368.972,51.783 368.972,49.137C368.972,46.46 370.565,44.643 372.861,44.643C374.231,44.643 375.698,45.376 376.367,46.396L376.367,51.847C375.698,52.867 374.231,53.6 372.861,53.6Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M397.949,56.82L397.949,41.423L393.901,41.423L393.901,51.847C393.2,52.739 391.956,53.6 390.394,53.6C388.673,53.6 387.557,52.899 387.557,50.763L387.557,41.423L383.509,41.423L383.509,52.357C383.509,55.353 385.103,57.202 388.482,57.202C391,57.202 392.849,56.055 393.901,54.875L393.901,56.82L397.949,56.82Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M408.181,57.202C411.337,57.202 413.25,55.832 414.27,54.397L411.624,51.943C410.891,52.963 409.775,53.6 408.373,53.6C405.918,53.6 404.197,51.783 404.197,49.105C404.197,46.428 405.918,44.643 408.373,44.643C409.775,44.643 410.891,45.216 411.624,46.3L414.27,43.814C413.25,42.411 411.337,41.04 408.181,41.04C403.431,41.04 400.021,44.388 400.021,49.105C400.021,53.855 403.431,57.202 408.181,57.202Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M429.896,56.82L429.896,46.874C429.896,42.443 426.677,41.04 423.17,41.04C420.748,41.04 418.325,41.805 416.444,43.463L417.974,46.173C419.281,44.961 420.811,44.356 422.469,44.356C424.509,44.356 425.848,45.376 425.848,46.938L425.848,49.01C424.828,47.798 423.011,47.129 420.971,47.129C418.516,47.129 415.615,48.5 415.615,52.102C415.615,55.545 418.516,57.202 420.971,57.202C422.979,57.202 424.796,56.469 425.848,55.226L425.848,56.82L429.896,56.82ZM422.596,54.461C421.003,54.461 419.696,53.632 419.696,52.198C419.696,50.699 421.003,49.871 422.596,49.871C423.903,49.871 425.179,50.317 425.848,51.209L425.848,53.122C425.179,54.015 423.903,54.461 422.596,54.461Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M438.551,57.202C440.24,57.202 441.324,56.756 441.93,56.214L441.069,53.154C440.846,53.377 440.272,53.6 439.667,53.6C438.774,53.6 438.264,52.867 438.264,51.911L438.264,44.961L441.388,44.961L441.388,41.423L438.264,41.423L438.264,37.215L434.184,37.215L434.184,41.423L431.634,41.423L431.634,44.961L434.184,44.961L434.184,52.994C434.184,55.736 435.714,57.202 438.551,57.202Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M446.01,39.765C447.349,39.765 448.433,38.681 448.433,37.343C448.433,36.004 447.349,34.952 446.01,34.952C444.703,34.952 443.587,36.004 443.587,37.343C443.587,38.681 444.703,39.765 446.01,39.765ZM448.05,56.82L448.05,41.423L444.002,41.423L444.002,56.82L448.05,56.82Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M458.251,57.202C463.352,57.202 466.412,53.505 466.412,49.105C466.412,44.738 463.352,41.04 458.251,41.04C453.183,41.04 450.123,44.738 450.123,49.105C450.123,53.505 453.183,57.202 458.251,57.202ZM458.251,53.6C455.733,53.6 454.33,51.528 454.33,49.105C454.33,46.715 455.733,44.643 458.251,44.643C460.77,44.643 462.204,46.715 462.204,49.105C462.204,51.528 460.77,53.6 458.251,53.6Z'
        fill={color}
        fillRule='nonzero'
      />
      <path
        d='M482.891,56.82L482.891,45.95C482.891,42.953 481.266,41.04 477.887,41.04C475.368,41.04 473.487,42.252 472.499,43.431L472.499,41.423L468.451,41.423L468.451,56.82L472.499,56.82L472.499,46.46C473.169,45.535 474.412,44.643 476.006,44.643C477.727,44.643 478.843,45.376 478.843,47.512L478.843,56.82L482.891,56.82Z'
        fill={color}
        fillRule='nonzero'
      />
    </g>
  </SvgIcon>
);

export const IconFeedback = ({ className, color = '#bfbfbf' }) => (
  <SvgIcon width='32.998' height='30.3' viewBox='0 0 32.998 30.3' className={className}>
    <defs />
    <g transform='translate(2.65 -18.68)'>
      <path
        fill={color}
        stroke={color}
        strokeWidth='0.3px'
        d='M27.5,21.529v-2.7h-30V48a.828.828,0,0,0,.513.769.816.816,0,0,0,.32.064.838.838,0,0,0,.589-.244l6.5-6.5h5.665v0H27.5V28.1l1.9-1.9.046-.047h0a2.74,2.74,0,0,0-1.942-4.63ZM18.284,33.443l.38-1.9,5.978-5.978,1.192,1.192v.66l-5.65,5.65Zm7.55-11.377a2.6,2.6,0,0,0-.315.265L20.753,27.1v-1.52H4.246v1.666H20.608l-2.383,2.382-14.015,0v1.666H17.016l-.61,3.051a.829.829,0,0,0,.227.753.838.838,0,0,0,.589.244.878.878,0,0,0,.163-.016l3.372-.675a.83.83,0,0,0,.426-.228l4.651-4.651v10.65H5.079a.827.827,0,0,0-.589.244L-.835,45.987V20.5H25.834Zm2.7,2.2a1.085,1.085,0,0,1-.315.76l-.718.718V23.195A1.076,1.076,0,0,1,28.533,24.269Z'
      />
      <path
        fill={color}
        stroke={color}
        strokeWidth='0.3px'
        d='M104.472,255.113h8.1c.024,0,.047,0,.07,0h.763v-1.666h-9.8v1.666h.8C104.425,255.112,104.449,255.113,104.472,255.113Z'
        transform='translate(-99.393 -219.776)'
      />
    </g>
  </SvgIcon>
);

export const IconClose = ({ className, color = '#131F6B' }) => (
  <SvgIcon width='19' height='14' viewBox='0 0 13 13' className={className}>
    <g>
      <g>
        <g>
          <path
            fill={color}
            d='M7.7,6.5l5-5c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.3-1.2,0l-5,5l-5-5c-0.3-0.3-0.9-0.3-1.2,0
				c-0.3,0.3-0.3,0.9,0,1.2l5,5l-5,5c-0.3,0.3-0.3,0.9,0,1.2C0.4,12.9,0.6,13,0.9,13c0.2,0,0.4-0.1,0.6-0.3l5-5l5,5
				c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3c0.3-0.3,0.3-0.9,0-1.2L7.7,6.5z'
          />
        </g>
      </g>
    </g>
    ;
  </SvgIcon>
);
