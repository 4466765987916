import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { IconClose } from 'shared/components/Icons';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import ButtonLoading from 'shared/components/ButtonLoading';

const LicenseManageModal = ({ data, onRefreshData, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [newKey, setNewKey] = useState('');
  const [fetch, setFetch] = useState(false);
  const [remove, setRemove] = useState(false);

  const onRemoveKey = async id => {
    try {
      setRemove(id);
      const { data } = await Api.post(`/auth/removelicense/${id}`);
      if (onRefreshData) await onRefreshData();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemove(false);
    }
  };

  const onAddNewKay = async () => {
    try {
      setFetch(true);
      await Api.post('/auth/addlicense', { licenseKeys: [newKey] });
      if (onRefreshData) await onRefreshData();
      setNewKey('');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  return (
    <>
      <div className='modal-box deactivate-modal d-flex flex-column'>
        <div className='align-items-center d-flex header justify-content-between'>
          <div>Manage License Keys</div>
          <div onClick={onClose} tabIndex='-1' role='button' className='pointer'>
            <IconClose />
          </div>
        </div>

        <div className='content flex-fill overflow-auto'>
          <p className='mt-3 mb-2 weight-500 fz-16'>License Keys:</p>
          <div className='subs-plan-area'>
            {data && data.length ? (
              data.map(item => {
                return (
                  <div
                    className='plan-box align-items-center d-flex justify-content-between'
                    key={item.id}
                  >
                    <div className='plan-name'>{item.licenseKey}</div>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex'>
                        <button
                          className={`btn text-primary`}
                          onClick={onRemoveKey.bind(null, item.id)}
                          disabled={remove}
                        >
                          {remove === item.id && (
                            <div className='spinner-border spinner-grow-sm mr-2' />
                          )}
                          <span className='mr-1'>REMOVE</span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='plan-box'>No Active Keys</div>
            )}
          </div>
          <p className='mt-3 mb-2 weight-500 fz-16'>New License Key</p>
          <div className='subs-plan-area no-min-h'>
            <InputGroup
              type='text'
              name='newKay'
              placeholder='Enter Key'
              value={newKey}
              disabled={fetch}
              onChange={({ target }) => setNewKey(target.value)}
            />
          </div>
          <div className='text-right'>
            <button
              className='btn px-5 py-2 btn-outline-blue'
              onClick={onAddNewKay}
              disabled={fetch || !newKey}
            >
              {fetch ? <ButtonLoading /> : 'Add Key'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseManageModal;
