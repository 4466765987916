import Main from 'app/Main';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import EventLanding from './routes/EventLanding';
import EventLogin from './routes/EventLogin';
import EventRegister from './routes/EventRegister';

const Events = ({ match }) => {
  const isAuth = useSelector(state => state.isAuthenticated);
  const { path } = match;

  return (
    <div className='events'>
      <Switch>
        {!isAuth && <Route path={`${path}/:id/login`} component={EventLogin} />}
        {!isAuth && <Route path={`${path}/:id/register`} component={EventRegister} />}
        {isAuth && <Route path={`${path}/:id/dashboard`} component={Main} />}
        <Route path={`${path}/:id?`} component={EventLanding} />
        <Redirect from='*' to={`${path}/`} />
      </Switch>
    </div>
  );
};

export default Events;
