import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import { getError } from 'utils/appHelpers';

const Input = ({ forId, type, name, label, value, onChange, className, checked, disabled }) => {
  const inputRef = useRef();
  const active = inputRef.current && inputRef.current.checked;

  return (
    <div
      className={`${type === 'checkbox' ? 'input-checkbox' : ''} ${
        active ? 'active' : ''
      } ${className}`}
    >
      <input
        className='input-custom'
        id={forId}
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={type !== 'checkbox'}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={forId} className='mb-0'>
        {label}
      </label>
    </div>
  );
};

const QuestionaryModal = ({
  episodes,
  lessonId,
  changeStep,
  completedSteps,
  step,
  caseId,
  isOpened,
  updateLessonsEpisode,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [initialForm, setInitialForm] = useState(null);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [result, setResult] = useState({});
  const isDisabled = completedSteps.length < step.disabled_no_active;

  const handleSubmit = async e => {
    e.preventDefault();
    const answers = [];
    // eslint-disable-next-line no-unused-vars
    for (let key in result) {
      answers.push({
        questionaryId: key,
        answer: result[key],
      });
    }
    const body = { lessonId, completed: 1, step: 10, answers, caseId };
    await Api.post('/courses/updateuserlesson', body);
    await updateLessonsEpisode(9);
    completedSteps.push(9);
    changeStep(10);
  };

  const handleChange = (id, value, type, index) => {
    let modifiedValue = '';
    if (type === 'checkbox') {
      let values = result[id].split(',');
      values[index] = value;
      modifiedValue = values.join(',');
    }
    const res = { ...result, [id]: type === 'checkbox' ? modifiedValue : value };
    setResult(res);
  };

  const getQuestionary = async () => {
    let body = episodes.filter(item => item.caseId === caseId);
    body = body.sort((a, b) => a.orderNum - b.orderNum);
    if (!body[9]) return;
    try {
      const { id } = body[9];
      const { data } = await Api.get(`/questionary/getepisodequestionaries/${id}`);
      setInitialForm(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const fillUserAnswers = group => {
    if (group.userAnswer) {
      if (!alreadyAnswered) setAlreadyAnswered(true);
      return group.userAnswer;
    }
    if (group.type === 'checkbox') {
      return Array(group.options.length)
        .fill(false)
        .join(',');
    }
    return null;
  };

  const initializeResults = () => {
    if (!initialForm) return;
    const result = {};
    initialForm.forEach(group => {
      result[group.id] = fillUserAnswers(group);
      group.options.forEach(subGroup => {
        if (subGroup.subQuestions) {
          subGroup.subQuestions.forEach(subGroupOption => {
            result[subGroupOption.id] = fillUserAnswers(subGroupOption);
          });
        }
      });
    });
    setResult(result);
  };

  const isInputChecked = (subG, subGroup, sIndex) => {
    if (subGroup.type === 'checkbox') {
      const arr = result[subGroup.id].split(',');
      return arr[sIndex] === 'true';
    } else {
      return subG.value === result[subGroup.id];
    }
  };

  useEffect(() => {
    if (!isDisabled) getQuestionary();
    //eslint-disable-next-line
  }, []);

  useEffect(initializeResults, [initialForm]);

  if (isOpened) {
    return (
      <div className='p-4 text-center text-white w-100'>
        This step is not required for this case
      </div>
    );
  }

  if (isDisabled) {
    return (
      <div className='p-4 text-center text-white w-100'>
        You must first review all steps of the hanging protocol as indicated below
      </div>
    );
  }

  if (!initialForm) return <Loading classView='justify-content-around w-100' />;

  return (
    <div className='modal-custom modal__fullscreen modal__fluid modal-questionary'>
      <div className='inner-content questionary-content'>
        <h5 className='mb-2'>Score the most suspicious finding</h5>
        <form className='d-flex flex-column flex-fill' onSubmit={handleSubmit} name='questionForm'>
          <div className='flex-fill overflow-auto questions-area'>
            {initialForm.length > 0 &&
              initialForm.map((group, gIndex) => {
                // LEVEL 1
                return (
                  <Fragment key={gIndex}>
                    {group.options &&
                      group.options.map((groupOption, gOptIndex) => {
                        const checked = groupOption.value === result[group.id];
                        const isCorrect = group.correct === groupOption.value;
                        // ITEM ON LEVEL 1
                        return (
                          <Fragment key={gOptIndex}>
                            <Input
                              id={group.key}
                              forId={groupOption.key}
                              type={group.type}
                              name={group.id}
                              value={groupOption.value}
                              checked={checked}
                              disabled={alreadyAnswered}
                              onChange={e =>
                                handleChange(
                                  group.id,
                                  group.type === 'radio' ? e.target.value : e.target.checked,
                                  group.type,
                                  gOptIndex,
                                )
                              }
                              label={groupOption.key}
                              className={`${isCorrect && alreadyAnswered ? 'a-correct' : ''} ${
                                checked && !isCorrect && alreadyAnswered ? 'a-incorrect' : ''
                              } question-parent d-flex align-items-center`}
                            />
                            {groupOption.subQuestions &&
                              groupOption.subQuestions.length > 0 &&
                              result[group.id] === groupOption.value && (
                                <div className='question-group'>
                                  {groupOption.subQuestions.map((subGroup, sGroupIndex) => {
                                    // LEVEL 2
                                    return (
                                      <div
                                        className={`sub-question-group sub-question-group__${
                                          subGroup.type
                                        }`}
                                        key={sGroupIndex}
                                      >
                                        {subGroup.options &&
                                          subGroup.options.map((subG, sIndex) => {
                                            let isCorrect = subGroup.correct === subG.value;
                                            let checked = isInputChecked(subG, subGroup, sIndex);
                                            const isCheckBox = subGroup.type === 'checkbox';
                                            if (isCheckBox) {
                                              isCorrect =
                                                subGroup.correct &&
                                                subGroup.correct.split(',')[sIndex] === 'true';
                                            }
                                            // ITEM ON LEVEL 2
                                            return (
                                              <Fragment key={sIndex}>
                                                <Input
                                                  id={subGroup.id}
                                                  label={subG.key}
                                                  forId={subG.key}
                                                  type={subGroup.type}
                                                  name={subGroup.id}
                                                  value={subG.value}
                                                  checked={isInputChecked(subG, subGroup, sIndex)}
                                                  disabled={alreadyAnswered}
                                                  onChange={e =>
                                                    handleChange(
                                                      subGroup.id,
                                                      subGroup.type === 'radio'
                                                        ? e.target.value
                                                        : e.target.checked,
                                                      subGroup.type,
                                                      sIndex,
                                                    )
                                                  }
                                                  className={`${
                                                    isCorrect && alreadyAnswered
                                                      ? `a-correct${isCheckBox ? '-check' : ''}`
                                                      : ''
                                                  } ${
                                                    checked && !isCorrect && alreadyAnswered
                                                      ? `a-incorrect${isCheckBox ? '-check' : ''}`
                                                      : ''
                                                  } question-child`}
                                                />
                                              </Fragment>
                                            );
                                          })}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </Fragment>
                        );
                      })}
                  </Fragment>
                );
              })}
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            <button
              disabled={alreadyAnswered}
              type='submit'
              className='btn btn-blue-dark btn-rounded btn-outlined-white btn-width-300 mt-3'
            >
              Submit Assessment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuestionaryModal;
