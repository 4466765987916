import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserImage, Settings, Status, SubscriptionStatus, PasswordManage } from './components';
import LinkIcon from 'shared/components/LinkIcon';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { getAndUpdateProfile } from 'app/Auth/actions';
import { hasAccess } from 'utils/permissionHelper';
// import accessImg from 'assets/events/accesslogo_dark.svg';
import EntityInfoBlock from './components/EntityInfoBlock';
import LicensesBlock from './components/LicensesBlock';
import CopyRightInfo from 'shared/components/CopyRightInfo';

const Profile = ({ account, getAndUpdateProfile }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { imageUrl, subscriptions, email, connections } = account;
  // const [changeCode, setChangeCode] = useState(false);
  // const [code, setCode] = useState((learnerProfile && learnerProfile.registrationKey) || '');
  const [licenses, setLicenses] = useState([]);
  const certs = account.userCertificates || [];

  // const onChangeClick = async () => {
  //   try {
  //     const { data } = await Api.post('auth/updatekey', { uniqueKey: code });
  //     await getAndUpdateProfile();
  //     enqueueSnackbar(data.message, { variant: 'success' });
  //   } catch (err) {
  //     enqueueSnackbar(getError(err), { variant: 'error' });
  //   } finally {
  //     setChangeCode(false);
  //   }
  // };

  const getLicenses = async () => {
    try {
      const { data } = await Api.get('/auth/getlicenses');
      setLicenses(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  // const onClickAccessVip = () => {
  //   const isAlreadyVIP = account.userType !== 6;
  //   let win;
  //   if (isAlreadyVIP) {
  //     win = window.open('/allcourses', '_blank');
  //   } else {
  //     win = window.open('/register', '_blank');
  //   }
  //   win.focus();
  // };

  const isEventUser = hasAccess('event_user');

  useEffect(() => {
    getLicenses();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className='page-title'>
        <h2>My Profile</h2>
      </div>
      <div className='p-3 d-flex'>
        <div className='col-9 row d-block'>
          <div className='mb-5 d-flex flex-1 mt-1 align-items-center'>
            <div className='mr-4'>
              <UserImage url={imageUrl} userId={account.id} />
              {/* {isEventUser && (
                <button className='btn btn-block mt-5' onClick={onClickAccessVip}>
                  <img src={accessImg} height='60' alt='logo' />
                </button>
              )} */}
            </div>
            <Status connections={connections} />
          </div>
          {account.entity && <EntityInfoBlock entity={account.entity} />}
          <div className='mb-5'>
            <Settings data={account} isEventUser={isEventUser} />
          </div>
          {!!subscriptions.length && !isEventUser && (
            <div className='mb-5'>
              <SubscriptionStatus subscriptions={subscriptions} />
            </div>
          )}
          {/* {!isEventUser && (
            <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
              <div className='d-flex flex-fill align-items-center'>
                <div className='col-4 mr-3 p-0 weight-900'>Registration Code:</div>
                <div className='col'>
                  {changeCode ? (
                    <input
                      type='text'
                      placeholder='Registration Code'
                      value={code}
                      onChange={({ target }) => setCode(target.value)}
                    />
                  ) : learnerProfile ? (
                    learnerProfile.registrationKey || '-'
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              {changeCode && (
                <button className='btn btn-white' onClick={onChangeClick}>
                  Save Changes
                </button>
              )}
              <button className='btn btn-white ml-2' onClick={() => setChangeCode(i => !i)}>
                {changeCode ? 'Close' : 'Change'}
              </button>
            </div>
          )} */}
          {!isEventUser && (
            <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
              <div className='d-flex flex-fill'>
                <div className='col-4 mr-3 p-0 weight-900'>Certificates:</div>
                <div className='col'>{certs.length}</div>
              </div>
              {!!certs.length && (
                <button className='btn btn-white'>
                  <LinkIcon className='text-body px-1' to={`/profile/certificate`}>
                    See
                  </LinkIcon>
                </button>
              )}
            </div>
          )}
          <div className='mb-3'>
            <PasswordManage email={email} />
          </div>
          {!isEventUser && (
            <div className='mb-5'>
              <LicensesBlock data={licenses} getLicenses={getLicenses} />
            </div>
          )}
        </div>
      </div>
      <CopyRightInfo />
    </div>
  );
};

const mapStateToProps = ({ account }) => ({ account });

export default connect(
  mapStateToProps,
  { getAndUpdateProfile },
)(Profile);
