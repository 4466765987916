import React from 'react';
import { Link } from 'react-router-dom';
import { IconArrowRight } from 'shared/components/Icons';
import { getEventUrl, getFeedbackUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

const CourseHeaderRightNav = ({ course }) => {
  const isEventUser = hasAccess('event_user');
  const isKolUser = hasAccess('kol_user');
  const path = isEventUser ? getEventUrl() : '';

  return (
    <div className={`course-links d-flex align-items-center ${isKolUser ? 'has-m' : ''}`}>
      {!isEventUser && (
        <Link
          to={`${path}/courses/progress/${course.id}`}
          className='d-flex align-items-center mr-2 link light'
        >
          Progress <IconArrowRight className='ml-1' />
        </Link>
      )}
      {getFeedbackUrl(course) && (
        <Link to={getFeedbackUrl(course)} className='d-flex align-items-center mr-2 link light'>
          Evaluation <IconArrowRight className='ml-1' />
        </Link>
      )}
      {!isEventUser && (
        <Link
          to={`${path}/courses/certificate/${course.id}`}
          className='d-flex align-items-center mr-2 link light'
        >
          Certificate <IconArrowRight className='ml-1' />
        </Link>
      )}
    </div>
  );
};
export default CourseHeaderRightNav;
