import React from 'react';
import { getEventUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { IconArrowLeft } from './components/Icons';

const BackButton = ({ history, route, noBackRoute, className }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';

  const handleBackClick = () => {
    if (route) {
      history.push(`${path}${route}`);
      return;
    }
    if (!history.goBack && noBackRoute) history.push(`${path}${noBackRoute}`);
    else history.goBack();
  };

  return (
    <div
      className={`back-button pointer ${className || ''}`}
      onClick={handleBackClick}
      role='button'
      tabIndex='-1'
    >
      <IconArrowLeft />
    </div>
  );
};

export default BackButton;
