import React, { memo } from 'react';

const InputGroup = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  disabled,
  required,
  icon,
  className,
  hasError,
}) => (
  <div
    className={`input-group ${className || ''} ${hasError ? 'has-error' : ''} ${
      !icon ? 'no-icon' : ''
    }`}
  >
    {icon && <div className='icon-area'>{icon}</div>}
    <input
      type={type}
      value={value}
      id={name}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      aria-describedby=''
      placeholder={placeholder}
      autoComplete='off'
      disabled={disabled}
      required={required}
      className='flex-fill'
    />
  </div>
);

export default memo(InputGroup);
