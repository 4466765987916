import React, { useEffect, useState, memo } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setDiagnosticData } from '../../actions';
import SpinnerLoading from './../SpinnerLoading';
import Feedback from './../Feedback';
import ViewResult from './ViewResult';

const threshold = 3; // This is the minimal threshold to count MIN, MAX and AVG
const repeatLimit = 2;
const limit = threshold + repeatLimit;

// Latency
const settings = {
  optimal: 5000,
  minimal: 8000,
  timeout: 35000,
};

const requestUrl = '/common/test-latency';

const LatencyTest = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [aborted, setAborted] = useState(false);

  const checkNetworkConnection = () => {
    if (result) return;
    const promises = [];

    for (let i = 1; i <= limit; i++) {
      promises.push(
        new Promise((resolve, reject) => {
          try {
            const start = performance.now();
            Api.get(requestUrl, { timeout: settings.timeout })
              .then(res => {
                const millis = performance.now() - start;
                const result = millis % 60000;
                resolve(Math.round(result));
              })
              .catch(reject);
          } catch (err) {
            reject(err);
          }
        }),
      );
    }

    Promise.all([...promises])
      .then(results => {
        const sorted = results.sort((a, b) => a - b);
        const avg = Math.round(sorted.reduce((a, b) => a + b) / sorted.length);
        const min = sorted[0];
        const max = sorted[sorted.length - 1];
        const success = avg <= settings.optimal;
        const result = { avg, min, max, success };
        dispatch(setDiagnosticData({ latency: result }));
        setResult(result);
      })
      .catch(err => {
        // if (err.code === 'ECONNABORTED') {
        //   dispatch(setDiagnosticData({ failed: true }));
        //   setAborted(true);
        // }
        dispatch(setDiagnosticData({ failed: true }));
        setAborted(true);
        enqueueSnackbar(getError(err), { variant: 'error' });
      });
  };

  useEffect(checkNetworkConnection, [result]);

  return (
    <>
      <div className='box'>
        <p className='m-0'>
          <span className='weight-600 mr-2'>Latency:</span>
          <span className='weight-100'>
            {aborted ? 'No Response.' : result ? <ViewResult data={result} /> : <SpinnerLoading />}
          </span>
        </p>
      </div>
      <Feedback
        data={result}
        optimalSettings={settings.optimal}
        minimalSettings={settings.minimal}
        resultKey='avg'
        successText='The latency is in normal range.'
        failText={
          <>
            The latency is too high for optimal viewing performance.
            <br />
            Hint: Try other internet connection.
          </>
        }
        aborted={aborted}
      />
    </>
  );
};

export default memo(LatencyTest);
