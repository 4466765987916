import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import ProgressHeader from './ProgressHeader';
import ProgressIncorectBar from 'shared/components/ProgressIncorectBar';
import { getCourses } from './../Courses/actions/index';

const CourseProgress = ({ match, history, getCourses }) => {
  const courses = useSelector(state => state.courses);
  const course = courses.find(item => item.id === Number(match.params.id));

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  if (course.isOpened) {
    return (
      <div>
        <ProgressHeader history={history} course={course} />
        <div className='text-center py-5 certificate-page'>
          No Progress is available for this course
        </div>
      </div>
    );
  }

  return (
    <>
      <ProgressHeader history={history} course={course} />
      <div className='flex-1 p-3 course-progress'>
        <ul className='pl-0 col-12 pt-3'>
          {course.lessons &&
            course.lessons.map(lesson => {
              const { id, name, startDate, endDate, progress, correctProgress } = lesson;
              const isCompleted = progress && progress >= 100;

              return (
                <li className='d-flex row align-items-end mb-4 text-size-15' key={id}>
                  <div
                    className={`col-4 weight-600 text-right ${
                      progress && progress < 100 ? 'color-blue' : ''
                    }`}
                  >
                    {name}
                  </div>
                  <div className='col-4'>
                    <div className='d-flex justify-content-between'>
                      <span className='text-date'>
                        {startDate ? `Start ${startDate}` : 'Not Started'}
                      </span>
                      {!!isCompleted && (
                        <span className='text-date'>{endDate && `End ${endDate}`}</span>
                      )}
                    </div>
                    <ProgressIncorectBar
                      className='rounded'
                      progress={progress}
                      correctProgress={correctProgress}
                    />
                  </div>
                  <div
                    className={`col-3 weight-600 ${
                      progress && correctProgress !== progress ? 'color-blue' : ''
                    }`}
                  >
                    {progress}%
                  </div>
                </li>
              );
            })}
        </ul>
        <div className='progress-circles d-flex flex-column pl-0 col-12 pt-3'>
          <div className='d-flex'>
            <div className='col-4' />
            <div className='d-flex col-4'>
              <div className='col-6 pl-0 d-flex align-items-center'>
                <span className='correct' />
                Correct Answers
              </div>
              <div className='col-6 pr-0 d-flex align-items-center'>
                <span className='progress' />
                Lesson In Progress
              </div>
            </div>
            <div className='col-3' />
          </div>
          <div className='d-flex'>
            <div className='col-4' />
            <div className='d-flex col-4'>
              <div className='col-6 pl-0 d-flex align-items-center'>
                <span className='incorrect' />
                Incorrect Answers
              </div>
              <div className='col-6 pr-0 d-flex align-items-center'>
                <span className='completed' />
                Completed Lesson
              </div>
            </div>
            <div className='col-3' />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  null,
  { getCourses },
)(CourseProgress);
