import React from 'react';

const ViewResult = ({ data }) => {
  const { received, duration } = data;
  return (
    <span>
      received <b>{received}</b> MB in <b>{duration}</b>s
    </span>
  );
};

export default ViewResult;
