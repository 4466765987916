import React, { useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import InputGroup from 'shared/components/InputGroup';
import { useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import { IconKey, IconRight } from 'shared/components/Icons';
import { useSnackbar } from 'notistack';
import { findCourseFirstLesson, getError } from 'utils/appHelpers';
import keyImage from 'assets/keyimage.png';
import { getCourses } from 'app/Main/routes/Courses/actions';
import { useHistory } from 'react-router';

const SubscriptionModal = ({ data, onModalClose, onSuccess, getCourses }) => {
  const userId = useSelector(item => item.account.id);
  const { enqueueSnackbar } = useSnackbar();
  const [key, setKey] = useState('');
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const keyReq = data && data.type === 3;

  const subscribe = async () => {
    setFetching(true);
    try {
      await Api.post('/subscription/subscribe', {
        subscriptionId: data.id,
        uniqueKey: key,
        userId,
      });
      await getCourses();
      if (onSuccess) onSuccess();
      enqueueSnackbar(
        keyReq
          ? 'Subscription requested. Pending Activation.'
          : 'You are now enrolled and the course has been added to your transcript.',
        { variant: 'success' },
      );
      onModalClose();
      if (!keyReq && data.courses && data.courses[0]) {
        const course = data.courses[0];
        course.status = 1;
        findCourseFirstLesson(course, history);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  if (!data) return null;

  return (
    <Modal
      className='modal-default subscription-modal flex-column d-flex'
      isOpen={true}
      onRequestClose={onModalClose}
      contentLabel='Connection and device test'
    >
      <button className='button-times' onClick={onModalClose} />
      <img className='subscription-image' src={data.image} alt={data.name} />
      <h5 className='my-3'>{data.name}</h5>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Duration</span>
        <span>{data.time} hours</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Parts</span>
        <span>{data.totalCourses} parts</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Course Duration</span>
        <span>{data.days} days</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Faculty</span>
        <span>{data.totalAuthors || data.authors.length} Faculty</span>
      </div>
      {keyReq && (
        <div className='password-input d-flex mt-3'>
          <div className='pr-3 pl-2 pass-icon mb-2'>
            <IconKey />
          </div>
          <InputGroup
            className='pl-3'
            type='text'
            name='key'
            value={key}
            placeholder='Enter Key'
            onChange={e => setKey(e.target.value)}
          />
        </div>
      )}
      <div
        className={`align-items-center d-flex pt-3 ${
          keyReq ? 'justify-content-between' : 'justify-content-end mt-3 border-top'
        }`}
      >
        {keyReq && (
          <div className='find-key-button small pointer'>
            <div className='key-image'>
              <img src={keyImage} alt='keyimage' />
            </div>
            Find the Key
          </div>
        )}
        <button
          className='btn subscribe-btn p-0'
          onClick={subscribe}
          disabled={(keyReq && !key) || fetching}
        >
          Enroll <IconRight color='#131f6b' className='ml-1' />
        </button>
      </div>
    </Modal>
  );
};

export default connect(
  null,
  { getCourses },
)(SubscriptionModal);
