import { combineReducers } from 'redux';
import { isAuthReducer, accountReducer } from 'app/Auth/actions';
// import { statisticsReducer } from 'app/Main/routes/Home/actions';
import { authorsReducer } from 'app/Main/routes/Authors/actions';
import { coursesReducer } from 'app/Main/routes/Courses/actions';
import {
  diagnosticTestReducer,
  diagnosticDataReducer,
} from 'app/Main/components/ConnectionAndDeviceTestModal/actions';
import { viewerReducer } from 'app/Main/routes/Viewer/actions';
import { eventsReducer } from 'app/Events/actions';

// Load reducers
const rootReducer = combineReducers({
  isAuthenticated: isAuthReducer,
  // statistics: statisticsReducer,
  account: accountReducer,
  authors: authorsReducer,
  courses: coursesReducer,
  connectionAndDeviceTest: diagnosticTestReducer,
  diagnosticData: diagnosticDataReducer,
  viewerOptions: viewerReducer,
  events: eventsReducer,
});

export default rootReducer;
