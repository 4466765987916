import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import EventHeader from './components/EventHeader';
import EventBanner from './components/EventBanner';
// import EventInfo from './components/EventInfo';
import EventAbout from './components/EventAbout';
import EventSpeakers from './components/EventSpeakers';
// import EventPrograms from './components/EventPrograms';
import EventCME from './components/EventCME';
import EventCourses from './components/EventCourses';
import EventFooter from './components/EventFooter';
import { useDispatch, useSelector } from 'react-redux';
import { onGetEvents } from 'app/Events/actions';

const EventLanding = ({ match }) => {
  const dispatch = useDispatch();
  const events = useSelector(state => state.events);
  const { id } = match.params;
  const event = events[id];
  const { enqueueSnackbar } = useSnackbar();

  const getEventData = async id => {
    try {
      const { data } = await Api.get(`/events/event/${id}`);
      const res = await Api.get(`/events/event/courses/${id}`);
      data.data.courses = res.data.data.courses;
      dispatch(onGetEvents({ ...events, [id]: data.data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEventData(id);
    //eslint-disable-next-line
  }, [])

  if (!event) return <Loading classView='mt-5' />;

  return (
    <div className='event-view'>
      <EventHeader eventId={event.id} />
      <EventBanner event={event} />
      {/* <EventInfo event={event} /> */}
      <EventAbout event={event} />
      <EventCourses event={event} />
      <EventCME event={event} />
      <EventSpeakers event={event} />
      {/* <EventPrograms event={event} /> */}
      <EventFooter event={event} />
    </div>
  );
};

export default EventLanding;
