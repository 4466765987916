/* eslint-disable react/jsx-no-target-blank */
import { HOST } from 'configs';
import React, { useState } from 'react';
import { IconCourses } from 'shared/components/Icons';

const data = [
  {
    header: 'Account and Certificates:',
  },
  {
    title: 'Where do I find my certificate?',
    content: (
      <>
        Certificates are emailed out automatically after completion of a course for you to download.
        They can also be access two ways:
        <ul className='mt-2'>
          <li>
            1. <b>My Transcript</b> &gt; Click on this link on the left-hand banner. Your
            certificate(s) will be under <b>Certificates</b>.
          </li>
          <li>
            2. Click on your picture above your name in the left-hand banner. Scroll down to{' '}
            <b>Certificates</b>. <b>Click See</b>.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'How do I print my certificate?',
    content: (
      <>
        Once you have located your certificates in either <b>“My Transcript”</b> or in your profile,
        hover over the certificate icon, the word <b>“Download”</b> will appear. Click on “Download”
        , a new window in your web browser will open with the certificate. From there you can select
        to download and/or save to your computer or to print.
      </>
    ),
  },
  {
    title: 'How do I change my password?',
    content: (
      <>
        Click on your picture above your name. Scroll down to <b>Change Password</b>. Click{' '}
        <b>Change</b>.
      </>
    ),
  },
  {
    title: 'How do I change my user profile i.e.spelling of name, etc?',
    content: (
      <>
        Click on your picture above your name. Scroll down to your information. Click <b>Edit</b>.
        Update your information. Click <b>Save</b>.
      </>
    ),
  },
  {
    header: 'Courses and Modules:',
  },
  {
    title: 'Why am I unable to take certain courses?',
    content: (
      <>
        Courses that have an icon of a <b>“lock”</b> a registration code is required. For U.S.
        Hologic customer needing to register for <b>Essentials of 3D Breast Tomosynthesis Course</b>
        , please reach out to your Account Executive for the code.
      </>
    ),
  },
  {
    title: 'Why am I unable to take certain modules within a course?',
    content: (
      <>
        In order to proceed to the next module, some courses require that you watch each module in
        its entirety. To proceed to the next module, ensure that you have watched your current
        module in its entirety. Also, for courses containing hands-on case review, every view in
        each case must be viewed and scored. Please ensure there is a <b>“checkmark”</b> over each
        view for each cases to be counted as complete.
      </>
    ),
  },
  {
    title: 'Is there a cheat sheet for the DICOM viewer tools?',
    content: (
      <>
        Yes, that can be downloaded from{' '}
        <a download target='_blank' href={HOST.API.CONTENT + '/content/dicom_cheatsheet.pdf'}>
          here
        </a>
        .
      </>
    ),
  },
  {
    title:
      'While in the hands-on case review module, tomosynthesis slices or cine are slow and choppy',
    content: (
      <>
        The tomosynthesis slices have to be fully downloaded before they run smoothly. To initiate
        the buffering if they are slow or choppy, click on the <b>“play”</b> icon on the stop of the
        scroll bar when you are on a tomosynthesis image. Press the <b>“play”</b> icon and the cases
        will buffer for a second to download the slices. Once the buffering has stopped, the
        tomosynthesis image will scroll smoothly.
      </>
    ),
  },
  {
    title: 'Why can I view the videos but cannot access the hands-on case review section?',
    content: (
      <>
        This has to do with the internet network you are using, either a firewall or bandwidth. If
        you are in a hospital setting, the firewalls may be prohibiting the case review. If it is
        not a firewall issue then it may be a bandwidth issue. The hands-on case review section
        needs a lot more bandwidth than the videos to play.
      </>
    ),
  },
  {
    title: 'How do I take a course offline?',
    content: `It is possible to take courses offline using Hologic's ACE iPad app for iOS. Check the app store for this new app which will be available soon!`,
  },
  {
    title: 'How do I download the iPad app?',
    content: `Hologic's ACE iPad app for iOS will be available for download soon! Check back periodically for a download link.`,
  },
  {
    header: 'Specifications and Connection:',
  },
  {
    title: 'What are the ideal specifications for internet browsers?',
    content: (
      <ul>
        <li>
          ● Google Chrome (min version <b>79.0.3945</b>)
        </li>
        <li>
          ● Mozilla Firefox (min version <b>78.0</b>)
        </li>
        <li>
          ● Safari (Mac) (min version <b>13</b>)
        </li>
        <li>
          ● Microsoft Edge (min version <b>79.0.309</b>)
        </li>
        <li>● Internet Explorer is no longer supported</li>
      </ul>
    ),
  },
  {
    title: 'What are the ideal specifications for computer?',
    content: (
      <ul>
        <li>● Memory: 2 GB or more Memory: 2 GB or more</li>
        <li>● Storage: 8 GB or more free space Storage: 8 GB or more free space</li>
        <li>
          ● Monitor/Display: at least 13" monitor with resolution 1600 x 900 or better
          Monitor/Display: at least 13" monitor with resolution 1600 x 900 or better
        </li>
        <li>● Internet: 8 Mb/s or better Internet: 8 Mb/s or better</li>
      </ul>
    ),
  },
  {
    title: 'I failed the Connection Test, can I still access the portal and what does it mean',
    content:
      'If you fail the Connect Test you can still access the portal, just means that some aspects of the portal may not run optimally. Depending on what section failed, you may need to try a different internet network or web browser.',
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = index => setActiveIndex(activeIndex === index ? null : index);
  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconCourses color='#131f6b' />
          </span>
          FAQ
        </div>
      </div>
      <div className='p-3'>
        <div className='accordion faq parts-accordion'>
          {data.map((item, index) => {
            if (item.header)
              return (
                <div className='section-header bg-light p-2 mb-3 rounded' key={index}>
                  <h2 className='font-weight-normal'>{item.header}</h2>
                </div>
              );
            return (
              <ul className='accordion-item' key={index}>
                <li
                  className={`item-header ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => toggleAccordion(index)}
                  role='presentation'
                >
                  <div className='d-flex'>{item.title}</div>
                </li>
                {activeIndex === index && <div className='item-content px-4'>{item.content}</div>}
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
