import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { IconCourseLock, IconPlay } from 'shared/components/Icons';

const SubMenuCourses = ({ courses, location, path }) => {
  const [activeCourse, setActiveCourse] = useState();
  const openCourses = courses.filter(item => item.status === 1);

  const isNavActive = (id, match) => {
    if (!match) return false;
    setActiveCourse(id);
    return true;
  };

  const handleLinkClick = (active, e) => {
    if (!active) e.preventDefault();
  };

  const getFirtsLessonPath = course => {
    if (course.status !== 1) return `${path}/allcourses/details/${course.id}`;
    const firstLesson = course.lessons[0];
    return `${path}/courses/${course.id}/${firstLesson.type}/${firstLesson.id}`;
  };

  useEffect(() => {
    const id = location.pathname.split('/')[2];
    setActiveCourse(Number(id));
  }, [location.pathname]);

  return (
    <ul className='sub-menu courses mt-2'>
      {openCourses &&
        openCourses.map((item, index) => {
          const coursePath = `${path}/courses/${item.id}`;
          const isActive = item.id === activeCourse && item.status === 1;
          const Icon = item.status === 1 ? IconPlay : IconCourseLock;
          return (
            <li key={index}>
              <NavLink
                className={item.status === 1 ? '' : 'not-allowed'}
                to={getFirtsLessonPath(item)}
                isActive={isNavActive.bind(null, item.id)}
              >
                <Icon className='d-inline align-baseline mr-1' /> {item.name}
              </NavLink>
              {isActive && (
                <ul>
                  {item.lessons &&
                    item.lessons.map((lesson, index) => {
                      return (
                        <li key={lesson.id + index}>
                          <NavLink
                            className={lesson.status === 1 ? '' : 'not-allowed'}
                            to={`${coursePath}/${lesson.type}/${lesson.id}`}
                            activeClassName='active'
                            onClick={handleLinkClick.bind(null, lesson.status === 1)}
                          >
                            {lesson.name}
                          </NavLink>
                        </li>
                      );
                    })}
                </ul>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default withRouter(SubMenuCourses);
