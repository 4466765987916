import React, { memo } from 'react';
// import LinkIcon from './LinkIcon';
import { getImageUrl } from 'utils/appHelpers';
import { withRouter } from 'react-router-dom';

const AuthorBox = ({ author, isShort, bordered, className, onSeeMoreClick, active }) => {
  // const authorClickHandle = author => {
  //   history.push(`/authors/${author.id}`);
  // };

  const isDisabled = author.description.length < 150;

  return (
    <div
      // onClick={authorClickHandle.bind(null, author)}
      role='button'
      tabIndex='-1'
      key={author.id}
      className={`author-item d-flex flex-column text-center align-items-center ${className}`}
    >
      <div className={`${bordered ? 'image-bordered' : ''}`}>
        <img src={getImageUrl(author.image)} alt={author.name} />
      </div>
      <span>
        {author.name} {author.surname}
      </span>
      <p className='mb-1'>{author.profession}</p>
      {!isShort && (
        <>
          <p className={`mb-2 author-bio-short ${active ? 'active' : ''}`}>{author.description}</p>
          <button className='btn btn-sm' disabled={isDisabled} onClick={onSeeMoreClick}>
            {active ? 'See Less' : 'See more'}
          </button>
          {/* <LinkIcon to={`authors/${author.id}`}>See more</LinkIcon> */}
        </>
      )}
    </div>
  );
};

export default memo(withRouter(AuthorBox));
