import React from 'react';
import { IconVideos, IconCirclePlay, IconLock } from 'shared/components/Icons';
import ProgressBar from 'shared/components/ProgressBar';
import { getEndOfSubs, bindDurationMinutes } from 'utils/appHelpers';

const CourseItem = ({ item, courseClick, hideProgress, className, hidePlay }) => {
  const Icon = item.status === 1 ? IconCirclePlay : IconLock;
  const hasProgress =
    !item.isOpened && !hideProgress && item.status === 1 && item.progress !== null;
  return (
    <div
      className={`course-item d-flex flex-column mr-1 ${className || ''} ${
        item.status === 1 ? 'active' : ''
      }`}
      role='button'
      tabIndex='-1'
      onClick={courseClick}
      key={item.id}
    >
      <div className='image-area'>
        <img src={item.image} alt={item.name} />
        <div className='info d-flex justify-content-between align-items-end'>
          {!hidePlay && (
            <div className='course-icon d-flex justify-content-center align-items-center'>
              <Icon />
            </div>
          )}
          {hasProgress && (
            <div className='d-flex justify-content-between align-items-center w-100 course-thumb-info p-1'>
              <div>
                <span>
                  {bindDurationMinutes(item.watchedVideoDuration)} /{' '}
                  {bindDurationMinutes(item.totalVideoDuration)}
                </span>
              </div>
              <div>
                <span className='d-flex align-items-center'>
                  {item.completedCases} / {item.totalCases} <IconVideos className='ml-1' />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex flex-1 flex-column course-item-footer'>
        <p className='name text-center mt-2 mb-1'>{item.name}</p>
        <p className='description text-center m-0'>{item.description}</p>
        {hasProgress && (
          <>
            <p className='align-items-center d-flex m-0 mt-2'>
              <span className='mr-2 small'>{item.progress}%</span>
              <ProgressBar progress={item.progress || 0} />
            </p>
            <p className='m-0 text-center small text-secondary'>
              {getEndOfSubs(item.expire, item.expirable)}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CourseItem;
