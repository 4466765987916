import React from 'react';
import ProgressBar from 'shared/components/ProgressBar';
import { IconRight, IconKey, IconLock } from 'shared/components/Icons';
import { Link } from 'react-router-dom';
import { courseSubsStatuses } from 'utils/appHelpers';

const subsTypes = {
  1: 'Opened',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required{' '}
    </>
  ),
};

const SubscriptionItem = ({ item, className, isSubscribed, onSubscribe, isDisabled }) => {
  const userCourse = item.userSubs;
  // const course = item.courses && item.courses[0];
  const isPending =
    item.userSubs && courseSubsStatuses(item.userSubs.userSubscriptionStatus, 'pending');

  return (
    <div className={`subscription-item d-flex justify-content-between ${className || ''}`}>
      <div className='d-flex align-items-center'>
        <div className='subs-item-image'>
          {isDisabled && (
            <div className='lock-overlay'>
              <IconLock />
            </div>
          )}
          <img src={item.image} alt='subsription' />
        </div>
        <div className='ml-3'>
          <h4>{item.name}</h4>
          <ul className='d-flex'>
            <li className='subs-type'>{subsTypes[item.type] || '-'}</li>
            <li>{item.time} hours</li>
            <li>{item.totalCourses || 0} Courses</li>
            <li>{item.expirable ? `${item.days} days` : 'No Expiration'}</li>
            <li>
              <Link to={`/subscription/${item.id}`}>More info</Link>
            </li>
          </ul>
        </div>
      </div>
      {isSubscribed && !isPending && (
        <div className='col-4 d-flex flex-column user-subscibe-info pt-3 px-5'>
          <div className='d-flex pb-2'>
            <span className='mr-4'>
              <b className='mr-2'>Start:</b>
              {userCourse.startDate.substr(0, 10)}
            </span>
            {userCourse.progress === 100 && (
              <span>
                <b className='mr-2'>End:</b>
                {userCourse.endDate.substr(0, 10)}
              </span>
            )}
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <b className='mr-5 text-nowrap'>Progress {userCourse.progress || 0}%</b>
            <ProgressBar progress={userCourse.progress || 0} />
          </div>
        </div>
      )}
      {isSubscribed && isPending && (
        <div className='align-items-end d-flex pb-2 pr-4 small text-secondary'>Request Sent</div>
      )}
      {!isSubscribed && (
        <div className='align-items-end d-flex pb-2 pr-4'>
          <button className='btn subscribe-btn' onClick={() => onSubscribe(item)}>
            Subscribe now <IconRight color='#131f6b' className='ml-1' />
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionItem;
