/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { chunckArray } from 'utils/appHelpers';
import { IconArrowRight, IconAuthors } from 'shared/components/Icons';

const DashBoxAuthors = () => {
  const allAuthors = useSelector(state => state.authors);
  const authors = chunckArray(allAuthors, 4);
  const history = useHistory();

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    className: 'home-authors-slide',
    autoplay: true,
  };

  return (
    <div className='h-100'>
      {authors && (
        <Slider {...sliderSettings}>
          {authors.map((item, i) => {
            return (
              <div key={i}>
                <div className='d-flex flex-wrap authors-block'>
                  {item.map(author => {
                    const prof = author.profession ? `, ${author.profession}` : '';
                    const secondRow = `${author.surname}${prof}`;
                    return (
                      <div
                        key={author.id}
                        className='col-6 p-0 d-flex flex-column justify-content-center'
                      >
                        <div className='img-block'>
                          <img src={author.image} alt='author' />
                        </div>
                        <p>
                          {author.name}
                          <br />
                          {secondRow}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
      )}
      <div className='overlay-block d-flex flex-column justify-content-between'>
        <div className='title-anim text-right'>
          <span>Faculty</span>
          <IconAuthors color='#fff' />
        </div>
        <div>
          <a
            href='/'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              history.push('/authors');
            }}
          >
            Show More
            <IconArrowRight color='#fff' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DashBoxAuthors;
