import React, { useRef } from 'react';
import { openFullscreen, closeFullscreen } from 'utils/appHelpers';

import iconMax from 'assets/arrows/2p.svg';
import iconMin from 'assets/arrows/4a.svg';
import BackButton from 'shared/BackButton';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { saveToStore, getFromStore } from 'utils/storeHelpers';

const ControlArea = ({
  tools,
  course,
  fullScreen,
  activeTool,
  setActiveTool,
  history,
  viewerOptions,
  disabled,
}) => {
  const toolClickHandle = item => {
    const state =
      item.type === activeTool.type && !item.onTimeAction ? { type: 'none' } : { ...item };
    setActiveTool(state);
  };

  const tooltipRef = useRef();

  useOutsideClick(tooltipRef, () => tooltipRef.current.classList.remove('hover'));

  return (
    <div className='control-area px-2 justify-content-center d-flex flex-column'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          {!fullScreen && history && <BackButton history={history} className='viewer-back' />}
          <button
            ref={tooltipRef}
            className={`btn p-0 app-tooltip app-tooltip__dark app-tooltip__bottom app-tooltip__pinned-left ${
              getFromStore('viewerToolTip') ? '' : 'hover'
            }`}
            onClick={fullScreen ? closeFullscreen : openFullscreen}
            onMouseLeave={() => {
              tooltipRef.current.classList.remove('hover');
              saveToStore('viewerToolTip', true);
            }}
          >
            <img width='25' src={fullScreen ? iconMin : iconMax} alt='fullscreen' />
            <span
              className='tooltip-content'
              role='button'
              tabIndex='-1'
              onClick={e => e.stopPropagation()}
            >
              <span className='weight-600'>{fullScreen ? 'Normal ' : 'Full '}Screen</span>
              <hr className='my-1 border-light' />
              Click this button to
              {fullScreen ? ' turn off the full screen' : ' display the viewer in full screen'}
            </span>
          </button>
          {course && <p className='ml-2 text-white m-0 opc-6'>{course.title}</p>}
        </div>
        <div className='d-flex align-items-center'>
          <div className='view-tools'>
            <div className='d-flex align-items-center'>
              {tools.map(item => {
                const isSeparator = item.type === 'separator';
                const canActive = viewerOptions && viewerOptions.canBeActive[item.type];
                const isActive = (activeTool.type === item.type && !item.onTimeAction) || canActive;
                if (isSeparator) return <div key={item.type} className='tool-separator' />;
                return (
                  <button
                    key={item.type}
                    title={item.title}
                    type='button'
                    className={`btn text-white btn-sm mr-1 ${isActive ? 'active' : ''}`}
                    onClick={toolClickHandle.bind(null, item)}
                    disabled={disabled}
                  >
                    {item.icon && (
                      <img
                        height={item.height || '28'}
                        src={isActive ? item.iconActive : item.icon}
                        alt={item.name}
                      />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlArea;
