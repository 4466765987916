import React, { memo } from 'react';

const Select = memo(
  ({ name, value, onChange, onBlur, label, items, className, selectClassName, disabled }) => (
    <div className={`form-select ${className} ${value ? 'selected' : 'non-selected'}`}>
      <select
        className={selectClassName}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        id={name}
        autoComplete='off'
        disabled={disabled}
        required
      >
        {label && <option value=''>{label}</option>}
        {items &&
          items.length &&
          items.map(({ code, name, id }, index) => (
            <option key={name + index} value={code || id}>
              {name}
            </option>
          ))}
      </select>
    </div>
  ),
);

export default Select;
