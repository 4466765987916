import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderSection from './components/HeaderSection';
import InfoSection from './components/InfoSection';
import LessonsListSection from './components/LessonsListSection';
import TypeVideo from './components/TypeVIdeo';
import TypePDF from './components/TypePDF';
import TypeCases from './components/TypeCases';
import TypeImage from './components/TypeImage';
import moment from 'moment';
import Loading from 'shared/components/Loading';
import CourseFeedback from '../CourseFeedback';
import { findCourseFirstLesson, getEndOfSubs } from 'utils/appHelpers';
import ProgressBar from 'shared/components/ProgressBar';
import ExpiredView from './components/ExpiredView';
import TagsViewBlock from 'shared/components/TagsViewBlock';

const viewsComponents = {
  video: TypeVideo,
  pdf: TypePDF,
  cases: TypeCases,
  image: TypeImage,
};

const CourseView = props => {
  const { match, courses, history } = props;
  const [lesson, setLesson] = useState();
  const { courseId, type, lessonId, action } = match.params;
  const course = courses.find(item => item.id === Number(courseId));
  const { expirable, expire, isOpened } = course;
  const isExpired = expirable && expire ? moment(expire).diff(new Date()) <= 0 : false;

  useEffect(() => {
    setLesson(course.lessons.find(lesson => lesson.id === Number(lessonId)));
    //eslint-disable-next-line
  }, [match.params]);

  if (!type || !lessonId) {
    findCourseFirstLesson(course, history);
    return null;
  }

  if (isExpired)
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection course={course} history={history} />
        <ExpiredView course={course} />
      </div>
    );

  if (type === 'feedback') {
    return <CourseFeedback course={course} lesson={lesson} {...props} />;
  }

  if (!lesson || lesson.id !== Number(lessonId)) return <Loading />;

  const LessonView = viewsComponents[type];

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <HeaderSection course={course} history={history} />
      <div className='body-section overflow-hidden d-flex'>
        <LessonView
          lesson={lesson}
          course={course}
          params={match.params}
          history={history}
          action={action}
        />
      </div>
      <div className='footer-section d-flex'>
        <div className='col courses-area p-0'>
          <LessonsListSection
            lessons={course.lessons}
            activeLessonn={lesson}
            courseId={courseId}
            history={history}
          />
        </div>
        <div className='col info-area'>
          <InfoSection info={course.description} />
          <div className='course-info-progress d-flex flex-column justify-content-center align-items-center'>
            {!isOpened && (
              <div className='col-6'>
                <ProgressBar progress={course.progress} />
              </div>
            )}
            <p>{getEndOfSubs(course.expire, course.expirable)}</p>
          </div>
          <TagsViewBlock tags={course.courseTags} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ courses }) => ({
  courses,
});

export default connect(
  mapStateToProps,
  {},
)(CourseView);
