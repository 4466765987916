import {
  IconHome,
  IconTranscript,
  IconCoursesNew,
  // IconAuthors,
  // IconStatistics,
  IconAbout,
  IconContacts,
  IconAuthorsNew,
  // IconSubscription,
} from 'shared/components/Icons';

export const navigation = [
  {
    name: 'Home',
    path: 'home',
    icon: IconHome,
    permission: 'vip_user',
  },
  // {
  //   name: 'Subscriptions',
  //   path: 'subscriptions',
  //   icon: IconSubscription,
  //   permission: 'vip_user',
  // },
  {
    name: 'My Transcript',
    path: 'courses',
    icon: IconTranscript,
    permission: 'vip_user',
  },
  {
    name: 'Available Courses',
    path: 'allcourses',
    icon: IconCoursesNew,
  },
  {
    name: 'Faculty',
    path: 'authors',
    icon: IconAuthorsNew,
  },
  // {
  //   name: 'Statistics',
  //   path: 'statistics',
  //   icon: IconStatistics,
  // },
  {
    name: 'About',
    path: 'about',
    icon: IconAbout,
    permission: 'vip_user',
  },
  {
    name: 'About',
    path: 'eventabout',
    icon: IconAbout,
    permission: 'event_user',
  },
  {
    name: 'Support',
    path: 'contacts',
    icon: IconContacts,
    permission: 'vip_user',
    menu: [
      {
        name: 'FAQ',
        path: 'faq',
      },
      {
        name: 'Connection Test',
        path: 'connection',
      },
      {
        name: 'Contact',
        path: 'contacts',
      },
      {
        name: 'All Requests',
        path: 'messages/list',
      },
      {
        name: 'Privacy Policy',
        link: 'https://www.hologic.com/privacy-policy',
      },
      {
        name: 'System Reqs.',
        path: 'requirements',
      },
    ],
  },
];
