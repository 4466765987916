/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useState } from 'react';
import { Api } from 'utils/connectors';
import { useEffect } from 'react';
import Loading from 'shared/components/Loading';
import Slider from 'react-slick';
import moment from 'moment';

const DashBoxEvent = ({ onOpenUrl }) => {
  const [events, setEvents] = useState();

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    className: 'home-events-slide',
    autoplay: true,
  };

  const openEventPage = event => {
    onOpenUrl(`${window.location.origin}/events/${event.id}`);
  };

  const getEvents = async () => {
    try {
      const { data } = await Api.get('/events/events');
      setEvents(data.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  if (!events) return <Loading classView='mt-5' />;

  return (
    <Slider {...sliderSettings}>
      {events.map(event => {
        const isOnGoing =
          moment(event.startDate).diff(new Date()) <= 0 &&
          moment(event.endDate).diff(new Date()) > 0;
        const isExpired = moment(event.endDate).diff(new Date()) <= 0;
        const label = isExpired ? 'Archived' : isOnGoing ? 'Ongoing' : 'Upcoming';
        return (
          <div className='d-flex pointer' key='id' onClick={openEventPage.bind(null, event)}>
            <div className='col-12 p-0 slider-area'>
              <Slider {...sliderSettings}>
                {event.banners.map(item => {
                  return (
                    <div key={item.id}>
                      <img width='618' src={item.bannerUrl} alt='img' />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className='col-4 d-flex flex-column justify-content-between event-info-block'>
              <div className='ib-title'>{event.subject}</div>
              <div>
                <h3>
                  Hologic Webinars <br />
                  <p>{label}</p>
                </h3>
                <p>
                  {moment(event.startDate).format('MMMM DD')} -{' '}
                  {moment(event.endDate).format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default DashBoxEvent;
