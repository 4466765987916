import React, { useState, useRef } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-daterange-picker';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling. (OPTIONAL)
import iconCalendar from 'assets/calendar.svg';
import { IconArrowDown, IconArrowUp } from './Icons';

const DateRange = ({ onChange, value = null }) => {
  const [open, setOpen] = useState(false);
  const content = useRef();

  useOutsideClick(content, () => setOpen(false));

  return (
    <div className={`calendar ${open ? 'is-opened' : ''}`} ref={content}>
      <div
        className='calendar-header d-flex align-items-center justify-content-between'
        onClick={() => setOpen(!open)}
        role='presentation'
      >
        <img src={iconCalendar} alt='Calendar' />
        {value
          ? `${moment(value.start).format('MM/DD/YYYY')} - ${moment(value.end).format(
              'MM/DD/YYYY',
            )}`
          : 'Not Selected'}
        {open ? <IconArrowUp /> : <IconArrowDown />}
      </div>
      {/* {open && (
        <div className='calendar-body'>
          <DateRangePicker
            onSelect={values => {
              onChange(values);
              setOpen(false);
            }}
            value={value}
          />
        </div>
      )} */}
      <div className={`calendar-body ${open ? 'open' : ''}`}>
        <DateRangePicker
          onSelect={values => {
            onChange(values);
            setOpen(false);
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default DateRange;
