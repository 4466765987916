import React from 'react';
import { Link } from 'react-router-dom';
import placeholder from 'assets/case_placeholder.jpg';
import { IconPlayFill } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';
import { getEventUrl } from 'utils/appHelpers';

const TypeCases = ({ lesson, course }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';
  return (
    <div className='case-part text-center'>
      <Link to={`${path}/courses/${course.id}/cases/${lesson.id}/play`} className='play-btn'>
        <IconPlayFill />
      </Link>
      <img height='100%' src={lesson.image || placeholder} alt='placeholder' />
    </div>
  );
};

export default TypeCases;
