import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getAuthors } from './routes/Authors/actions';
import { getCourses } from './routes/Courses/actions';

import SideBar from './components/SideBar/index';
import HeaderNav from './components/HeaderNav/index';
import Home from './routes/Home';
import Profile from './routes/Profile';
import DiagnosticsHistory from './routes/DiagnosticsHistory';
import Courses from './routes/Courses';
import Transcript from './routes/Transcript';
import Subscriptions from './routes/Subscriptions';
import SubscriptionView from './routes/SubscriptionView';
import CourseView from './routes/CourseView';
import Authors from './routes/Authors';
// import AuthorView from './routes/AuthorView';
// import Statistics from './routes/Statistics';
import About from './routes/About';
import Contacts from './routes/Contacts';
import Messages from './routes/Messages';
import PrivacyAndPolicy from './routes/PrivacyAndPolicy';
import Viewer from './routes/Viewer';
import TestViewer from './routes/TestViewer';
import Icons from './routes/Icons';
import Loading from 'shared/components/Loading';
import ConnectionAndDeviceTestModal from './components/ConnectionAndDeviceTestModal';
import CourseProgress from './routes/CourseProgress';
import CourseCertificate from './routes/CourseCertificate';
// import CourseFeedback from './routes/CourseFeedback';
// import CourseSubscriptions from './routes/CourseSubscriptions';
import CourseDetails from './routes/CourseDetails';
import { getAndUpdateProfile } from 'app/Auth/actions';
import UserCertificate from './routes/UserCertificate';
// import FooterNav from 'shared/components/FooterNav';
import ConnectionTest from './routes/ConnectionTest';
import FAQ from './routes/FAQ';
import TestViewerWL from './routes/TestWL';
import Requirements from './routes/Requirements';
import { hasAccess, PRoute } from 'utils/permissionHelper';
import AboutEvent from './routes/AboutEvent';
import { getLocalToken } from 'utils/tokenHelpers';
import { HOST } from 'configs';
import kolIcon from 'assets/kol_icon.svg';
import { getFromStore, removeFromStore } from 'utils/storeHelpers';
import CookiePopup from 'shared/components/CookiePopup';

const Main = ({ getAuthors, getCourses, getAndUpdateProfile, match, history }) => {
  const [testing, setTesting] = useState(false);
  const authors = useSelector(item => item.authors);
  const courses = useSelector(item => item.courses);
  const connectionAndDeviceTest = useSelector(item => item.connectionAndDeviceTest);
  const path = match.path === '/' ? '' : match.path;
  const isViewer = window.location.pathname.includes('/play');
  const isHomePage = window.location.pathname.includes('/home');
  const redirectUrl = getFromStore('redirectUrl');

  const finishTest = () => {
    setTesting(false);
    getAndUpdateProfile();
  };

  const openAdmin = () => {
    const token = getLocalToken();
    window.open(`${HOST.API.ADMIN_DOMAIN}/grant/kol?t=${token}`, '_blank');
  };

  useEffect(() => {
    if (!courses) getCourses();
  }, [courses, getCourses]);

  useEffect(() => {
    if (!authors) getAuthors();
    if ((authors && courses, redirectUrl)) {
      history.push(redirectUrl);
      removeFromStore('redirectUrl');
    }
    //eslint-disable-next-line
  }, [authors, getAuthors, courses]);

  useEffect(() => {
    if (!getFromStore('connectionAndDeviceTested')) setTesting(true);
  }, [connectionAndDeviceTest]);

  if (!authors || !courses) return <Loading classView='min-vh-100' />;

  const redirect = hasAccess('vip_user') ? '/home' : '/allcourses';

  return (
    <div className={`container-fluid p-0 ${isHomePage ? 'home-page' : ''}`}>
      <CookiePopup />
      <div>
        {!isHomePage && (
          <nav className='d-flex flex-column justify-content-between p-0 sidebar'>
            <SideBar path={path} />
          </nav>
        )}
        <main role='main' className='d-flex flex-column p-0 min-vh-100'>
          {isHomePage && <HeaderNav path={path} openAdmin={openAdmin} />}
          {/* <FooterNav history={history} /> */}
          {hasAccess('kol_user') && !isViewer && !isHomePage && (
            <div className={`top-fixed-menu ${isHomePage ? 'on-home' : ''}`}>
              <button className='btn col-switch-btn p-1' onClick={openAdmin}>
                <img src={kolIcon} alt='col' />
              </button>
            </div>
          )}
          <Switch>
            <Route path={`${path}/icons`} component={Icons} />
            <PRoute access='vip_user' path={`${path}/home`} component={Home} />
            <Route exact path={`${path}/profile`} component={Profile} />
            <Route path={`${path}/profile/history`} component={DiagnosticsHistory} />
            <Route path={`${path}/profile/certificate`} component={UserCertificate} />
            {/* <Route path={`${path}/courses/subscriptions/:id`} component={CourseSubscriptions} /> */}
            <Route path={`${path}/allcourses/details/:id`} component={CourseDetails} />
            <Route path={`${path}/courses/progress/:id`} component={CourseProgress} />
            <Route path={`${path}/courses/certificate/:id`} component={CourseCertificate} />
            {/* <Route path={`${path}/courses/feedback/:id`} component={CourseFeedback} /> */}
            <Route path={`${path}/courses/:courseId/:type?/:lessonId?/play`} component={Viewer} />
            <Route
              path={`${path}/courses/:courseId/:type?/:lessonId?/:action?`}
              component={CourseView}
            />
            <Route path={`${path}/courses/:courseId/:type?/:lessonId?`} component={CourseView} />
            <Route path={`${path}/courses`} component={Transcript} />
            <Route path={`${path}/allcourses/:type?`} component={Courses} />
            <Route path={`${path}/subscription/:id`} component={SubscriptionView} />
            <Route path={`${path}/subscriptions/:type`} component={Subscriptions} />
            <Route path={`${path}/subscriptions/`} component={Subscriptions} />
            {/* <Route path={`${path}/authors/:id`} component={AuthorView} /> */}
            <Route path={`${path}/authors`} component={Authors} />
            {/* <Route path={`${path}/statistics`} component={Statistics} /> */}
            <Route path={`${path}/about`} component={About} />
            <Route path={`${path}/eventabout`} component={AboutEvent} />
            <Route path={`${path}/contacts`} component={Contacts} />
            <Route path={`${path}/connection`} component={ConnectionTest} />
            <Route path={`${path}/faq`} component={FAQ} />
            <Route path={`${path}/messages/list`} component={Messages} />
            <Route path={`${path}/policy`} component={PrivacyAndPolicy} />
            <Route path={`${path}/test`} component={TestViewer} />
            <Route path={`${path}/testwl`} component={TestViewerWL} />
            <Route path={`${path}/requirements`} component={Requirements} />
            <Redirect from='*' to={redirect} />
          </Switch>
        </main>
        {testing && <ConnectionAndDeviceTestModal isOpen={true} onFinish={finishTest} />}
      </div>
    </div>
  );
};

export default connect(
  null,
  { getAuthors, getCourses, getAndUpdateProfile },
)(Main);
