import React from 'react';

const ProgressIncorectBar = ({ progress, correctProgress, className = '' }) => {
  const hasInccorect = correctProgress !== null && correctProgress !== undefined;
  const incorrect = progress - (correctProgress || 0);
  const correct = progress - (hasInccorect ? incorrect : 0);
  const isCompleted = progress >= 100;
  const status =
    !isCompleted && !hasInccorect
      ? 'progress'
      : isCompleted && !hasInccorect
      ? 'completed'
      : 'correct';
  return (
    <span className={`plan-progress advanced d-flex ${className}`}>
      <span className={`progress-line ${status}`} style={{ width: `${correct}%` }} />
      {hasInccorect && (
        <span className='progress-line incorrect' style={{ width: `${incorrect + 1}%` }} />
      )}
    </span>
  );
};

export default ProgressIncorectBar;
