import React from 'react';
import logo from 'assets/ACE-logo-soon.svg';
import footer from 'assets/events/footer-image.png';

import soc1 from 'assets/soon/facebook.svg';
import soc2 from 'assets/soon/linkedin.svg';
import soc3 from 'assets/soon/twitter.svg';

const ComingSoon = ({ match }) => {
  return (
    <div className='coming-soon d-flex flex-column min-vh-100 justify-content-between'>
      <div className='header'>
        <img src={logo} height='90' alt='img' />
      </div>
      <div className='content flex-1 d-flex flex-column justify-content-between'>
        <h1>Coming Soon</h1>
        <div>
          <a href='mailto:info@hologic.com'>Info@Hologic.com</a>
          <div>
            <a
              href='https://www.facebook.com/Hologic'
              className='mx-5'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={soc1} alt='social' />
            </a>
            <a
              href='https://www.linkedin.com/company/hologic/'
              className='mx-5'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={soc2} alt='social' />
            </a>
            <a
              href='https://twitter.com/Hologic'
              className='mx-5'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={soc3} alt='social' />
            </a>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div>
          <img height='213' src={footer} alt='footer' />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
