import React from 'react';
import Icon1 from 'assets/home-icon-1.svg';
import { IconArrowRight } from 'shared/components/Icons';

const DashBoxWebinars = ({ onOpenUrl }) => {
  return (
    <div className='d-flex flex-column h-100 justify-content-between'>
      <div className='title-anim text-right'>
        <span>Accredited Webinars</span>
        <img src={Icon1} height='20' alt='icon' />
      </div>
      <div>
        <a
          href='https://hologiced.com/events/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={e => {
            e.preventDefault();
            onOpenUrl('https://hologiced.com/breast-health/education-training', e);
          }}
        >
          Show More
          <IconArrowRight color='#fff' />
        </a>
      </div>
    </div>
  );
};

export default DashBoxWebinars;
