import React from 'react';

const ViewResult = ({ data }) => {
  const { min, max, avg } = data;
  return (
    <span>
      (Average: <b>{avg}</b>ms, Min: <b>{min}</b>ms, Max: <b>{max}</b>ms)
    </span>
  );
};

export default ViewResult;
