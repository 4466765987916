import * as cornerstone from 'cornerstone-core';
import toolStyle from './../stateManagement/toolStyle.js';
import toolColors from './../stateManagement/toolColors.js';
import path from './path.js';

export default function(context, evtDetail, handles, options = {}) {
  const element = evtDetail.element;
  const defaultColor = toolColors.getToolColor();

  context.strokeStyle = options.color || defaultColor;

  const handleKeys = Object.keys(handles);

  for (let i = 0; i < handleKeys.length; i++) {
    const handleKey = handleKeys[i];
    const handle = handles[handleKey];

    if (handle.drawnIndependently === true) {
      continue;
    }

    if (options.drawHandlesIfActive === true && !handle.active) {
      continue;
    }

    const lineWidth = handle.active ? toolStyle.getActiveWidth() : toolStyle.getToolWidth();
    const fillStyle = options.fill;

    path(
      context,
      {
        lineWidth,
        fillStyle,
      },
      context => {
        const handleCanvasCoords = cornerstone.pixelToCanvas(element, handle);
        drawArrow(context, handleCanvasCoords);
      },
    );
  }
}

function drawArrow(c, { x, y }) {
  // c.lineWidth = 1;
  // c.strokeStyle = '#00b5ec';
  // c.fillStyle = '#fff';
  c.setTransform(1, 0, 0, 1, 10, 0);
  c.translate(x - 8, y + 4);
  // c.beginPath();
  c.arrow(30, 20, 0, 0, [0, 2, -10, 2, -10, 5]);
  // c.fill();
}
