import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Api } from 'utils/connectors';
import { getCourses } from 'app/Main/routes/Courses/actions';

const TypeImage = ({ lesson, getCourses, params }) => {
  const updateLesson = async () => {
    const body = { lessonId: Number(params.lessonId), completed: 1 };
    await Api.post('/courses/updateuserlesson', body);
    getCourses();
  };

  useEffect(() => {
    updateLesson();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='video-area flex-1 d-flex justify-content-center'>
      <img src={lesson.image} height='100%' alt='lesson' />
    </div>
  );
};

export default connect(
  null,
  { getCourses },
)(TypeImage);
