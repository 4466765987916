import React from 'react';
import IconSmileGreen from 'assets/smile_green.svg';
import IconSmileYellow from 'assets/smile_yellow.svg';
import IconSmileRed from 'assets/smile_red.svg';

export const SIconResolution = ({ data, className }) => {
  return (
    <img
      className={className}
      src={
        data.resolution.success || data.resolution.window >= '1024x768'
          ? IconSmileGreen
          : data.resolution.window >= '1024x600'
          ? IconSmileYellow
          : IconSmileRed
      }
      alt='Smile Icon'
    />
  );
};

export const SIconLatency = ({ data, className }) => {
  return (
    <img
      className={className}
      src={
        data.latency.success || data.latency.avg <= 5000
          ? IconSmileGreen
          : data.latency.avg <= 8000
          ? IconSmileYellow
          : IconSmileRed
      }
      alt='Smile Icon'
    />
  );
};

export const SIconBandwidth = ({ data, className }) => {
  return (
    <img
      className={className}
      src={
        data.bandwidth.success || data.bandwidth.duration <= 8
          ? IconSmileGreen
          : data.bandwidth.duration <= 5
          ? IconSmileYellow
          : IconSmileRed
      }
      alt='Smile Icon'
    />
  );
};
