import React, { useState, useEffect, memo } from 'react';
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDiagnosticData } from '../../actions';
import SpinnerLoading from './../SpinnerLoading';
import Feedback from './../Feedback';
import ViewResult from './ViewResult';
import { HOST } from 'configs';

const imageFile = `${HOST.API.CONTENT}/test/bw-test1.jpg`;
// const downloadSize = 5083725; //bytes;

// Bandwidth duration
const settings = {
  optimal: 8,
  minimal: 5,
  timeout: 35000,
};

const BandwidthTest = () => {
  // Check wether the bandwith data is exists in the redux store
  // const testCompleted = useSelector(state => state.diagnosticData.bandwidth);
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [aborted, setAborted] = useState(false);

  const checkBandwidth = () => {
    if (aborted) return;

    let startTime, endTime;
    const download = new Image();
    download.onload = function() {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = function(err, msg) {
      setAborted(true);
    };

    startTime = new Date().getTime();
    const cacheBuster = '?nnn=' + startTime;
    download.src = imageFile + cacheBuster;

    function showResults() {
      const duration = (endTime - startTime) / 1000;
      // const bitsLoaded = downloadSize * 8;
      // const speedBps = (bitsLoaded / duration).toFixed(2);
      // const speedKbps = (speedBps / 1024).toFixed(2);
      // const speedMbps = (speedKbps / 1024).toFixed(2);
      const result = {
        received: 5.2,
        duration,
        success: duration <= settings.optimal,
      };
      setResult(result);
    }
  };

  useEffect(() => {
    if (result) {
      dispatch(setDiagnosticData({ bandwidth: result }));
    }
  }, [dispatch, result]);

  useEffect(() => {
    if (aborted) {
      dispatch(setDiagnosticData({ failed: true }));
    }
  }, [aborted, dispatch]);

  useEffect(() => {
    checkBandwidth();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='box'>
        <p className='m-0'>
          <span className='weight-600 mr-2'>Bandwidth:</span>
          <span className='weight-100'>
            {aborted ? 'No Response.' : result ? <ViewResult data={result} /> : <SpinnerLoading />}
          </span>
        </p>
      </div>
      <Feedback
        data={result}
        optimalSettings={settings.optimal}
        minimalSettings={settings.minimal}
        resultKey='duration'
        successText='The bandwidth is in normal range.'
        failText={
          <>
            The bandwidth is too low for optimal viewing performance.
            <br />
            Hint: Try other internet connection.
          </>
        }
        aborted={aborted}
      />
    </>
  );
};

export default memo(BandwidthTest);
