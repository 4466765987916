import React, { useEffect, useState } from 'react';
import moment from 'moment';
import BackButton from 'shared/BackButton';
import { useSelector } from 'react-redux';
import DateRange from 'shared/components/DateRangePicker';
import { duplicate } from 'utils/appHelpers';
import { IconSearch } from 'shared/components/Icons';
import {
  SIconResolution,
  SIconLatency,
  SIconBandwidth,
} from 'shared/components/StatisticTestIcons';

const DiagnosticsHistory = ({ history }) => {
  const initialData = useSelector(item => item.account.connectionTests);
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const initializePage = () => {
    let copiedData = duplicate(initialData);
    const modifiedData = [];
    const parseData = data => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return {};
      }
    };
    if (dateRange) {
      copiedData = copiedData.filter(item => {
        let date = new Date(item.dateTime);
        let start = new Date(dateRange.start);
        let end = new Date(dateRange.end);
        return date >= start && date <= end;
      });
    }

    copiedData.forEach(item => {
      if (!item.testData) return;
      const parsedData = parseData(item.testData);
      parsedData.ip = item.hostAddress;
      parsedData.resolution = parsedData.resolution || null;
      parsedData.bandwidth = parsedData.bandwidth || null;
      parsedData.latency = parsedData.latency || null;
      parsedData.browser = parsedData.browser || 'Unknown';
      parsedData.os = parsedData.os || 'Unknown';
      parsedData.id = item.id;
      parsedData.date = moment(item.dateTime).format('MM/DD/YYYY') || 'Unknown';
      modifiedData.push(parsedData);
    });
    setData(modifiedData.reverse());
  };

  useEffect(initializePage, [dateRange]);
  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <BackButton history={history} />
          <span className='ml-1'>Connection and Device Test History</span>
        </div>
      </div>
      <div className='p-3 d-flex justify-content-end'>
        <DateRange onChange={setDateRange} value={dateRange} />
      </div>
      <div className='px-3'>
        <table className='table table-custom'>
          <thead>
            <tr>
              <th scope='col' className='col-index'>
                #
              </th>
              <th scope='col'>Date</th>
              <th scope='col'>Resolution</th>
              <th scope='col'>Latency</th>
              <th scope='col'>Bandwidth</th>
              <th scope='col'>IP Address</th>
              <th scope='col'>Browser</th>
              <th scope='col cell-fixed__md'>OS Info</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map(({ resolution, bandwidth, latency, browser, os, id, date, ip }, index) => {
                return (
                  <tr key={id}>
                    <td className='col-index'>
                      <b>{index + 1}</b>
                    </td>
                    <td>{date}</td>
                    <td>
                      <div className='d-flex align-items-center justify-content-between'>
                        {resolution
                          ? `Screen: ${resolution.screen}, Window: ${resolution.window}`
                          : '-'}
                        <SIconResolution className='ml-2' data={{ resolution }} />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center justify-content-between'>
                        {latency
                          ? `Avg: ${latency.avg}ms, Min: ${latency.min}ms, Max: ${latency.max}ms`
                          : '-'}
                        <SIconLatency className='ml-2' data={{ latency }} />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center justify-content-between'>
                        {bandwidth
                          ? `Received ${Number(bandwidth.received).toFixed(2)} MB in ${Number(
                              bandwidth.duration,
                            ).toFixed(2)}s`
                          : '-'}
                        <SIconBandwidth className='ml-2' data={{ bandwidth }} />
                      </div>
                    </td>
                    <td className='text-nowrap text-center'>{ip || '-'}</td>
                    <td className='text-nowrap'>{browser || '-'}</td>
                    <td className={`cell-fixed__md ${os.length > 40 ? 'font-size-10' : ''}`}>
                      {os || '-'}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan='7'>
                  <div>
                    <IconSearch className='mr-1' /> No Results Found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DiagnosticsHistory;
