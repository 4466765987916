import React, { useEffect, Fragment } from 'react';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthState } from './Auth/actions';
import { useSnackbar } from 'notistack';

// Load Routes
import Auth from './Auth';
import Main from './Main';
import Loading from 'shared/components/Loading';
import PublicViewer from './PublicViewer';
import Events from './Events';
import ComingSoon from './ComingSoon';
import PromoPage from './PromoPage';

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.isAuthenticated);
  const account = useSelector(state => state.account);
  const hasVipAccess = isAuth && account && account.userType !== 6;

  useEffect(() => {
    if (isAuth === null) dispatch(getAuthState(enqueueSnackbar));
    //eslint-disable-next-line
  }, [getAuthState, isAuth]);

  if (isAuth === -1) return <ComingSoon />;

  return (
    <Fragment>
      <Router>
        {isAuth !== null && (
          <Switch>
            <Route path='/expromo1' component={PromoPage} />
            <Route path='/inpromo1' component={PromoPage} />
            <Route path='/soon' component={ComingSoon} />
            <Route path='/events' component={Events} />
            <Route path='/viewer' component={PublicViewer} />
            <Route path='/' component={hasVipAccess ? Main : Auth} />
          </Switch>
        )}
        {isAuth == null && <Loading classView='min-vh-100' />}
      </Router>
    </Fragment>
  );
};

export default App;
