import packageJson from './../../package.json';

const generic = {
  production: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.hologicace.com/',
    admin_domain: 'https://admin.hologicace.com',
    content_domain: 'content.hologicace.com',
  },
  stage: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.hologicace.com',
    admin_domain: 'https://admin-test.hologicace.com',
    content_domain: 'content.hologicace.com',
  },
  development: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'dev.fordemo.website/',
    admin_domain: 'https://admin-dev.fordemo.website',
    content_domain: 'content.fordemo.website',
  },
  rsna: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.hologicace.com',
    admin_domain: 'https://admin.hologicace.com',
    content_domain: 'content.hologicace.com',
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST = {
  API: {
    VERSION: packageJson.version,
    URL: `${main.url_protocol}://${main.api_domain}`,
    WS: `${main.ws_protocol}://${main.api_domain}`,
    CONTENT: `${main.url_protocol}://${main.content_domain}`,
    PROTOCOL: `${main.url_protocol}://`,
    ADMIN_DOMAIN: main.admin_domain,
    API_PREFIX: '',
    AUTH_HEADER: 'Authentication',
    AUTH_PREFIX: '',
  },
};
