/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import { IconPlayFill } from 'shared/components/Icons';
import { useRef } from 'react';

const DashImageSlider = ({ onOpenUrl }) => {
  const slider = useRef();
  const [banners, setBanners] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  const getBanners = async () => {
    const { data } = await Api.get('/common/banners/1');
    const body = data.data.sort((a, b) => a.orderNum - b.orderNum);
    setBanners(body);
  };

  useEffect(() => {
    getBanners();
  }, []);

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    className: 'home-slide',
    autoplay: true,
    afterChange: () => {
      setIsPlaying(false);
      slider.current.slickPause();
    },
  };

  const startSlide = e => {
    setIsPlaying(false);
    setTimeout(() => {
      slider.current.slickPlay();
    }, 10000);
  };

  return (
    <div className='banners-block'>
      {!banners && <Loading classView='mt-5' />}
      {banners && (
        <Slider {...sliderSettings} ref={slider}>
          {banners.map(item => {
            const isVideo = item.contentType === 2;
            const settings = {
              url: item.target,
              width: '100%',
              height: 289,
              controls: true,
              playing: isPlaying,
              light: true,
              playIcon: (
                <div
                  className='position-relative d-flex justify-content-center flex-column'
                  onClick={() => {
                    setIsPlaying(true);
                    slider.current.slickPause();
                  }}
                >
                  <span className='banner-play pointer'>
                    <IconPlayFill />
                  </span>
                  <img src={item.url} alt='play' />
                </div>
              ),
              onPlay: () => {
                setIsPlaying(true);
                slider.current.slickPause();
              },
              onPause: p => startSlide(p),
            };

            if (isVideo) {
              return <ReactPlayer {...settings} key={item.id} />;
            }

            return (
              <div key={item.id}>
                <div
                  role='button'
                  onClick={onOpenUrl.bind(null, item.target)}
                  className='position-relative d-flex justify-content-center flex-column'
                  tabIndex='-1'
                >
                  <img src={item.url} alt='banner' />
                  {isVideo && (
                    <span className='banner-play pointer'>
                      <IconPlayFill />
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default DashImageSlider;
