import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/umd/rangeslider.min.css';
import { IconPlayFill } from 'shared/components/Icons';
import Loading from 'shared/components/Loading';
import pause from 'assets/pause.svg';
import speedIcon from 'assets/speed.svg';

const stackWords = {
  CC: { top: 'H', bottom: 'F' },
  MLO: { top: 'M', bottom: 'L' },
};

const speeds = [
  { name: 'Low', value: 160 },
  { name: 'Medium', value: 80 },
  { name: 'High', value: 40 },
];

const StackControl = ({
  cornerstoneTools,
  element,
  cornerstone,
  imageType,
  setActiveTool,
  stackControl,
  className,
  dicomData,
}) => {
  const stackEl = useRef();
  const [speed, setSpeed] = useState(80);
  const [play, setPlay] = useState(false);
  const [fetch, setFetch] = useState(false);

  const playIndex = useRef({ index: 0, increase: true, images: undefined });
  const scale = useRef(0);
  const playInterval = useRef();

  const getStackData = () => {
    const stackData = cornerstoneTools.getToolState(element, 'stack');
    const { currentImageIdIndex, imageIds } = stackData.data[0];
    return { currentImageIdIndex, imageIds };
  };

  const { imageIds } = getStackData();

  const changeHandle = index => {
    if (fetch || play) return;
    if (!playIndex.current.images) {
      loadAllImages();
      return;
    }
    playIndex.current.index = index;
    scale.current = index;
    changeStackImage(playIndex.current.images, index);
  };

  const changeStackImage = (images, index) => {
    if (images[index] !== undefined) {
      const viewport = cornerstone.getViewport(element);
      const stackData = cornerstoneTools.getToolState(element, 'stack');
      stackData.data[0].currentImageIdIndex = Number(index);
      cornerstone.displayImage(element, images[index], viewport);
    }
  };

  const playStack = images => {
    try {
      let { index, increase } = playIndex.current;
      index += increase ? 1 : -1;
      if (!imageIds[index]) {
        increase = !increase;
        index += increase ? 2 : -2;
      }
      changeStackImage(images, index);
      playIndex.current.index = index;
      playIndex.current.increase = increase;
    } catch (err) {
      console.log(err);
    }
  };

  const loadAllImages = async play => {
    setFetch(true);
    if (!playIndex.current.images) {
      const { imageIds } = getStackData();
      const imageLoads = imageIds.map(async item => await cornerstone.loadAndCacheImage(item));
      const images = await Promise.all(imageLoads);
      playIndex.current.images = images;
    }
    setFetch(false);
    if (play) playInterval.current = setInterval(playStack, speed, playIndex.current.images);
  };

  const onElementScroll = e => {
    e.preventDefault();

    if (!playIndex.current.images) {
      if (fetch || play) return;
      loadAllImages();
      return;
    }

    const { imageIds } = getStackData();
    scale.current += e.deltaY * -0.01;
    scale.current = Math.min(Math.max(0, scale.current), imageIds.length);

    if (playIndex.current.index !== Math.floor(scale.current)) {
      changeHandle(Math.floor(scale.current));
    }
  };

  const onChangeStart = () => {
    setActiveTool({ type: 'remove' });
  };

  const handleUserKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 32)
      setPlay(p => {
        return !p;
      });
  };

  const stopPlayerUnmount = () => {
    window.removeEventListener('keydown', handleUserKeyPress);
    setPlay(false);
    clearInterval(playInterval.current);
    playIndex.current = { index: 0, increase: true, images: undefined };
  };

  const onSpeedChange = async value => {
    setSpeed(value);
    if (play) {
      clearInterval(playInterval.current);
      playInterval.current = setInterval(playStack, value, playIndex.current.images);
    }
  };

  const renderLines = length => {
    const lines = [];
    for (let index = 0; index < length; index++) {
      lines.push(<span key={index} className='line' />);
    }
    return lines;
  };

  const getStackLinesStyle = () => {
    if (!stackEl.current) return {};
    return {
      height: stackEl.current.clientHeight - 137,
    };
  };

  useEffect(() => {
    playIndex.current = { index: 0, increase: true, images: undefined };
    scale.current = 0;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (play) {
      element.onwheel = null;
      loadAllImages(true);
    } else {
      scale.current = playIndex.current.index;
      element.onwheel = onElementScroll;
      clearInterval(playInterval.current);
    }
    //eslint-disable-next-line
  }, [play]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      stopPlayerUnmount();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stackControl !== '2D') {
      setTimeout(() => {
        dicomData.images.map(item => cornerstone.loadAndCacheImage(item.url));
      }, 100);
    }
    stopPlayerUnmount();
    //eslint-disable-next-line
  }, [stackControl]);

  return (
    <div className={`stack-control ${fetch ? 'loading' : ''} ${className || ''}`} ref={stackEl}>
      <div
        className='stack-lines d-flex flex-column justify-content-around'
        style={getStackLinesStyle()}
      >
        {renderLines(imageIds.length)}
      </div>
      <div className='settings-btn'>
        <img src={speedIcon} alt='speed' />
        <div className='settings-options d-flex align-items-center'>
          {speeds.map(item => (
            <button
              key={item.value}
              className={`btn btn-sm btn-block ${speed === item.value ? 'active' : ''}`}
              onClick={() => onSpeedChange(item.value)}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      <button className='btn play-btn' disabled={fetch} onClick={() => setPlay(p => !p)}>
        {fetch ? (
          <Loading classSpinner='spinner-border-sm text-white' />
        ) : play ? (
          <img className='mr-1' src={pause} alt='pause' width='15' />
        ) : (
          <IconPlayFill color='#fff' />
        )}
      </button>
      <div className='stack-word'>{stackWords[imageType].top}</div>
      <Slider
        min={0}
        max={imageIds.length - 1}
        value={Number(getStackData().currentImageIdIndex)}
        onChange={changeHandle}
        onChangeStart={onChangeStart}
        orientation='vertical'
        tooltip={false}
        handleLabel={`${getStackData().currentImageIdIndex + 1}`}
      />
      <div className='stack-word'>{stackWords[imageType].bottom}</div>
      <div className='stack-num'>
        {Number(getStackData().currentImageIdIndex) + 1}/{imageIds.length}
      </div>
      <div className='stack-tooltip'>Buffering in Process</div>
    </div>
  );
};

export default StackControl;
